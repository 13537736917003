<spinner *ngIf="isLoading"></spinner>
<form  #ngForm="ngForm" *ngIf="container && !isLoading" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>
        
        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="label">Label</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['label']" name="label" placeholder="Required" required>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Report Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="bundle_id">Bundle ID</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data.bundle_id" name="bundle_id" placeholder="Required" required>
                </div>
                <div class="ui-g-4">
                    <label for="report_type">Report Type</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data.report_type" name="report_type" placeholder="Required" required>
                </div>
                <div class="ui-g-4">
                    <label for="subtype">Subtype</label>
                    <select [(ngModel)]="container.data.subtype" name="subtype" class="form-control">
                        <option *ngFor="let opt of subtypeList" [value]="opt.value">{{ opt.label }}</option>
                    </select>
                    <!-- <input type="text" class="form-control"[(ngModel)]="container.data.subtype" name="subtype"> -->
                </div>
                <div class="ui-g-4">
                    <label for="query_domain">Query Domain</label>
                    <select [(ngModel)]="container.data.query_domain" name="query_domain" class="form-control">
                        <option *ngFor="let opt of queryDomainList" [value]="opt.value">{{ opt.label }}</option>
                    </select>
                    <!-- <input type="text" class="form-control" [(ngModel)]="container.data.query_domain" name="query_domain"> -->
                </div>
                <div class="ui-g-4">
                    <label for="query_path">Query Path</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.query_path" name="query_path">
                </div>
                <div class="ui-g-4">
                    <label for="query_results_transform">Query Results Transform</label>
                    <select [(ngModel)]="container.data.query_results_transform" name="query_results_transform" class="form-control">
                        <option *ngFor="let opt of queryTransformList" [value]="opt.value">{{ opt.label }}</option>
                    </select>
                    <!-- <input type="text" class="form-control"[(ngModel)]="container.data.query_results_transform" name="query_results_transform"> -->
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Report Display Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="category">Category</label>
                    <input type="text" class="form-control"[(ngModel)]="container.data.category" name="category">
                </div>
                <div class="ui-g-6">
                    <label for="render_type">Render Type</label>
                    <select [(ngModel)]="container.data.render_type" name="render_type" class="form-control">
                        <option *ngFor="let opt of renderTypeList" [value]="opt.value">{{ opt.label }}</option>
                    </select>
                    <!-- <input type="text" class="form-control"[(ngModel)]="container.data.render_type" name="render_type"> -->
                </div>    
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control"[(ngModel)]="container.data.description" name="description">
                </div>
            </div>
        </p-panel>

    </div>
</form>
