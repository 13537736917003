import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { CatalogFormComponent } from '../../forms/catalog-form/catalog-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';


@Component({
    selector: 'catalog-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'catalog-edit.component.html',
    styleUrls: ['catalog-edit.component.css']
})
export class CatalogEditComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading: boolean = false;
    public msgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('catalog edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number

            console.log('id', id);

            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        console.log('res', res);
                        this.container = res
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });

        console.log('cres', this.container);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.msgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Success', detail: 'Catalog saved.' });
                    this.container = Object.assign(new Container(), this.container);
                    //this.location.back();
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Catalog could not be saved.' });

                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
