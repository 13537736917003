<form (submit)="onSubmit($event)">
    <div class="ui-g-12">
        <input type="text" name="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" autocomplete="off" class="form-control p-inputtext p-corner-all ui-state-default p-widget" placeholder="Username">
    </div>
    <div class="ui-g-12">
        <input type="password" name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" autocomplete="off" class="form-control p-inputtext p-corner-all ui-state-default p-widget" placeholder="Password">
    </div>
    <div class="ui-g-12 button-container">
        <button type="submit" class="p-button green-btn raised-btn p-widget ui-state-default p-corner-all p-button-text-icon-left">
            <span class="p-button-icon-left p-c fa fa-fw fa-user"></span>
            <span class="p-button-text p-c">Sign In</span>
        </button>
    </div>
</form>
