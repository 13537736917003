import { Component, Input, Output,OnInit, EventEmitter } from '@angular/core';
import { Resource, Container } from '../../models';
import { ResourceService } from '../../services/resource/resource.service';

@Component({
    selector: 'resource',
    providers: [
        ResourceService
    ],
    templateUrl: 'resource.component.html',
    styleUrls: ['resource.component.css']
})
export class ResourceComponent {
    @Input() resource: Resource;
    @Input() parentContainer: Container;
    @Output() onRemove: EventEmitter < any > = new EventEmitter();

    private tagTooltip: string = '';

    constructor(
        private resourceService: ResourceService
    ) {}

    ngOnInit() {
        console.log('resource create init');

        this.tagTooltip = this.resource.tags.map(t => t.value).join('\n');

    }

    get displayPreview() {
        if (!this.resource) return false;
        return this.resourceService.displayPreview(this.resource.type.name);
    }

    remove() {
        if (confirm('Are you sure you want to delete this resource?')) {
            this.resourceService
                .delete(this.resource)
                .subscribe(
                    res => {
                        console.log(res);
                        this.onRemove.emit({
                            resource: this.resource
                        });
                    },
                    err => console.log(err)
                );
        }
    }
}
