import { EntityType } from './entity-type';
import { Container } from './container';

export class History {
    history_id = -1;
    history_date = '';
    history_type = '';
    history_user = '';
    history_user_id: number = null;
    history_change_reason = '';
}
export class ContainerHistory extends History {
	id = -1;
	type: EntityType = new EntityType();
	guid = '';
	status = '';
	origin = '';
	data = {};
	created_date = '';
	reference_date = '';
	published_date = '';
	expiration_date = '';
}
export class ResourceHistory extends History {
	id = -1;
	type: EntityType = new EntityType();
	uri = '';
	status = '';
	origin = '';
	data = {};
	container_id = -1;
	created_date = '';
	published_date = '';
	expiration_date = '';
}