import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'provider-form',
    providers: [
        EntityTypeService,
        PipelineService
    ],
    templateUrl: 'provider-form.component.html'
})
export class ProviderFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    private pipelineTypes = [];
    public isLoading = false;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        private pipelineService: PipelineService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'provider'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
        this.pipelineTypes = PipelineService.pipelineTypes;
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the provider name.' });
            valid = false;
        }
        if (!('mso_id' in this.container.data) || (!this.container.data['mso_id']) || !/\S/.test(this.container.data['mso_id'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the MSO id.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}
