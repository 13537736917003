import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { ContainerService } from '../../services/container/container.service';

@Component({
    selector: 'catalog-form',
    providers: [
        EntityTypeService,
        ContainerService
    ],
    templateUrl: 'catalog-form.component.html'
})
export class CatalogFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formCancel: EventEmitter<any> = new EventEmitter();
    @ViewChild('queryset') querysetEditor: JsonEditorComponent;
    @ViewChild('filters') filtersEditor: JsonEditorComponent;
    @ViewChild('excludes') excludesEditor: JsonEditorComponent;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;
    private querysetObject: Object = {};
    private filtersArray: Array<any> = [];
    private excludesArray: Array<any> = [];
    private editorOptionsQueryset: JsonEditorOptions;
    private editorOptionsFilters: JsonEditorOptions;
    private editorOptionsExcludes: JsonEditorOptions;

    // queryset_type
    //query_set
    //template
    //order_by
    //page_by

    //descriptio
    //region
    //locale
    //version id
    private catalogTypes = [
        'apple_universal_services',
        'apple_itunes',
        'amazon_cdf',
        'google_dfp',
        'msn_syndication',
        'yahoo_syndication',
        'export_campaign',
        'other',
    ];

    private queryTypes = [
        'django_query',
        'vms_api_query',
    ];

    private contentTypes = [
        'application/json',
        'text/plain',
        'text/html',
        'text/xml',
        'text/csv'
    ];

    private regions = ['CA'];
    private locales = ['en-US', 'en-CA', 'fr-CA'];
    private ratingSystemCodes = ['us-tv'];
    private serviceversions = ['2.0'];
    private catalogversions = ['1.3'];
    constructor(
        private entityTypeService: EntityTypeService,
        private containerService: ContainerService
    ) { }

    ngOnInit() {

        this.editorOptionsQueryset = new JsonEditorOptions();
        this.editorOptionsQueryset.mode = 'tree';
        this.editorOptionsQueryset.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsQueryset.statusBar = true;
        this.editorOptionsQueryset.expandAll = true;
        this.editorOptionsQueryset.onChange = () => this.changeJson('queryset', this.querysetEditor);

        this.editorOptionsFilters = new JsonEditorOptions();
        this.editorOptionsFilters.mode = 'tree';
        this.editorOptionsFilters.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsFilters.statusBar = true;
        this.editorOptionsFilters.expandAll = true;
        this.editorOptionsFilters.onChange = () => this.changeJson('queryset_q_object_list', this.filtersEditor);

        this.editorOptionsExcludes = new JsonEditorOptions();
        this.editorOptionsExcludes.mode = 'tree';
        this.editorOptionsExcludes.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsExcludes.statusBar = true;
        this.editorOptionsExcludes.expandAll = true;
        this.editorOptionsExcludes.onChange = () => this.changeJson('queryset_exclude_list', this.excludesEditor);

        this.entityTypeId = this.container.type.id;

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'catalog'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    
                    this.initValues();

                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    private initValues() {
        if (!this.container.data['queryset']) {
            this.container.data['queryset'] = {};
        }

        if (!this.container.data['queryset_q_object_list']) {
            this.container.data['queryset_q_object_list'] = [];
        }

        if (!this.container.data['queryset_exclude_list']) {
            this.container.data['queryset_exclude_list'] = [];
        }

        if (!('requires_basic_authentication' in this.container.data)) {
            this.container.data['requires_basic_authentication'] = false;
        }
        // Basically a workaround for type diff between
        // editor's JSON type and the fields' types
        this.querysetObject = this.container.data['queryset'];
        this.filtersArray = this.container.data['queryset_q_object_list'];
        this.excludesArray = this.container.data['queryset_exclude_list'];
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
        try {
            var editorData = editor.get();
        } catch (e) {
            // Keep current value
            return;
        }
        this.container.data[field] = editorData;
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);
        this.formSubmit.emit({
            container: this.container
        });
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }
}
