<div class="card card-w-title top-level-card">
    <h1>Series</h1>
    <container-dataview 
        [containers]="containers" 
        rows="50"
        filterBy="title, keywords" 
        [sortField]="sortField" 
        [sortOrder]="sortOrder" 
        [isLoading]="isLoading" 
        (statusSelectionChange)="onStatusSelectionChange($event)">
    </container-dataview>
</div>