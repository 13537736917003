<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left"
                    style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Label</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['label']" name="label" placeholder="Required" required>
                </div>
            </div>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="name">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description">
                </div>
            </div>

        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
                <div class="ui-g-4">
                    <label for="type">Catalog Type</label>
                    <select [(ngModel)]="container.data.catalog_type" name="catalogtype" class="form-control">
                        <option *ngFor="let catalogType of catalogTypes" [value]="catalogType">{{ catalogType }}</option>
                    </select>
                </div>
                <div class="ui-g-8">
                    <label for="origin">Bundle Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.bundle_id" name="bundle" placeholder="Required" required>
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
      
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="enable_http_basic_authentication">Enable Http Basic Authentication&emsp;</label>
                    <p-checkbox name="checkbox" [(ngModel)]="container.data.requires_basic_authentication" binary="true"></p-checkbox>
                    </div>
                <div class="ui-g-10">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>


        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Parameters</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
            </div>
            <div class="ui-g-2">
                <label for="type">Query Type</label>
                <select [(ngModel)]="container.data.query_type" name="querytype" class="form-control">
                    <option *ngFor="let queryType of queryTypes" [value]="queryType">{{ queryType }}</option>
                </select>
            </div>
            <div class="ui-g-2">
                <label for="origin">Page Size</label>
                <input type="text" class="form-control" [(ngModel)]="container.data.page_size" name="Paging">

            </div>
            <div class="ui-g-2">
                <label for="origin">Total Query Count</label>
                <input type="checkbox" class="form-control" [(ngModel)]="container.data.total_query_count" name="TotalQueryCount">

            </div>
            <div class="ui-g-3">
                <label for="origin">Ordering</label>
                <input type="text" class="form-control" [(ngModel)]="container.data.ordering" name="Ordering">
            </div>
            <div class="ui-g-3">
                    <label for="type">Endpoint Content Type</label>
                    <select [(ngModel)]="container.data.content_type" name="contenttype" class="form-control">
                        <option *ngFor="let contentType of contentTypes" [value]="contentType">{{ contentType }}</option>
                    </select>
            </div>  
        </p-panel>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
            <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Base QuerySet</span>
            </p-header>

            <json-editor #queryset [options]="editorOptionsQueryset" [data]="querysetObject"></json-editor>
        </p-panel>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">QuerySet Filter List</span>
            </p-header>

            <json-editor #filters [options]="editorOptionsFilters" [data]="filtersArray"></json-editor>
        </p-panel>

        <p-panel class="ui-g-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">QuerySet Exclude List</span>
            </p-header>

            <json-editor #excludes [options]="editorOptionsExcludes" [data]="excludesArray"></json-editor>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Template</span>
            </p-header>
            <div class="ui-g-12">
                <textarea [rows]="20" class="ui-g-12" pInputTextarea name="payload" [(ngModel)]="container.data.template" autoResize="autoResize"></textarea>
            </div>
        </p-panel>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>

        <hr>

    </div>
</form>