<p-dialog header="Reverting object to version {{historyId}}" [(visible)]="displayDialog" [modal]="true" [minY]="70" [baseZIndex]="10000">
     <span style="white-space: normal;">Are you sure you want to apply these changes ?</span>

     <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="revertObject()" label="Yes"></button>
        <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="No" class="p-button-secondary"></button>
    </p-footer>
</p-dialog>

<div class="card card-w-title top-level-card">
    <h1>Revert to Version {{historyId}}</h1>
    
    <div class="ui-g-12" style="padding-left: 0">
    	<label>Datetime when selected history version was created</label>
        <div>
            <input type="text" class="form-control" [(ngModel)]="targetHistoryDate" [readonly]="true">
        </div>
    </div>
    
    <p-panel>
        <p-header>
            <span class="p-panel-title" style="font-size:14px">Reverting this {{objectType}} will apply the following changes</span>
            <button pButton type="button" label="Apply" icon="pi pi-arrow-left" style="float:right;"(click)="showDialog()"></button>
        </p-header>
    </p-panel>
    
    <div style="margin-top: 10px">
        <inline-diff [oldText]=currentObjectString [newText]=oldObjectString [lineContextSize]=35></inline-diff>
    </div>
</div>