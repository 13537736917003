import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { Container } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';

@Component({
    selector: 'platform-form',
    providers: [
        EntityTypeService,
        ContainerService
    ],
    templateUrl: 'platform-form.component.html'
})
export class PlatformFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;
    private publishDate: Date;
    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'platform'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the platform name.' });
            valid = false;
        }

        if (!('platform_type' in this.container.data) || (!this.container.data['platform_type']) || !/\S/.test(this.container.data['platform_type'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the platform type.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onStatusChange(event) {   
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date); 
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }
}
