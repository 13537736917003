<div class="ui-g ui-g-nopad">
    <spinner *ngIf="isLoading" class="ui-g-12 ui-g-nopad"></spinner>
    <div class="p-widget-header table-header ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none;">
        <div class="ui-g-3 ui-g-nopad">
            <div class="p-inputgroup">
                <input type="text" pInputText size="50" placeholder="Search" class="filter-bar" [(ngModel)]="searchText" (keydown)="onAttemptSearch($event)" style="text-align:center;width:100%;height:100%;">
                <button pButton type="button" icon="fa fa-search" (click)="onSearch($event)"></button>
            </div>
        </div>
        <div class="ui-g-2 ui-g-nopad">
            <div *ngIf="!isLoading" style="text-align:center;margin-top:4px;">{{resultsCount}} search result{{results.length==1?'':'s'}}</div>
            <div *ngIf="isLoading" style="text-align:center;margin-top:4px;">Loading...</div>
        </div>
        <div class="ui-g-2  ui-g-nopad">
                <p-dropdown [options]="originList" [(ngModel)]="selectedOrigin" (onChange)="onChangedOrigin($event)" placeholder="Select an Origin" [group]="false" appendTo="body" >
                        <ng-template let-item pTemplate="selectedItem">
                                <span style="vertical-align:middle; margin-left: .5em">{{item.label}}</span>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <div class="clearfix" style="position: relative;height: 25px;">
                                    <div style="font-size:14px;float:right;margin-top:4px">{{item.label}}</div>
                                </div>
                            </ng-template>
                </p-dropdown>
        </div>
        <div class="ui-g-2 ui-g-nopad">
                <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" [group]="false" [(ngModel)]="selectedStatus" appendTo="body"></p-dropdown>
        </div>


        <div class="ui-g-3 ui-g-nopad">
            <input type="text" pInputText size="50" placeholder="Filter" (keyup)="rt.filterGlobal($event.target.value.toLowerCase().trim(), 'contains')" class="filter-bar" style="text-align:center;width:100%;height:100%;">
        </div>
    </div>

    <p-table #rt class="ui-g-12 ui-g-nopad" 
            [value]="results" 
            [globalFilterFields]="['title', 'guid', 'keywords']" 
            [rows]="20" 
            [paginator]="true" 
            paginatorPosition="both" 
            [(selection)]="selected" 
            dataKey="guid" 
            [lazy]="true" 
            [rows]="10" 
            [first]="offset" 
            (onLazyLoad)="onLazyLoad($event)"
            styleClass="p-datatable-gridlines"
            [totalRecords]="resultsCount">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 2.4em">
                </th>
                <th>Title/Name/Label</th>
                <th>Show Title</th>
                <th>GUID</th>
                <th>Keywords</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-container>
            <tr [pSelectableRow]="container">
                <td>
                    <p-tableCheckbox [value]="container"></p-tableCheckbox>
                </td>
                <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                <td style="word-break: break-all;">{{container.guid}}</td>
                <td style="word-break: break-all;">{{container.keywords || ''}}</td>
            </tr>
        </ng-template>
    </p-table>

    <div class="ui-g-12" style="height:20px;"></div>

    <p-toolbar class="ui-g-12 ui-g-nopad" [style]="{'padding-left':'0','padding-right':'0'}">
        <div class="ui-g-2">
            <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
        </div>
        <div class="ui-g-8" style="text-align:center;margin-top:4px;">
            {{selected.length}} {{filterType.replace('_', ' ')}}{{selected.length==1?'':'(s)'}} will be added. They are listed below.
        </div>
        <div class="ui-g-2">
            <button pButton type="submit" label="Confirm" (click)="onSubmit($event)" icon="pi pi-check" style="float:right;"></button>
        </div>
    </p-toolbar>

    <div class="ui-g-12" style="height:20px;"></div>

    <div class="p-widget-header table-header ui-g-12 ui-g-nopad" style="padding:6px;border-bottom: 0 none;">
        <div class="ui-g-4 ui-g-offset-4 ui-g-nopad">
            <input type="text" pInputText size="50" placeholder="Filter" (keyup)="st.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar" style="text-align:center;width:100%;height:100%;">
        </div>
    </div>
    <p-table #st [value]="selected" 
            [globalFilterFields]="['title', 'guid', 'keywords']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both"
            styleClass="p-datatable-gridlines"
            [(selection)]="selected">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 2.25em">
                </th>
                <th>Title/Name/Label</th>
                <th>Show Title</th>
                <th>GUID</th>
                <th>Keywords</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-container>
            <tr [pSelectableRow]="container">
                <td>
                    <p-tableCheckbox [value]="container"></p-tableCheckbox>
                </td>
                <td>{{container.data['title'] || container.data['name'] || container.data['label']}}</td>
                <td style="word-break: break-all;">{{container.data.show_title || ''}}</td>
                <td style="word-break: break-all;">{{container.guid}}</td>
                <td style="word-break: break-all;">{{container.keywords || ''}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
