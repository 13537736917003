import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
import { Message } from 'primeng/api';

@Component({
    selector: 'platform-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'platform-create.component.html',
    styleUrls: ['platform-create.component.css']
})
export class PlatformCreateComponent implements OnInit {
    public container: Container = new Container();
    private error: string;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    public isLoading = false;

    constructor(
        private location: Location,
        public containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('platform create init');
    }

    onSubmit(e) {
        console.log('platform create submit', e);

        this.containerService
            .save(e.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/platform', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    let body = err.json();
                    this.error = body.guid || err.statusText;
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
                }
            );
    }

    onCancel(e) {
        this.location.back();
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
