import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { SeriesFormComponent } from '../../../content/forms/series-form/series-form.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';

@Component({
    selector: 'application-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'application-create.component.html'
})
export class ApplicationCreateComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container = new Container();
    private error: HttpResponse<any>;
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('application create initialized');
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/applications', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
