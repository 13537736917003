<p-panel>
    <p-header>
        <span class="p-panel-title" style="font-size:16px;">App View Metadata</span>
    </p-header>

    <div style="padding:0.5em;overflow:hidden;">
        <div class="ui-g-4">
            <label for="superLabel">Super Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['super_label']" name="superLabel">
        </div>
        <div class="ui-g-4">
            <label for="displayLabel">Display Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['display__label']" name="displayLabel">
        </div>
        <div class="ui-g-4">
            <label for="subLabel">Sub Label</label>
            <input type="text" class="form-control" [(ngModel)]="container.data['sub__label']" name="subLabel">
        </div>
    </div>
</p-panel>