import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'media-form',
    providers: [
        // EntityTypeService
    ],
    templateUrl: 'media-form.component.html'
})
export class MediaFormComponent implements OnInit {
    @Input() container: Container;
    @Input() seasons: Container[];
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formReparent: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @Output() reparenting: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityTypes = [];
    private entityTypeId;
    private selectedSeasonGuid;
    public isLoading = false;

    private publishDate: Date;
    
    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private ratings = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
    private genre = genreList;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date
    }

    ngOnInit() {
        this.entityTypeId = this.container.type.id;
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        if ('season_number' in this.container.data) {
            this.selectedSeasonGuid = this.container.data['secondary_parent_container_id'];
        }
        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSeasonChange() {
        console.log('selected season', this.selectedSeasonGuid);
        this.container.data['secondary_parent_container_id'] = this.selectedSeasonGuid;
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        let season = this.seasons.find(t => t.guid === this.selectedSeasonGuid);

        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the media title.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onStatusComplete() {
        // this.container.data['metadata_ingest_workflow_status'] = 'Complete';
        this.formSubmit.emit({
            container: this.container
        });
    }

    onStatusChange(event) {   
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date); 
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

    onReparenting(event) {
        this.formReparent.emit({
            container: this.container
        });
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }
}
