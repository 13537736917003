<spinner *ngIf="isLoading"></spinner>
<p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

<div class="card card-w-title">
    <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
</div>

<div class="ui-g" *ngIf="activeIndex == 0">
    <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Profiles</span>
        </p-header>


        <div class="p-padding-md">
            <div class="p-grid">

                <div class="p-col">
                    <label for="title">Template</label>
                    <select [(ngModel)]="selectedTemplate" name="template" class="form-control" (ngModelChange)="onTemplateChange()">
                        <option *ngFor="let template of templateProfiles" [value]="template.id">{{template.data.name}}</option>
                    </select>
                </div>
                <div class="p-col">
                    <label for="title">Series</label>
                    <select [(ngModel)]="selectedSeries" name="series" class="form-control" (ngModelChange)="onSeriesChange()">
                        <ng-container *ngFor="let item of series">
                            <option *ngIf="item.status=='published'" [value]="item.id">{{item.title}}</option>
                            <option *ngIf="item.status!='published'" [value]="item.id">{{item.title}} ({{item.status}})</option>
                        </ng-container>
                    </select>
                </div>
                <div class="p-col" *ngIf="additionalParentsVisible">
                    <label for="title">Additional Parents</label>
                    <container-search-autocomplete (onValueChange)="setAdditionalParents($event)" [parentObjects]="additionalParentsObjects"
                        [filterDefinition]="additionalParentsFilter" [enabled]="additionalParentsEnabled"
                        [multiSelectEnabled]="additionalParentsMultiselect"></container-search-autocomplete>
                </div>
            </div>
        </div>

    </p-panel>
</div>

<div class="ui-g" *ngIf="activeIndex == 2">
    <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Assets</span>
        </p-header>
        <div class="card ui-g-12" *ngIf="uploadingFiles.length">
            <h1>Uploading</h1>
            <div *ngFor="let file of uploadingFiles">
                <div *ngIf="!file.complete">
                    <label for="title">{{file.name}} - {{file.size}} bytes</label>
                    <p-progressBar [value]="file.progress"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="card ui-g-12" *ngIf="uploadedFiles.length">
            <h1>Uploaded</h1>
            <div *ngFor="let file of uploadedFiles">
                <label for="title">{{file.name}} - {{file.size}} bytes</label>
                <p-progressBar [value]="file.progress"></p-progressBar>
            </div>
        </div>
        <div class="card ui-g-12" *ngIf="deferredFiles.length">
            <h1>Waiting</h1>
            <div *ngFor="let file of deferredFiles">
                <label for="title">{{file.name}} - {{file.size}} bytes</label>
                <p-progressBar [value]="file.progress"></p-progressBar>
            </div>
        </div>

        <p-fileUpload name="uploadAssets" customUpload="true" (uploadHandler)="onUpload($event)" multiple="multiple"
            *ngIf="!isUploading">
        </p-fileUpload>
    </p-panel>
</div>

<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g" *ngIf="activeIndex == 0">
        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['title']" name="title"
                        placeholder="Required">
                </div>
                <div class="ui-g-6">
                    <label for="houseId">House Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.houseId" name="houseId"
                        placeholder="Required">
                </div>
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Details</span>
                <cue-point-edit [cuePoints]="this.container.data['chapter_start_times']" (onCuepointEdit)="onCuepointEdit($event)" style="float:right"></cue-point-edit>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="genre">Genre</label>
                    <select [(ngModel)]="container.data.genre" name="genre" class="form-control">
                        <option *ngFor="let ctype of genre" [value]="ctype.value">{{ctype.label}}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <container-subgenre [container]="container"></container-subgenre>
                </div>
                <div class="ui-g-4">
                    <label for="rating">Rating</label>
                    <select [(ngModel)]="container.data.tv_rating" name="rating" class="form-control">
                        <option *ngFor="let ctype of ratings" [value]="ctype">{{ctype}}</option>
                    </select>
                </div>
                <div class="ui-g-4">
                    <label for="episodeNumber">Episode &#35;</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.episode_number" name="episodeNumber">
                </div>
                <div class="ui-g-4">
                    <label for="season">Season &#35;</label>
                    <select [(ngModel)]="selectedSeasonId" (ngModelChange)="onSeasonChange()" name="season" class="form-control">
                        <option *ngFor="let season of seasons" [value]="season.id">{{ season.data.season_number }}</option>
                    </select>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            <div class="ui-g-12">
                <label for="keywords">Keywords</label>
                <input type="text" class="form-control" [(ngModel)]="container.data.keywords" name="keywords">
            </div>
        </p-panel>
    </div>

    <div class="ui-g" *ngIf="activeIndex == 1">
        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <div class="ui-g-12">
                <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
            </div>
        </p-panel>
    </div>

    <div class="ui-g" *ngIf="activeIndex == 3">
        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability Information</span>
            </p-header>

            <div class="ui-g">
                <div class="ui-g-3">
                    <label for="availableDate">Available Date</label>
                    <p-calendar [(ngModel)]="container.availableDate" [showIcon]="true" appendTo="body" name="availableDate"
                        dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                <div class="ui-g-3">
                    <label for="expirationDate">Expiration Date</label>
                    <p-calendar [(ngModel)]="container.expirationDate" [showIcon]="true" appendTo="body" name="expirationDate"
                        dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                <div class="ui-g-3">
                    <label for="referenceDate">Air Date/Reference Date</label>
                    <p-calendar [(ngModel)]="container.referenceDate" [showIcon]="true" appendTo="body" name="referenceDate"
                        dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                <!--
                <div class="ui-g-3">
                    <label for="publicWindowEndDate">Public Window End Date</label>
                    <p-calendar [(ngModel)]="container.publicWindowEndDate" [showIcon]="true" appendTo="body" name="publicWindowEndDate" dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                 -->
            </div>
            <!--
            <div class="ui-g">
                <div class="ui-g-3">
                    <label for="originalAirDate">Original Air Date</label>
                    <p-calendar [(ngModel)]="container.originalAirDate" [showIcon]="true" appendTo="body" name="originalAirDate" dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                <div class="ui-g-3">
                    <label for="localAirDate">Local Air Date</label>
                    <p-calendar [(ngModel)]="container.localAirDate" [showIcon]="true" appendTo="body" name="localAirDate" dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
                <div class="ui-g-3">
                    <label for="releaseDate">Default Release Date</label>
                    <p-calendar [(ngModel)]="container.releaseDate" [showIcon]="true" appendTo="body" name="releaseDate" dateFormat="yy-mm-dd" [showTime]="true" [yearNavigator]="true" yearRange="1970:2029"></p-calendar>
                </div>
            </div>
             -->
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Processing</span>
            </p-header>

            <div class="ui-g-12">
                <label for="title">Processing Profile:</label>
                <select [(ngModel)]="selectedPipelineId" name="pipelines" class="form-control" (ngModelChange)="onPipelineChange()">
                    <option *ngFor="let pipeline of pipelines" [value]="pipeline.id">{{pipeline.data.name}}</option>
                </select>
            </div>
        </p-panel>
    </div>

    <div class="ui-g">
        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="Cancel" (click)="onCancel($event)" class="p-button-danger"></button>
            </div>
            <div class="ui-g-4 ui-g-offset-6">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button"
                    (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" style="float:right;margin-left:4px;"
                    icon="fa fa-thumbs-up"></button>
                <button pButton type="submit" label="Save & Publish" icon="pi pi-check" style="float:right;margin-left:4px;"
                    *ngIf="activeIndex == 3 && !isUploading"></button>
                <button pButton type="button" label="Next" icon="pi pi-arrow-right" style="float:right;margin-left:4px;"
                    *ngIf="activeIndex != 3 && !isUploading" (click)="nextStep()"></button>
                <button pButton type="button" label="Back" icon="pi pi-arrow-left" style="float:right;margin-left:4px;"
                    *ngIf="activeIndex != 0 && !isUploading" (click)="prevStep()"></button>
            </div>
        </p-toolbar>
    </div>
</form>