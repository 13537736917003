import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { UploadComponent } from '../../../upload/upload.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { UploadService } from '../../../upload/upload.service';
import { FileUpload } from 'primeng/fileupload';
import { AuthService } from '../../../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { Resource } from '../../models';
import { Container } from '../../models/container';
import { ResourceUploadService } from '../../../upload/resource-upload.service';

import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'upload-resource-simple-form',
    templateUrl: 'upload-resource-simple-form.component.html',
    styleUrls: ['upload-resource-simple-form.component.css']
})
export class UploadResourceSimpleFormComponent implements OnInit {
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    @ViewChild('uploadComponent') uploadComponent: FileUpload;
    private entityTypes = [];

    container: Container = new Container();
    createdContainer: Container = new Container();

    private entityTypeId;
    private selectedEntityType;

    private isCreating = false;

    public isLoading = false;

    private error: string;

    private createdContainerId;
    private createdContainerGuid;

    private seriesContainerId;
    private selectedSeries;
    private series: any[] = [];
    private filteredSeriesList: any[] = [];
    private seriesStatusList = ['published', 'unpublished', 'scheduled', 'approved', 'ready', 'new'];

    //TODO: should be populated by templates

    private containerTypeName = 'episode';

    filesToUpload: any[] = [];

    selectedProfile: string;
    selectedTemplate: string;

    private templateProfiles = [];
    private contentTemplates = [];

    public msgs: Message[] = [];

    additionalParentsQuery: any;
    additionalParentsFilter: any;
    additionalParentsVisible: Boolean = false;
    additionalParentsEnabled: Boolean = false;
    additionalParentsTypes: [];
    additionalParentsMultiselect: Boolean = false;
    additionalParentsSeriesDependency: Boolean = false;
    additionalParentsRequired: Boolean = false;

    submitEnabled: Boolean = true;

    constructor(
        private router: Router,
        private containerService: ContainerService,
        private authService: AuthService,
        private entityTypeService: EntityTypeService,
        private resourceUploadService: ResourceUploadService
    ) { }

    resetUploadData() {

        this.container = new Container();
        this.setDefaultContainerObjectData(this.container);

        this.createdContainerId = null;
        this.createdContainerGuid = null;

        this.selectedProfile = "";
        this.selectedTemplate = "";

    }

    ngOnInit() {

        this.getEntityData();
        this.getTemplateProfiles();
        this.getSeriesData();
    }

    setDefaultContainerObjectData(container) {
        container.data = {
            title: '',
            houseId: '',
            description: '',
            genre: '',
            tv_rating: '',
            episode_number: '',
            available_date: null,
            expiration_date: null,
            public_window_end_date: null,
            original_air_date: null,
            local_air_date: null,
            release_date: null,
            keywords: '',
            locked: false,
            exclude_platforms: '',
            primary_parent_container_id: '',
            secondary_parent_container_id: '',
            externalVendorId: ''
        };
    }

    onUpload(event) {
        this.resourceUploadService.createResourceUploadContext(this.createdContainer, event.files);
    }

    getEntityData() {
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.onTypeChange(this.containerTypeName);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getTemplateProfiles() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'profile', 'published', ['title'])
            .subscribe(
                res => {
                    this.templateProfiles = (res.results.filter(t => t.data.profile_type === 'template'));
                    for (let template of this.templateProfiles) {
                        let templateValue = { label: template['data']['name'], value: template['data']['bundle_id'] };
                        this.contentTemplates.push(templateValue);
                    }
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onTemplateChange() {

        this.container = new Container();

        let selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(this.selectedTemplate, 10));

        let containerJSON = selectedTemplateContainer.data.payload;
        if (typeof containerJSON == 'string') {
            containerJSON = JSON.parse(containerJSON);
        }

        this.container = Object.assign(this.container, containerJSON.container);

        if (!('published_date' in containerJSON.container)) {
            this.container.published_date = null;
        }
        if ('type' in containerJSON.container) {
            this.onTypeChange(containerJSON.container.type);
        }

        if ('additional_parents_options' in containerJSON) {
            this.additionalParentsVisible = true;
            this.setAdditionaParentOptions(containerJSON['additional_parents_options']);
        }
        else {
            this.additionalParentsVisible = false;
        }

        this.container.data['template_profile_guid'] = selectedTemplateContainer.guid;

        if ('default_tags' in containerJSON) {
            this.container.tags = containerJSON['default_tags'];
        }

        // Handle placeholder available date
        if (this.container.availableDate === null) {
            this.container.availableDate = new Date();
        }

        if ('default_series_filter' in containerJSON) {
            this.filteredSeriesList = this.getFilteredSeriesFromTemplate(containerJSON['default_series_filter'], this.series);
        }
        else {
            this.filteredSeriesList = this.series;

            if (this.filteredSeriesList[0]) {
                this.setDefaultSeries(this.filteredSeriesList[0].guid);
            }
        }

        if ('default_series' in containerJSON) {
            this.setDefaultSeries(containerJSON['default_series']);
        }

        if (!('author' in this.container.data)) {
            let user = this.authService.getAuthenticatedUser();
            if (user && 'email' in user) {
                this.container.data['author'] = user['email'];
            }
        }


        console.log();
        console.log("Template change");
        console.log(selectedTemplateContainer);
        console.log(containerJSON.container);
        console.log(this.container);
    }

    setAdditionalParents(e) {
        if ("selected" in e) {

            let selectedParents: any[] = e['selected'];
            if (selectedParents.length > 0) {
                this.container.data['additional_parents'] = e['selected'];
            }
            else if (selectedParents.length == 0) {
                delete this.container.data['additional_parents']
            }

        }
    }


    getFilteredSeriesFromTemplate(seriesFilterArray: any[], allSeriesArray: any[]) {
        let filteredSeries = [];
        allSeriesArray.forEach((element, index) => {
            let series = seriesFilterArray.find(t => t === element.guid);
            if (series) filteredSeries.push(element);
        })

        console.log(filteredSeries)
        return filteredSeries;
    }

    getSeriesData() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'series', this.seriesStatusList, ['title'])
            .subscribe(
                res => {
                    this.series = res.results
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    setDefaultSeries(guid) {
        let defaultSeries = this.series.find(t => t.guid === guid);
        this.selectedSeries = defaultSeries;
        this.seriesContainerId = defaultSeries.id;
        this.onSeriesChange();
    }

    setAdditionaParentOptions(options) {

        if ('filter' in options) {
            this.additionalParentsQuery = { query_parameters: options['filter'] }
        }

        if ('dependency' in options) {
            this.additionalParentsSeriesDependency = options['dependency'];
        }

        if ((this.additionalParentsSeriesDependency == false)) {
            this.additionalParentsEnabled = true;
        }
        else {
            this.additionalParentsEnabled = false;
        }

        if ('multiselect' in options) {
            this.additionalParentsMultiselect = options['multiselect'];
        }
        else {
            this.additionalParentsMultiselect = false;
        }


        if ('required' in options) {
            this.additionalParentsRequired = options['required'];
        }
        else {
            this.additionalParentsRequired = false;
        }

        console.log("setAdditionaParentOptions", options, this.additionalParentsEnabled, this.additionalParentsFilter, this.additionalParentsMultiselect);
    }


    onSeriesChange() {

        let seriesContainer = this.series.find(t => t.id === parseInt(this.seriesContainerId, 10));
        this.container.data['show_title'] = seriesContainer.data.title;
        this.container.data['tv_rating'] = seriesContainer.data.tv_rating;
        this.container.data['genre'] = seriesContainer.data.genre;
        this.container.data['primary_parent_container_id'] = seriesContainer.guid;

        if (this.additionalParentsVisible === true) {
            this.additionalParentsEnabled = true
            let queryString = this.additionalParentsQuery['query_parameters']
            if (this.additionalParentsSeriesDependency) {
                queryString = queryString + "&parent=" + seriesContainer.guid
            }

            this.additionalParentsFilter = {};
            this.additionalParentsFilter['query_parameters'] = queryString;
            console.log("Series Changed", this.additionalParentsFilter);
        }
    }

    onTypeChange(containerTypeName) {
        this.selectedEntityType = (this.entityTypes.find(t => t.name === containerTypeName));
        this.entityTypeId = this.selectedEntityType.id;
        this.container.type = this.selectedEntityType;
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = (this.ngForm.form.status === 'VALID');

        // check if the title has been entered
        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the title' });
            valid = false;
        }

        // check if the template has been selected
        const selectedTemplate_id = parseInt(this.selectedTemplate, 10);
        if (isNaN(selectedTemplate_id)) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please select a template' });
            valid = false;
        } else {
            // else load the template profile, get the required and optional files and check if them exists in the attached files to the current form
            let selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(this.selectedTemplate, 10));
            let containerJSON = selectedTemplateContainer.data.payload;
            if (typeof containerJSON == 'string') {
                containerJSON = JSON.parse(containerJSON);
            }

            let filesToUpload = this.uploadComponent.files.map(f => f['name']);
            let requiredFiles = containerJSON.required_files || [];
            let optionalFiles = containerJSON.optional_files || [];

            let uploadCheck = this.resourceUploadService.checkRequiredFiles(filesToUpload, requiredFiles, optionalFiles);
            let allowedFiles = uploadCheck['allowed']['required'].concat(uploadCheck['allowed']['optional']);

            // Fail if no files to upload, or missing any required
            if (filesToUpload.length == 0 || uploadCheck['missing']['required'].length > 0) {
                this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please attach media files' });
                if (uploadCheck['missing']['required'].length) {
                    for (let spec of uploadCheck['missing']['required']) {
                        this.msgs.push({
                            severity: 'info',
                            summary: 'Required',
                            detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
                        });
                    }
                    for (let spec of uploadCheck['missing']['optional']) {
                        this.msgs.push({
                            severity: 'info',
                            summary: 'Optional',
                            detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
                        });
                    }
                }
                valid = false;
            }



            if ((this.additionalParentsRequired === true) && !('additional_parents' in this.container.data)) {
                this.msgs.push({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please select an additional parent.',
                });

                valid = false;

            }

            // Also fail if there are files not matching required/optional
            // (and presuming there are required/optional)
            if (allowedFiles.length > 0 && uploadCheck['extra'].length > 0) {
                this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please remove unused media files' });
                for (let fileName of uploadCheck['extra']) {
                    this.msgs.push({ severity: 'info', summary: 'Unused', detail: `${fileName}` });
                }
                valid = false;
            }

            // Ensure all files non-zero size
            for (let file of this.uploadComponent.files) {
                if (file['size'] <= 0) {
                    this.msgs.push({ severity: 'info', summary: 'Invalid', detail: `${file['name']}` });
                    valid = false;
                }
            }
        }

        if (valid === true) {
            this.submitEnabled = false;
            this.containerService
                .save(this.container)
                .subscribe(
                    res => {
                        console.log(res);
                        console.log('Container created:', this.container);
                        this.msgs.push({ severity: 'success', summary: 'Media Object Created', detail: '' });
                        this.createdContainerId = res['id'];
                        this.createdContainerGuid = res['guid'];
                        this.createdContainer = res;
                        this.uploadComponent.upload()
                        this.resetUploadData()
                        this.router.navigate(['/workflow/worklist']);
                        this.formSubmit.emit();
                    },
                    err => {
                        this.submitEnabled = true;
                        console.log(err);
                    },
                    () => this.isLoading = false
                );
            console.log('container submitted', this.container);
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
        this.resetUploadData();
    }

    removeFile(file: File) {
        const index = this.uploadComponent.files.indexOf(file);
        this.uploadComponent.remove(null, index);
    }

    getSizeInMegaBytes(file: File) {
        return file ? file.size / (1024 * 1024) : 0;
    }

}
