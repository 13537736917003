import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { JobsService } from '../../../content/services/jobs/jobs.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { ResourceListComponent } from '../../../content/components/resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { PipelineFormComponent } from '../../forms/pipeline-form/pipeline-form.component';

@Component({
    selector: 'pipeline-edit',
    providers: [
        ContainerService,
        JobsService
    ],
    templateUrl: 'pipeline-edit.component.html',
    styleUrls: ['pipeline-edit.component.css']
})
export class PipelineEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: PipelineFormComponent;
    @ViewChild('inputs') inputsEditor: JsonEditorComponent;
    @ViewChild('results') resultsEditor: JsonEditorComponent;
    @ViewChild('steps') stepsEditor: JsonEditorComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private workers: Array<Container>;
    private validSections: Object = {};
    private inputsObject: Object = {};
    private resultsObject: Object = {};
    private stepsArray: Array<any> = [];
    private editorOptionsInputs: JsonEditorOptions;
    private editorOptionsResults: JsonEditorOptions;
    private editorOptionsSteps: JsonEditorOptions;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('pipeline edit initialized');
        this.editorOptionsInputs = new JsonEditorOptions();
        this.editorOptionsInputs.mode = 'tree';
        this.editorOptionsInputs.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsInputs.statusBar = true;
        this.editorOptionsInputs.expandAll = true;
        this.editorOptionsInputs.onChange = () => this.changeJson('inputs', this.inputsEditor);

        this.editorOptionsResults = new JsonEditorOptions();
        this.editorOptionsResults.mode = 'tree';
        this.editorOptionsResults.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsResults.statusBar = true;
        this.editorOptionsResults.expandAll = true;
        this.editorOptionsResults.onChange = () => this.changeJson('results', this.resultsEditor);

        this.editorOptionsSteps = new JsonEditorOptions();
        this.editorOptionsSteps.mode = 'tree';
        this.editorOptionsSteps.modes = ['code', 'text', 'tree', 'view'];
        this.editorOptionsSteps.statusBar = true;
        this.editorOptionsSteps.expandAll = true;
        this.editorOptionsSteps.onChange = () => this.changeJson('steps', this.stepsEditor);

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.container = res;
                        this.initValues();
                        this.filterWorkers();
                        this.validateFormData();
                        console.log("pipeline", this.container);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                    this.initValues();
                    this.filterWorkers();
                    this.validateFormData();
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onReorder(e) {
        console.log('reorder', e);

        this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.refetchContainer() // this.isLoading = false
            );

    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];
        let valid = true;
        for (let field of ['Inputs', 'Results', 'Steps']) {
            if (this.validSections[field.toLowerCase()] === false) {
                valid = false;
                this.msgs.push({
                    severity: 'error',
                    summary: `${field}: Invalid JSON`,
                    detail: 'Cannot save invalid JSON.'
                });
            }
        }
        return valid;
    }

    onSubmit(e) {
        console.log('submit', e.model);
        let valid = this.validateFormData();
        if (!valid) {
            return;
        }
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this pipeline?')) {
                this.refetchContainer();
            }
        }
    }

    private onRemove(e, worker) {
        // Eval steps and ensure worker unused
        let steps = this.container.data['steps'];
        if (steps.some(s => s['worker_id'] == worker.guid)) {
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Worker In Use',
                detail: 'Cannot remove worker used by one or more steps.'
            });
        } else if (confirm('Are you sure you want to remove this worker?')) {
            console.log('remove', e, worker);
            this.containerService
                .unrelate(this.container, worker)
                .subscribe(
                    res => {
                        console.log(res);
                        let i = this.workers.indexOf(worker);
                        if (i >= 0) {
                            this.workers.splice(i, 1);
                        }
                    },
                    err => console.log(err)
                );
        }
    }

    private initValues() {
        if (!this.container.data['inputs']) {
            this.container.data['inputs'] = {};
        }
        if (!this.container.data['results']) {
            this.container.data['results'] = {};
        }
        if (!this.container.data['steps']) {
            this.container.data['steps'] = [];
        }
        // Basically a workaround for type diff between
        // editor's JSON type and the fields' types
        this.inputsObject = this.container.data['inputs'];
        this.resultsObject = this.container.data['results'];
        this.stepsArray = this.container.data['steps'];
    }

    private filterWorkers() {
        let workers = this.container.containers.filter(t => t.type.name === 'worker');
        workers.sort((a, b) => {
            const key_a = a.data["name"];
            const key_b = b.data["name"];
            return key_a > key_b ? 1 : key_b > key_a ? -1 : 0;
        });
        this.workers = workers;
    }

    private changeJson(field: string, editor: JsonEditorComponent) {
        try {
            var editorData = editor.get();
        } catch (e) {
            // Keep current value
            this.validSections[field] = false;
            return;
        }
        this.validSections[field] = true;
        this.container.data[field] = editorData;
    }

}
