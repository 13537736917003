<page-header [showSpinner]="isContentMetricsLoading" [error]="error?.statusText">
</page-header>
<div>
    <div *ngIf="!isContentMetricsLoading" class="card card-w-title">
        <h1>
            Content Activity
            <button pButton type="button" (click)="getMetrics()" label="Refresh" class="p-button-success" icon="fa fa-refresh" style="float:right;margin-left:4px;"></button>
        </h1>

        <div class="ui-g dashboard">
            <div class="ui-g-12 ui-md-6 ui-lg-3">
                <div class="ui-g overview-box overview-box-1">
                    <div class="ui-g-4 overview-box-icon">
                        <div class="fa fa-fw fa-file-text"> </div>
                    </div>
                    <div class="ui-g-8">
                        <span class="overview-box-count">{{this.contentMetrics['all_content_count']}}</span>
                        <span class="overview-box-name">CONTENT ITEMS</span>
                        <span class="overview-box-rate">+10%</span>
                    </div>
                </div>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-3">
                <div class="ui-g overview-box overview-box-2">
                    <div class="ui-g-4 overview-box-icon">
                        <div class="fa fa-archive"> </div>
                    </div>
                    <div class="ui-g-8">
                        <span class="overview-box-count">{{this.contentMetrics['total_episode_count']}}</span>
                        <span class="overview-box-name">EPISODES</span>
                        <span class="overview-box-rate">+34%</span>
                    </div>
                </div>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-3">
                <div class="ui-g overview-box overview-box-3">
                    <div class="ui-g-4 overview-box-icon fa fa-archive">

                    </div>
                    <div class="ui-g-8">
                        <span class="overview-box-count">{{this.contentMetrics['total_series_count']}}</span>
                        <span class="overview-box-name">SERIES</span>
                        <span class="overview-box-rate">+15%</span>
                    </div>
                </div>
            </div>
            <div class="ui-g-12 ui-md-6 ui-lg-3">
                <div class="ui-g overview-box overview-box-4">
                    <div class="ui-g-4  fa fa-fw fa-file-text">
                    </div>
                    <div class="ui-g-8">
                        <span class="overview-box-count">{{this.contentMetrics['new_other_media_count']}}</span>
                        <span class="overview-box-name">OTHER MEDIA</span>
                        <span class="overview-box-rate">+24%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isContentMetricsLoading" class="card card-w-title">
        <h1>
            Newly Added
            <button pButton type="button" (click)="getContentMetrics()" label="Refresh" class="p-button-success" icon="fa fa-refresh" style="float:right;margin-left:4px;"></button>
        </h1>

        <p-table [value]="this.contentMetrics['latest_content_ingested']" 
                [rows]="10" 
                [paginator]="true" 
                selectionMode="single"
                responsive="true"
                styleClass="p-datatable-gridlines" 
                [(selection)]="container">
            <ng-template pTemplate="header">
                <tr>
                    <th>Content Id</th>
                    <th>Title</th>
                    <th>Show Title</th>
                    <th [style]="{'width':'5.5em'}">Type</th>
                    <th [style]="{'width':'5.5em'}">Status</th>
                    <th [style]="{'width':'15%'}">Publish Date</th>
                    <th [style]="{'width':'6.5em'}"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-container>
                <tr>
                    <td [style]="{'word-break':'break-all'}">{{container.guid}}</td>
                    <td>{{container.title}}</td>
                    <td>{{container.show_title}}</td>
                    <td>
                        <div class="label label-default">{{container.type | titlecase}}</div>
                    </td>
                    <td>
                        <div *ngIf="container.status == 'new'" class="label label-default">New</div>
                        <div *ngIf="container.status == 'waiting'" class="label label-info">Waiting</div>
                        <div *ngIf="container.status == 'processing'" class="label label-info">Processing</div>
                        <div *ngIf="container.status == 'error'" class="label label-danger">Error</div>
                        <div *ngIf="container.status == 'rejected'" class="label label-danger">Rejected</div>
                        <div *ngIf="container.status == 'unpublished'" class="label label-danger">Unpublished</div>
                        <div *ngIf="container.status == 'ready'" class="label label-primary">Ready</div>
                        <div *ngIf="container.status == 'approved'" class="label label-primary">Approved</div>
                        <div *ngIf="container.status == 'scheduled'" class="label label-warning">Scheduled</div>
                        <div *ngIf="container.status == 'published'" class="label label-success">Published</div>
                    </td>
                    <td [style]="{'word-break':'break-all'}">{{container.pub_date}}</td>
                    <td>
                        <button *ngIf="container.type == 'media'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/media', container.id, '']" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                        <button *ngIf="container.type == 'episode'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/episodes', container.id, '']" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                        <button *ngIf="container.type == 'movie'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/movie', container.id]" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                        <button *ngIf="container.type == 'season'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/season', container.id, '']" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                        <button *ngIf="container.type == 'collection'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/collection', container.id, '']" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                        <button *ngIf="container.type == 'series'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/series', container.id]" icon="pi pi-pencil" style="width:100%;max-width:6em;font-size:13px;"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="card card-w-title" >
        <spinner *ngIf="isPipelineMetricsLoading && !isContentMetricsLoading"></spinner>
            <h1>
                Pipeline Status
                <p-toolbar>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <input type="text" pInputText placeholder="Filter BundleID" [(ngModel)]="bundle_id" (ngModelChange)="changedBundleID($event)" style="text-align:center;height:100%;width:100%;"/>
                        </div>
                        <div class="ui-g-3">
                            <input type="text" pInputText placeholder="Filter Context ID" [(ngModel)]="context_id" (ngModelChange)="changedContextID($event)" style="text-align:center;height:100%;width:100%;"/>
                        </div>
                        <div class="ui-g-3">
                            <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="label" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                        </div>
                        <div class="ui-g-3">
                            <button pButton type="button" (click)="refreshPipelineMetrics()" label="Refresh" class="p-button-success" icon="fa fa-refresh" style="float:right;margin-left:4px;"></button>
                        </div>
                    </div>
                </p-toolbar>
            </h1>
        
            <p-table [columns]="pipelineColumns" 
                [value]="pipelineMetricsData" 
                [lazy]="true" 
                (onLazyLoad)="getPipelineMetricsLazy($event)" 
                [rows]="pipelineMetricsLimit" 
                [paginator]="true" 
                [first]="pipelineMetricsOffset"
                [totalRecords]="pipelineMetricsCount"
                styleClass="p-datatable-gridlines"
                paginatorPosition="bottom"
                [responsive]="true"
                dataKey="id">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 2.25em"></th>
                        <th *ngFor="let col of columns" [ngStyle]="getHeaderStyle(col)">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pipeline let-expanded="expanded" let-columns="columns">
                    <tr *ngIf="!isLoading">
                        <td>
                            <a [pRowToggler]="pipeline">
                                <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                            </a>
                        </td>
                        <td *ngFor="let col of columns">
                            <ng-container [ngSwitch]="col.field">
                              <div *ngSwitchCase="'pipeline_type'" class="label label-info">{{pipeline.pipeline_type | titlecase}}</div>
                              <div *ngSwitchCase="'status'" [ngClass]="getStatusStyleClass('pipeline', pipeline.status)">{{pipeline.status | titlecase}}</div>
                              <div *ngSwitchDefault style="word-wrap:break-word;" [innerHTML]="getFieldData(pipeline, col)"></div>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-pipeline>
                  <tr class="row-expanded">
                    <td [attr.colspan]="pipelineColumns.length+1">
                      <div class="ui-g message-wrapper">
                          <div class="ui-g-4 ui-sm-12"><strong>Started At:</strong> {{pipeline.started_at | date:'yyyy-MM-dd HH:mm:ss' }}</div>
                          <div class="ui-g-4 ui-sm-12"><strong>Stopped At:</strong> {{pipeline.stopped_at | date:'yyyy-MM-dd HH:mm:ss'}}</div>
                          <div class="ui-g-4 ui-sm-12"><span><strong>Pipeline Action</strong></span>
                            &nbsp;<pipeline-action [pipeline]="pipeline" (onExecuteAction)="onExecuteAction($event)"></pipeline-action>
                          </div>
                        <div class="error-wrapper">
                            <div *ngIf="pipeline.error_data" class="ui-g-3 ui-sm-12"><span class="label label-danger">Error</span><pre>{{pipeline.pipeline_error}}</pre></div>
                            <div class="ui-g-5 ui-sm-12" *ngIf="pipeline.error_data && pipeline.error_data.context"><span class="label label-danger">Worker Error</span><span class="label label-default">{{this.failedStep}}</span><pre>{{pipeline.worker_error}}</pre>
                            </div>
                        </div>
                      </div>
                      <p-table [columns]="workerColumns"
                            styleClass="p-datatable-gridlines" 
                            [value]="pipeline.workers">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let col of workerColumns" [ngStyle]="getHeaderStyle(col)">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-worker>
                          <tr>
                              <td *ngFor="let col of workerColumns">
                                <ng-container [ngSwitch]="col.field">
                                  <div *ngSwitchCase="'label'" class="label label-default" style="margin-left: 0.5em;"> {{worker.label}}</div>
                                  <div *ngSwitchCase="'status'" [ngClass]="getStatusStyleClass('worker', worker.status)">{{worker.status | titlecase}}</div>
                                  <div *ngSwitchDefault style="word-wrap:break-word; margin-left: 0.5em;" [innerHTML]="getFieldData(worker, col)"></div>
                                </ng-container>
                              </td>
                          </tr>
                        </ng-template>
                  </p-table>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
