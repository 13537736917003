import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../../content/models';
import { SearchTableComponent } from './../../../content/components/search-table/search-table.component';


@Component({
    selector: 'worklist-table',
    templateUrl: 'worklist-table.component.html',
    styleUrls: ['worklist-table.component.css']
})
export class WorklistTableComponent implements OnInit {
    @Input() containers: any;
    @Input() defaultSearchTerm: string;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(SearchTableComponent) searchTable:  SearchTableComponent;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        console.log('Container');
        console.log(this.containers);
    }

    findParentContainerId(container) {

        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id || '']);
    }

    onLazyLoad(event)
    {
        this.rowSelect.emit({
            container: null
        });
    }

    handleRowSelect(event) {
        console.log("Handling Event")
        console.log(event['container'])
        this.rowSelect.emit({
            container: event['container']
        });
    }

    rowUpdate(guid) {
      this.searchTable.rowUpdate(guid);
    }
}
