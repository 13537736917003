<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button" (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up" style="float:right;"></button>
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12 ui-md-6 ui-lg-6">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" name="name" [(ngModel)]="container.data['name']" placeholder="Optional">
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-6">
                    <label for="name">Number</label>
                    <input type="text" class="form-control" name="number" [(ngModel)]="container.data.number" pKeyFilter="pnum" placeholder="Required" required>
                </div>
                <div class="ui-g-12 ui-md-4 ui-lg-4">
                    <label for="forced_update">Triggers Forced Update</label>
                    <div class="p-inputgroup">
                        <p-checkbox name="forced_update" [(ngModel)]="container.data.forced_update" binary="true"></p-checkbox>
                    </div>
                </div>
                <div class="ui-g-6 ui-md-4 ui-lg-4">
                    <label for="forced_update_min">Forced Update Minimum OS Version</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon" style="padding:0 0.5em; font-size:18px; border-right: none;">&#8805;</span>
                        <input *ngIf="container.data.forced_update" type="text" class="form-control" name="forced_update_min" [(ngModel)]="container.data.forced_update_min" pKeyFilter="pnum" placeholder="Required" required style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        <input *ngIf="!container.data.forced_update" disabled type="text" class="form-control" name="forced_update_min" [(ngModel)]="container.data.forced_update_min" pKeyFilter="pnum" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                    </div>
                </div>
                <div class="ui-g-6 ui-md-4 ui-lg-4">
                    <label for="forced_update_max">Forced Update Maximum OS Version</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon" style="padding:0 0.5em; font-size:18px; border-right: none;">&#8804;</span>
                        <input *ngIf="container.data.forced_update" type="text" class="form-control" name="forced_update_max" [(ngModel)]="container.data.forced_update_max" pKeyFilter="pnum" placeholder="Required" required style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        <input *ngIf="!container.data.forced_update" disabled type="text" class="form-control" name="forced_update_max" [(ngModel)]="container.data.forced_update_max" pKeyFilter="pnum" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                    </div>
                </div>
                <div class="ui-g-12 ui-md-4 ui-lg-4">
                    <label for="optional_update">Triggers Optional Update</label>
                    <div class="p-inputgroup">
                        <p-checkbox name="optional_update" [(ngModel)]="container.data.optional_update" binary="true"></p-checkbox>
                    </div>
                </div>
                <div class="ui-g-6 ui-md-4 ui-lg-4">
                    <label for="optional_update_min">Optional Update Minimum Version</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon" style="padding:0 0.5em; font-size:18px; border-right: none;">&#8805;</span>
                        <input *ngIf="container.data.optional_update" type="text" class="form-control" name="optional_update_min" [(ngModel)]="container.data.optional_update_min" pKeyFilter="pnum" placeholder="Required" required style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        <input *ngIf="!container.data.optional_update" disabled type="text" class="form-control" name="optional_update_min" [(ngModel)]="container.data.optional_update_min" pKeyFilter="pnum" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                    </div>
                </div>
                <div class="ui-g-6 ui-md-4 ui-lg-4">
                    <label for="optional_update_max">Optional Update Maximum Version</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon" style="padding:0 0.5em; font-size:18px; border-right: none;">&#8804;</span>
                        <input *ngIf="container.data.optional_update" type="text" class="form-control" name="optional_update_max" [(ngModel)]="container.data.optional_update_max" pKeyFilter="pnum" placeholder="Required" required style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        <input *ngIf="!container.data.optional_update" disabled type="text" class="form-control" name="optional_update_max" [(ngModel)]="container.data.optional_update_max" pKeyFilter="pnum" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                    </div>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>
        
    </div>
</form>
