<p-autoComplete #autoCmpl
                [(ngModel)]="container['data']['segment_keywords']"
                (ngModelChange) = "onChange($event)"
                (onSelect) = "onKeywordSelect($event)"
                [suggestions]="suggestedKeywords"
                (completeMethod)="getKeywordSuggestions($event)"
                (onBlur)="onDeselect($event)"
                [minLength]="1"
                placeholder="Keywords"
                field="label"
                name="keywords"
                [multiple]="true"
                appendTo="body"
                [autoHighlight]="true"
                delay="300"
                emptyMessage="Invalid keyword. Try again"
                unique="true">
</p-autoComplete>