import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PolicyFormComponent } from './../../forms/policy-form/policy-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { Message } from 'primeng/api';

@Component({
    selector: 'policy-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'policy-edit.component.html',
    styleUrls: ['policy-edit.component.css']
})
export class PolicyEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: PolicyFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    private isProcessing = false;

    // private dataSet: Array<any>;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id, 'full', 0)
                .subscribe(
                    res => {
                        this.container = res;
                        // this.dataSet = this.toIterable(this.container.data);
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res;
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    // from MapToIterablePipe
    toIterable(dict: Object): Array<any> {
        var a = [];
        for (var key in dict) {
            if (dict.hasOwnProperty(key)) {
                a.push({ key: key, val: dict[key] });
            }
        }
        return a;
    }

    // addProperty(newProperty : string) {
    //     if (newProperty && !this.container.data.hasOwnProperty(newProperty)) {
    //         console.log('Adding new property to policy:', newProperty);
    //         this.container.data[newProperty] = '';
    //         this.dataSet = this.toIterable(this.container.data);
    //     }
    // }

    // onEditComplete(e) {
    //     this.container.data[e.data.key] = e.data.val;
    // }

    // isDateValid(dateString: string) {
    //     var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    //     if (date_regex.test(dateString)) {
    //         return true;
    //     }
    //     return false;
    // }

    validateFormData() {
        this.msgs = [];

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Title is required for policies.' });
        }
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this policy?')) {
                this.refetchContainer();
            }
        }
    }
}
