import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EntityType, Container, Resource, History } from '../../models';
import { Observable } from 'rxjs';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class HistoryService {

    private baseUrl = `${environment.PROXY_API_BASE_URL}/content/history`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
    ) { }


    getHistoryFromId(objectId: number, objectType: string, limit: number, offset: number): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        if ( objectType === 'container') {      
            return this.http
                .get(
                    `${this.baseUrl}/containers/${objectId}/?limit=${limit}&offset=${offset}`,
                    { headers: headers}
                );
        }
        else if ( objectType == 'resource') {
            return this.http
                .get(
                    `${this.baseUrl}/resources/${objectId}/?limit=${limit}&offset=${offset}`,
                    { headers: headers}
                );
        }
        else {
            throw new Error(`Invalid input type of ${objectType}`);
        }
    }

    getHistoryDiff(objectId: number, objectType: string, current_history_id: number, target_history_id: number = -1): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        if ( objectType == 'container' ) {
            var diffUrl = `${this.baseUrl}/containers/${objectId}/${current_history_id}/diff/`;
        }
        else if ( objectType == 'resource' ) {
            var diffUrl = `${this.baseUrl}/resources/${objectId}/${current_history_id}/diff/`
        }
        else {
            throw new Error(`Invalid input type of ${objectType}`);
        }

        if ( target_history_id > -1 ){
            diffUrl += `?old_history_id=${target_history_id}`;
        }
    
        return this.http
            .get(
                `${diffUrl}`,
                { headers:headers}
            );
            
    }

    retrieveHistory(objectId: number, objectType: string, history_id: number): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        if ( objectType == 'container' ) {
            var retrieveUrl = `${this.baseUrl}/containers/`;
        }
        else if ( objectType == 'resource' ){
            var retrieveUrl = `${this.baseUrl}/resources/`;
        }
        else {
            throw new Error(`Invalid input type of ${objectType}`);
        }

        return this.http
            .get(
                `${retrieveUrl}${objectId}/${history_id}/`,
                { headers: headers}
            );
    }

    revertHistory(objectId: number, objectType: string, target_history_id: number): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        if ( objectType == 'container' ) {
            var revertUrl = `${this.baseUrl}/containers/`;
        }
        else if ( objectType == 'resource' ){
            var revertUrl = `${this.baseUrl}/resources/`;
        }
        else {
            throw new Error(`Invalid input type of ${objectType}`);
        }
        return this.http
            .post(
                `${revertUrl}${objectId}/${target_history_id}/revert/`,
                {}, { headers: headers}
            );
    }


}