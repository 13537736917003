import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LandingComponent } from './landing/landing.component';
import { FeaturedComponent } from './content/components/featured/featured.component';
import { SeriesComponent } from './content/components/series/series.component';
import { ChannelsComponent } from './content/components/channels/channels.component';
import { ContainerRootComponent } from './content/components/container-root/container-root.component';
import { ContainerEditComponent } from './content/components/container-edit/container-edit.component';
import { ContainerCreateComponent } from './content/components/container-create/container-create.component';
import { ContainerListComponent } from './content/components/container-list/container-list.component';
import { ContainerRowComponent } from './content/components/container-row/container-row.component';
import { ContainerRelateComponent } from './content/components/container-relate/container-relate.component';
import { ContainerMultiParentRelateComponent } from './content/components/container-multi-parent-relate/container-multi-parent-relate.component';

import { ResourceRootComponent } from './content/components/resource-root/resource-root.component';
import { ResourceCreateComponent } from './content/components/resource-create/resource-create.component';
import { ResourceEditComponent } from './content/components/resource-edit/resource-edit.component';
import { ResourceUploadComponent } from './content/components/resource-upload/resource-upload.component';

import { StatusComponent } from './status/status.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout.component';
import { ExternalLoginComponent } from './auth/login-external.component';
import { AuthGuard } from './auth/auth-guard.service';

import { ApplicationsComponent } from './settings/components/applications/applications.component';
import { ApplicationCreateComponent } from './settings/components/application-create/application-create.component';
import { ApplicationEditComponent } from './settings/components/application-edit/application-edit.component';

import { DistributionCreateComponent } from './settings/components/distribution-create/distribution-create.component';
import { DistributionEditComponent } from './settings/components/distribution-edit/distribution-edit.component';

import { VersionCreateComponent } from './settings/components/version-create/version-create.component';
import { VersionEditComponent } from './settings/components/version-edit/version-edit.component';

import { ConfigurationCreateComponent } from './settings/components/configuration-create/configuration-create.component';
import { ConfigurationEditComponent } from './settings/components/configuration-edit/configuration-edit.component';

import { SettingsPoliciesComponent } from './settings/components/policies/settings-policies.component';
import { SettingsIntegrationsComponent } from './settings/components/integrations/settings-integrations.component';
import { SettingsPlatformsComponent } from './settings/components/platforms/settings-platforms.component';

import { SettingsOriginsComponent } from './settings/components/origins/settings-origins.component';
import { OriginCreateComponent } from './settings/components/origin-create/origin-create.component';
import { OriginEditComponent } from './settings/components/origin-edit/origin-edit.component';

import { SettingsProvidersComponent } from './settings/components/providers/settings-providers.component';
import { ProviderCreateComponent } from './settings/components/provider-create/provider-create.component';
import { ProviderEditComponent } from './settings/components/provider-edit/provider-edit.component';

import { PlatformCreateComponent } from './content/components/platform-create/platform-create.component';
import { PlatformFormComponent } from './content/forms/platform-form/platform-form.component';
import { PlatformEditComponent } from './content/components/platform-edit/platform-edit.component';

import { PolicyCreateComponent } from './content/components/policy-create/policy-create.component';
import { PolicyEditComponent } from './content/components/policy-edit/policy-edit.component';

import { SettingsReportsComponent } from './settings/components/reports/settings-reports.component';
import { ReportEditComponent } from './settings/components/report-edit/report-edit.component';
import { ReportCreateComponent } from './settings/components/report-create/report-create.component';

import { ServerCreateComponent } from './content/components/server-create/server-create.component';
import { ServerEditComponent } from './content/components/server-edit/server-edit.component';


import { WorklistComponent } from './workflow/components/worklist/worklist.component';

import { HistoryListComponent } from './content/components/history-list/history-list.component';
import { HistoryDiffComponent } from './content/components/history-diff/history-diff.component';
import { HistoryRevertComponent } from './content/components/history-revert/history-revert.component';

import { CatalogCreateComponent } from './content/components/catalog-create/catalog-create.component';
import { CatalogFormComponent } from './content/forms/catalog-form/catalog-form.component';
import { CatalogEditComponent } from './content/components/catalog-edit/catalog-edit.component';

import { DocumentEditComponent } from './content/components/document-edit/document-edit.component';
import { DocumentCreateComponent } from './content/components/document-create/document-create.component';

import { MoviesComponent } from './content/components/movies/movies.component';
import { MoviesEditComponent } from './content/components/movies-edit/movies-edit.component';

import { EpisodeEditComponent } from './content/components/episode-edit/episode-edit.component';

import { ChannelEditComponent } from './content/components/channel-edit/channel-edit.component';

import { MediaEditComponent } from './content/components/media-edit/media-edit.component';

import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';

import { SeriesEditComponent } from './content/components/series-edit/series-edit.component';
import { SeriesCreateComponent } from './content/components/series-create/series-create.component';

import { SeasonEditComponent } from './content/components/season-edit/season-edit.component';
import { SeasonCreateComponent } from './content/components/season-create/season-create.component';

import { ToolsComponent } from './tools/tools.component';

import { CollectionEditComponent } from './content/components/collection-edit/collection-edit.component';
import { CollectionCreateComponent } from './content/components/collection-create/collection-create.component';
import { CollectionContainerComponent } from './content/components/collection-container/collection-container.component';


import { PreviewFeaturedContentComponent } from './preview/components/featured/preview-featured-content.component';
import { PreviewSeriesDetailComponent } from './preview/components/series/preview-series-detail.component';

import { ViewsComponent } from './settings/components/views/views.component';
import { ViewCreateComponent } from './settings/components/view-create/view-create.component';
import { ViewEditComponent } from './settings/components/view-edit/view-edit.component';

import { MetricsDashboardComponent } from './metrics/components/metrics-dashboard/metrics-dashboard.component';
import { MetricsReportsComponent } from './metrics/components/metrics-reports/metrics-reports.component';


import { PipelinesComponent } from './settings/components/pipelines/pipelines.component';
import { PipelineCreateComponent } from './settings/components/pipeline-create/pipeline-create.component';
import { PipelineEditComponent } from './settings/components/pipeline-edit/pipeline-edit.component';
import { PipelineFormComponent } from './settings/forms/pipeline-form/pipeline-form.component';

import { ProfileCreateComponent } from './settings/components/profile-create/profile-create.component';
import { ProfileEditComponent } from './settings/components/profile-edit/profile-edit.component';
import { ProfileFormComponent } from './settings/forms/profile-form/profile-form.component';

import { UserProfileCreateComponent } from './settings/components/user-profile-create/user-profile-create.component';
import { UserProfileEditComponent } from './settings/components/user-profile-edit/user-profile-edit.component';

import { WorkerCreateComponent } from './settings/components/worker-create/worker-create.component';
import { WorkerEditComponent } from './settings/components/worker-edit/worker-edit.component';
import { WorkerFormComponent } from './settings/forms/worker-form/worker-form.component';

import { RolesComponent } from './settings/components/roles/roles.component';
import { RoleCreateComponent } from './settings/components/role-create/role-create.component';
import { RoleEditComponent } from './settings/components/role-edit/role-edit.component';
import { RoleFormComponent } from './settings/forms/role-form/role-form.component';

import { CACampaignWorklistComponent } from './commerce/components/contextual-ads-campaign/contextual-ads-campaign.component';
import { CACampaignEditComponent } from './commerce/components/campaign-edit/campaign-edit.component';
import { CACampaignCreateComponent} from './commerce/components/campaign-create/campaign-create.component';

import { CAContextWorklistComponent } from './commerce/components/contextual-ads-context/contextual-ads-context.component';
import { CAContentGroupEditComponent } from './commerce/components/content-group-edit/content-group-edit.component';
import { CAContentGroupCreateComponent } from './commerce/components/content-group-create/content-group-create.component';

import { MediaObjectCreateComponent } from './content/components/media-object-create/media-object-create.component';



export const appRoutes: Routes = [
    { path: '', component: PreviewFeaturedContentComponent, canActivate: [AuthGuard] },
    { path: 'auth/login', component: LoginComponent },
    { path: 'auth/logout', component: LogoutComponent },
    { path: 'auth/:provider', component: ExternalLoginComponent },

    { path: 'pipelines', component: PipelinesComponent, canActivate: [AuthGuard] },
    { path: 'pipelines/create', component: PipelineCreateComponent, canActivate: [AuthGuard] },
    { path: 'pipelines/:id', component: PipelineEditComponent, canActivate: [AuthGuard] },

    { path: 'profiles/create', component: ProfileCreateComponent, canActivate: [AuthGuard] },
    { path: 'profiles/:id', component: ProfileEditComponent, canActivate: [AuthGuard] },

    { path: 'roles', component: RolesComponent, canActivate: [AuthGuard] },
    { path: 'roles/create', component: RoleCreateComponent, canActivate: [AuthGuard] },
    { path: 'roles/:id', component: RoleEditComponent, canActivate: [AuthGuard] },

    { path: 'user/profiles/create/:parentId', component: UserProfileCreateComponent, canActivate: [AuthGuard] },
    { path: 'user/profiles/:id', component: UserProfileEditComponent, canActivate: [AuthGuard] },

    { path: 'workers/create', component: WorkerCreateComponent, canActivate: [AuthGuard] },
    { path: 'workers/:id', component: WorkerEditComponent, canActivate: [AuthGuard] },

    { path: 'featured', component: FeaturedComponent, canActivate: [AuthGuard] },
    { path: 'series', component: SeriesComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'channels', component: ChannelsComponent, canActivate: [AuthGuard] },
    { path: 'channels/:id', component: ChannelEditComponent, canActivate: [AuthGuard] },

    { path: 'workflow/worklist/:searchterm', component: WorklistComponent, canActivate: [AuthGuard] },
    { path: 'workflow/worklist', component: WorklistComponent, canActivate: [AuthGuard] },

    { path: 'history/:type/:id', component: HistoryListComponent, canActivate: [AuthGuard] },
    { path: 'history/:type/:objectId/diff/:historyId', component: HistoryDiffComponent, canActivate: [AuthGuard] },
    { path: 'history/:type/:objectId/revert/:historyId/:latestHistoryId', component: HistoryRevertComponent, canActivate: [AuthGuard] },


    { path: 'containers', component: ContainerRootComponent, canActivate: [AuthGuard] },
    { path: 'containers/create', component: ContainerCreateComponent, canActivate: [AuthGuard] },
    { path: 'containers/:id', component: ContainerEditComponent, canActivate: [AuthGuard] },
    { path: 'containers/:id/relate/:type', component: ContainerRelateComponent, canActivate: [AuthGuard] },
    { path: 'containers/:id/relatetoparents/:type', component: ContainerMultiParentRelateComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

    { path: 'preview/featured', component: PreviewFeaturedContentComponent, canActivate: [AuthGuard] },
    { path: 'preview/series/:guid', component: PreviewSeriesDetailComponent, canActivate: [AuthGuard] },

    { path: 'containers/:containerId/resources', component: ResourceRootComponent, canActivate: [AuthGuard] },
    { path: 'containers/:containerId/resources/create', component: ResourceCreateComponent, canActivate: [AuthGuard] },
    { path: 'containers/:containerId/resources/upload', component: ResourceUploadComponent, canActivate: [AuthGuard] },
    { path: 'containers/:containerId/resources/:resourceId', component: ResourceEditComponent, canActivate: [AuthGuard] },


    { path: 'metrics/dashboard', component: MetricsDashboardComponent, canActivate: [AuthGuard] },
    { path: 'metrics/reports', component: MetricsReportsComponent, canActivate: [AuthGuard] },
    { path: 'status', component: StatusComponent, canActivate: [AuthGuard] },

    { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard] },
    { path: 'applications/create', component: ApplicationCreateComponent, canActivate: [AuthGuard] },
    { path: 'applications/:id', component: ApplicationEditComponent, canActivate: [AuthGuard] },

    { path: 'origins', component: SettingsOriginsComponent, canActivate: [AuthGuard] },
    { path: 'origins/create', component: OriginCreateComponent, canActivate: [AuthGuard] },
    { path: 'origins/:id', component: OriginEditComponent, canActivate: [AuthGuard] },

    { path: 'providers', component: SettingsProvidersComponent, canActivate: [AuthGuard] },
    { path: 'providers/create', component: ProviderCreateComponent, canActivate: [AuthGuard] },
    { path: 'providers/:id', component: ProviderEditComponent, canActivate: [AuthGuard] },

    { path: 'distributions/create/:appId', component: DistributionCreateComponent, canActivate: [AuthGuard] },
    { path: 'distributions/:id/:appId', component: DistributionEditComponent, canActivate: [AuthGuard] },
    { path: 'distributions/:id', component: DistributionEditComponent, canActivate: [AuthGuard] },


    { path: 'versions/create/:distId', component: VersionCreateComponent, canActivate: [AuthGuard] },
    { path: 'versions/:id/:distId', component: VersionEditComponent, canActivate: [AuthGuard] },

    { path: 'configurations/create/:distId', component: ConfigurationCreateComponent, canActivate: [AuthGuard] },
    { path: 'configurations/:id/:distId', component: ConfigurationEditComponent, canActivate: [AuthGuard] },

    { path: 'settings/platforms', component: SettingsPlatformsComponent, canActivate: [AuthGuard] },
    { path: 'settings/integrations', component: SettingsIntegrationsComponent, canActivate: [AuthGuard] },
    { path: 'settings/policies', component: SettingsPoliciesComponent, canActivate: [AuthGuard] },

    { path: 'tools', component: ToolsComponent, canActivate: [AuthGuard] },

    { path: 'platform/create', component: PlatformCreateComponent, canActivate: [AuthGuard] },
    { path: 'platform/:id', component: PlatformEditComponent, canActivate: [AuthGuard] },

    { path: 'policy/create', component: PolicyCreateComponent, canActivate: [AuthGuard] },
    { path: 'policy/:id', component: PolicyEditComponent, canActivate: [AuthGuard] },

    { path: 'reports', component: SettingsReportsComponent, canActivate: [AuthGuard] },
    { path: 'reports/create', component: ReportCreateComponent, canActivate: [AuthGuard] },
    { path: 'reports/:id', component: ReportEditComponent, canActivate: [AuthGuard] },

    { path: 'commerce/campaign/create', component: CACampaignCreateComponent, canActivate: [AuthGuard]  },
    { path: 'commerce/campaign/:id', component: CACampaignEditComponent, canActivate: [AuthGuard]  },
    { path: 'commerce/campaign', component: CACampaignWorklistComponent, canActivate: [AuthGuard]  },

    { path: 'commerce/contentgroup/create', component: CAContentGroupCreateComponent, canActivate: [AuthGuard]  },
    { path: 'commerce/contentgroup/:id', component: CAContentGroupEditComponent, canActivate: [AuthGuard]  },
    { path: 'commerce/contentgroup', component: CAContextWorklistComponent, canActivate: [AuthGuard]  },

    { path: 'server/create', component: ServerCreateComponent, canActivate: [AuthGuard] },
    { path: 'server/:id', component: ServerEditComponent, canActivate: [AuthGuard] },

    { path: 'catalog/create', component: CatalogCreateComponent, canActivate: [AuthGuard] },
    { path: 'catalog/:id', component: CatalogEditComponent, canActivate: [AuthGuard] },
   // { path: 'episode/create', component: EpisodeCreateComponent,   canActivate: [AuthGuard] },

    { path: 'document/create', component: DocumentCreateComponent, canActivate: [AuthGuard] },
    { path: 'document/:id', component: DocumentEditComponent, canActivate: [AuthGuard] },

    { path: 'mediaobject/create', component: MediaObjectCreateComponent, canActivate: [AuthGuard] },

    { path: 'episodes/:id/:parentId', component: EpisodeEditComponent, canActivate: [AuthGuard] },
    { path: 'episodes/:id', component: EpisodeEditComponent, canActivate: [AuthGuard] },

    { path: 'series/create', component: SeriesCreateComponent, canActivate: [AuthGuard] },
    { path: 'series/:id', component: SeriesEditComponent, canActivate: [AuthGuard] },
    { path: 'seasons/:id', component: SeasonEditComponent, canActivate: [AuthGuard] },
    { path: 'seasons/create/:parentId', component: SeasonCreateComponent, canActivate: [AuthGuard] },

    { path: 'collections/create', component: CollectionCreateComponent, canActivate: [AuthGuard] },
    { path: 'collections/:id', component: CollectionEditComponent, canActivate: [AuthGuard] },

    { path: 'season/:id/:parentId', component: SeasonEditComponent, canActivate: [AuthGuard] },
    { path: 'collection/:id/:parentId', component: CollectionEditComponent, canActivate: [AuthGuard] },
    { path: 'episode/:id/:parentId', component: EpisodeEditComponent, canActivate: [AuthGuard] },
    { path: 'media/:id/:parentId', component: MediaEditComponent, canActivate: [AuthGuard] },
    { path: 'media/:id', component: MediaEditComponent, canActivate: [AuthGuard] },

    { path: 'views', component: ViewsComponent, canActivate: [AuthGuard] },
    { path: 'view/create/:parentId', component: ViewCreateComponent, canActivate: [AuthGuard] },
    { path: 'view/:id', component: ViewEditComponent, canActivate: [AuthGuard] },
    { path: 'movies', component: MoviesComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    { path: 'movie/:id', component: MoviesEditComponent, canActivate: [AuthGuard] },
];
