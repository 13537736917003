import { Component, Inject, forwardRef } from '@angular/core';
import { AppComponent } from './app.component';
import { AppContext } from './app.context';
import { ResourceUploadService } from '../app/upload/resource-upload.service';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../app/auth/auth.service'

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.css']
})
export class AppTopBarComponent {

    constructor(
        public app: AppComponent,
        public appContext: AppContext,
        public resourceUploadService: ResourceUploadService,
        private confirmationService: ConfirmationService,
        private authService: AuthService
    ) {
        console.log(resourceUploadService);
    }

    logoutConfirm() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to log out?',
            accept: () => {
                console.log('confirm logout');
                this.authService.logout(true);
            }
        });
    }
}
