<button pButton type="button" label="Reparent" icon="pi pi-arrow-right"
  style="float:right;" (click)="showDialog()"
  ></button>

<p-dialog header="Reparent" 
        [modal]="true"
        [(visible)]="displayDialog" 
        responsive="true">

  <spinner *ngIf="isSeriesLoading || isPrimaryParentLoading || isSeasonLoading || isSecondaryParentLoading"></spinner>
    <div class="ui-g-6" *ngIf="!isSeriesLoading && !isPrimaryParentLoading">
        <label for="title">Series</label>
            <select [(ngModel)]="seriesContainerId" name="filteredSeriesList" class="form-control" (ngModelChange)="onSeriesChange()">
              <option [value]=""></option>
              <ng-container *ngFor="let item of filteredSeriesList">
              <option  *ngIf="item.status=='published'" [value]="item.id">{{item.title}}</option>
              <option  *ngIf="item.status!='published'" [value]="item.id">{{item.title}} ({{item.status}})</option>
              </ng-container>
            </select>
    </div>
    <div class="ui-g-6" *ngIf="!isSeriesLoading && !isPrimaryParentLoading && !isSeasonLoading && !isSecondaryParentLoading">
      <label for="title">Seasons</label>
      <select [(ngModel)]="selectedSeasonId" (ngModelChange)="onSeasonChange()" name="filteredSeasonList" class="form-control" [disabled]="seasons.length == 0">
            <option [value]=""></option>
            <option *ngFor="let season of filteredSeasonList" [value]="season.id">{{ season.data.season_number }}</option>
      </select>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="saveParents()"
          label="Save"></button>
        <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false"
          label="Close" class="p-button-secondary"></button>
    </p-footer>

</p-dialog>
