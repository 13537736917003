export const genre = [
    {'label':'<None>','value':''},
    {'label':'Action','value':'action'},
    {'label':'Adventure','value':'adventure'},
    {'label':'Animation','value':'animation'},
    {'label':'Anime','value':'anime'},
    {'label':'Biography','value':'biography'},
    {'label':'Bollywood','value':'bollywood'},
    {'label':'Classics','value':'classics'},
    {'label':'Comedy','value':'comedy'},
    {'label':'Crime','value':'crime'},
    {'label':'Documentary','value':'documentary'},
    {'label':'Drama','value':'drama'},
    {'label':'Educational','value':'educational'},
    {'label':'Fantasy','value':'fantasy'},
    {'label':'Game Show','value':'game_show'},
    {'label':'History','value':'history'},
    {'label':'Holiday','value':'holiday'},
    {'label':'Horror','value':'horror'},
    {'label':'Independent','value':'independent'},
    {'label':'Kids and Family','value':'kids_and_family'},
    {'label':'LGBT (deprecated)','value':'lgbt'},
    {'label':'LGBTQ','value':'lgbtq'},
    {'label':'Music','value':'music'},
    {'label':'Musical','value':'musical'},
    {'label':'Mystery','value':'mystery'},
    {'label':'News','value':'news'},
    {'label':'Novelas','value':'novelas'}, 
    {'label':'Reality','value':'reality'},
    {'label':'Romance','value':'romance'},
    {'label':'Sci-Fi','value':'sci_fi'},
    {'label':'Short Films','value':'short_films'},
    {'label':'Special Interest','value':'special_interest'},
    {'label':'Sports','value':'sports'},
    {'label':'Talk Show','value':'talk_show'},
    {'label':'Teens','value':'teens'},
    {'label':'Thriller','value':'thriller'},
    {'label':'Travel','value':'travel'},
    {'label':'Variety','value':'variety'},
    {'label':'War','value':'war'},
    {'label':'Western','value':'western'},
];

export const pipelineTypes = {
    'all': 'All',
    'ingest': 'Ingest',
    'upload': 'Upload',
    'export': 'Export',
    'media': 'Media',
    'resource': 'Resource',
    'availability': 'Availability',
    'policy': 'Policy',
    'relation': 'Relation',
    'testing': 'Testing',
    'other': 'Other',
    'admin': 'Admin',
    'commerce': 'Commerce',
}
