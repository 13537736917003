import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { EpisodeRowComponent } from '../episode-row/episode-row.component';
import { SeasonFormComponent } from '../../forms/season-form/season-form.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../container-create/maptoiterable.pipe';
import { EntityType, Container } from '../../models';

@Component({
    selector: 'season-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'season-create.component.html',
    styleUrls: ['season-create.component.css']
})
export class SeasonCreateComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container = new Container();
    private error: HttpResponse<any>;
    public isLoading = false;
    private parentId: number;
    private errorMsg: string;
    private parentContainer: Container;
    private eType: EntityType = new EntityType();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            let parentId = +params['parentId']; // + converts string to number
            console.log('parentId', parentId);

            this.isLoading = true;
            this.containerService
                .get(parentId)
                .subscribe(
                    res => {
                        console.log('res', res);
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });

        console.log('Season create initialized');
        this.container.is_enabled = false;
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.container = res;
                    this.containerService
                        .relate(this.parentContainer, this.container)
                        .subscribe(
                            res => {
                                console.log(res);
                                this.router.navigate(['/season', this.container.id, this.parentContainer.id], { replaceUrl: true });
                            },
                            err => {
                                console.log(err);
                                this.errorMsg = 'Relationship already exists';
                            }
                        );

                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onCancel(e) {
        this.location.back();
    }
}
