import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
import { AppContext } from '../../../app.context';


@Component({
    selector: 'container-relate-form',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-relate-form.component.html'
})
export class ContainerRelateFormComponent implements OnInit {
    @Input() parentContainer: Container;
    @Input() filterType: string;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    public searchText = '';
    public isLoading = false;
    private limit: number;
    public offset: number = 0;
    public results = [];
    public resultsCount: number;
    public selected = [];
    private lastSearch = null;
    private lastSearchTerms = [];
    public selectedStatus: string;
    public selectedOrigin: string;
    public originList = [];
    public statusList = [];
    constructor(
        private containerService: ContainerService,
        private appContext: AppContext
    ) {}

    ngOnInit() {
        console.log('filterType', this.filterType);
        this.selectedOrigin = this.appContext.activeOrigin
        this.search([],this.selectedOrigin);
        this.selectedStatus = 'published'
        this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));

        console.log("StatusList:",this.statusList)
        console.log(this.appContext.originContainers)
        
        this.appContext.originContainers.forEach((element, index) => {   
            this.originList.push({label: element['data']['label'], value:element['data']['origin_id']});
        })
     
    }

    search(terms, origin, status = 'published') {
        this.lastSearchTerms = terms;
        if (this.lastSearch) {
            console.log(this.lastSearch);
            this.lastSearch.unsubscribe();
            this.lastSearch = null;
        }

        this.isLoading = true;
        this.lastSearch = this.containerService
            .detailedSearch(this.limit, this.offset, terms, this.filterType, status, ['title'], origin)
            .subscribe(
                res => {
                    console.log('res', res);
                    this.resultsCount = res.count
                    this.results = res.results
                        .filter(c => c.id !== this.parentContainer.id);   // remove parent container
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onAttemptSearch(e) {
        if (e.keyCode === 13) {
            this.onSearch(e);
        }
        else if (this.searchText.length === 1 && e.keyCode === 8 && this.lastSearchTerms !== []) {
            this.searchText = '';
            this.onSearch(e);
        }
    }

    onChangedOrigin(e)
    {
        this.selectedOrigin = e.value;
        this.onSearch(e);
    }

    onChangedFilteredStatus(e) {
        this.selectedStatus = e.value;
        console.log("new selected status",this.selectedStatus,event)
        this.onSearch();
    }


    onSearch(e=null) {
        this.offset = 0
        if (this.searchText !== '') {
            this.search(this.searchText.split(' ,.<>/?`~!*;:"\''), this.selectedOrigin, this.selectedStatus);
        }
        else {
            this.search([],this.selectedOrigin, this.selectedStatus);
        }
    }

    onLazyLoad(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.search(this.lastSearchTerms,this.selectedOrigin, this.selectedStatus);
    }

    onSubmit(e) {
        console.log('container relate submitted', this.selected);
        this.formSubmit.emit({
            containers: this.selected
        });
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}
