<div class="card card-w-title top-level-card">
    <h1>Edit View{{container ? ': ' + (container.data['name'] ? container.data['name'] : ''): ''}}</h1>

    <page-header title="Edit View{{container ? ': ' + (container.data['name'] ? container.data['name'] : ''): ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <view-form #form class="ui-g-12 ui-g-nopad" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formChangesScrapped)="onScrap($event)" (formCancelled)="onBack($event)"></view-form>

        <hr>

        <p-panel class="ui-g-12">
            <p-header>
                <div class="ui-g">
                    <span class="p-panel-title ui-g-12 ui-md-4 ui-lg-4" style="padding:0;font-size:16px;">View Collection</span>
                    <div class="ui-g-12 ui-md-8 ui-lg-8" style="padding:0;">

                        <button pButton type="button" label="Episode" [routerLink]="['/containers', container?.id, 'relate', 'episode']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Movie" [routerLink]="['/containers', container?.id, 'relate', 'movie']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Media" [routerLink]="['/containers', container?.id, 'relate', 'media']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Series" [routerLink]="['/containers', container?.id, 'relate', 'series']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Channel" [routerLink]="['/containers', container?.id, 'relate', 'channel']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Collection" [routerLink]="['/containers', container?.id, 'relate', 'collection']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Dynamic" [routerLink]="['/containers', container?.id, 'relate', 'dynamic']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>
                        <button pButton type="button" label="Report" [routerLink]="['/containers', container?.id, 'relate', 'report']" icon="pi pi-plus" style="float:right;margin-left:4px;"></button>

                    </div>
                    <spinner *ngIf="isReordering" class="ui-g-12"></spinner>
                </div>
            </p-header>

            <div>
                <container-paginated-datarow type="all" itemsPerPage = 20 [parentContainer]="container"
                                             class="nested-panel"
                                             ordering="to_container__order,-created_date" filterBy="data.title, data.keywords"
                                             [sortOptions]="collectionSortOptions" (reorder)="onReorder($event)">
                </container-paginated-datarow>
            </div>
        </p-panel>


        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </resource-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
