<p-dialog header="Confirm Status Change" 
        [(visible)]="displayDialog" 
        [modal]="true" 
        [minY]="70" 
        [baseZIndex]="10000"
        styleClass="dialog-max-40em"
        [style]="{'min-width': '200px'}"
        [appendTo]="'body'">
    <span *ngIf="processingRequest == false" style="white-space: normal;">Are you sure you want to put this object into <b>{{this.capitalize(this.newContainerStatus)}}</b> status ?</span>

    <div *ngIf="this.newContainerStatus == 'scheduled' && processingRequest != true" >
            <h5>Select schedule date and time:</h5>
            <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="true" [showIcon]="true" dateFormat="@"></p-calendar>
    </div>
    <p-progressSpinner *ngIf="processingRequest == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        <p-footer *ngIf="processingRequest == false">
            <button type="button"  pButton
                    autofocus
                    *ngIf="publishDate || this.newContainerStatus != 'scheduled'"  
                    icon="pi pi-check" 
                    (click)="postStatusChangeToService()" 
                    label="Yes">
            </button>
            <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="No" class="p-button-secondary"></button>
        </p-footer>
</p-dialog>

<p-splitButton label="{{this.capitalize(this.container.status)}}" icon="pi pi-file"  [model]="getStatusItems()" appendTo="body" [disabled]="!this.container.guid || this.container.status === 'waiting' || this.container.status === 'processing'"></p-splitButton>