const cognitoDefaults = {
    'IDP_NAME': 'ADFS',
    'CLIENT_ID': '5dv699m11omjuann99s0f61poo',
    'USER_POOL_ID': 'us-east-1_SdlZNqQEc',
    'CLIENT_DOMAIN_NAME': 'smdg-prd.auth.us-east-1.amazoncognito.com',
    'JWT_HEADER_PREFIX': 'CognitoJWT',
};

const defaults = {
    production: false,
    AWS_COGNITO: cognitoDefaults,
    PROXY_API_BASE_URL: 'http://localhost:8005'
};

export const environment = (function(win: any) {
  const overrides = win.CONFIG || {};
  return {...defaults, ...overrides};
})(window);
