import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { EpisodeRowComponent } from '../episode-row/episode-row.component';
import { ContainerService } from '../../services/container/container.service';
import { JobsService } from '../../services/jobs/jobs.service';
import { Container } from '../../models/container';
import { Tag } from '../../models/tag';
import { Status } from '../../models/status';
import { ResourceListComponent } from '../resource-list/resource-list.component';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../container-create/maptoiterable.pipe';
import { Message } from 'primeng/api';
import { SeriesFormComponent } from '../../forms/series-form/series-form.component';

@Component({
    selector: 'series-edit',
    providers: [
        ContainerService,
        JobsService
    ],
    templateUrl: 'series-edit.component.html',
    styleUrls: ['series-edit.component.css']
})
export class SeriesEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: SeriesFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    public isLoadingEntityTypes = false;
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    public jobInProgress: boolean = false;
    private jobStatus: Status;
    private job_status_msgs: Message[] = [];
    private job_status_sub: any;
    private job_prev_status = 'default';
    private resourceSortOptions: Array<any>;
    private episodeSortOptions: Array<any>;
    private seasonSortOptions: Array<any>;
    private mediaSortOptions: Array<any>;
    private collectionSortOptions: Array<any>;
    public isProcessing = false;
    private pipelineId = ''
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private jobsService: JobsService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.resourceSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.episodeSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Title A-to-Z', value: 'data.title'},
            {label: 'Title Z-to-A', value: '!data.title'},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.seasonSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Title A-to-Z', value: 'data.title'},
            {label: 'Title Z-to-A', value: '!data.title'},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.mediaSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Title A-to-Z', value: 'data.title'},
            {label: 'Title Z-to-A', value: '!data.title'},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.collectionSortOptions = [
            {label: 'Stored Order', value: ''},
        ];

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id, 'full', -1, 'none')
                .subscribe(
                    res => {
                        this.container = res;
                        this.checkProcessingStatus();
                        this.validateFormData();
                        // this.checkJobStatus();
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
        console.log(this.sub);
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }


    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    onPipelineTriggered(event) {
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id, 'full', -1, 'none')
            .subscribe(
                res => {
                    this.container = res;
                    this.checkProcessingStatus();
                    this.validateFormData();
                    // this.checkJobStatus();
                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    ngOnDestroy() {
        if (this.job_status_sub) {
            this.job_status_sub.unsubscribe();
        }
        this.sub.unsubscribe();
    }

    // isJobInProgress() {
    //     return this.jobInProgress;
    // }

    checkJobStatus() {
        // TODO: pipeline-based job check
        console.log('Job status check disabled');


        // console.log('Starting Import for', this.container.guid);
        // this.job_status_sub = this.jobsService
        //     .pollStatus(this.container.guid)
        //     .subscribe(
        //         res => {
        //             this.job_status_msgs = [];

        //             this.jobStatus = res;
        //             console.log(this.jobStatus['status']);
        //             if (this.jobStatus['status'] === 'none') {
        //                 this.jobInProgress = false;
        //             } else if (this.jobStatus['status'] === 'finished') {
        //                 if (this.job_prev_status === 'in-progress') {
        //                     this.job_status_msgs.push({ severity: 'success', summary: 'Content Import Complete', detail: '' });
        //                     this.refetchContainer();
        //                 }
        //                 this.job_prev_status = 'finished';
        //                 this.jobInProgress = false;
        //             } else if (this.jobStatus['status'] === 'failed') {
        //                 this.job_status_msgs.push({ severity: 'error', summary: 'Content Import Failed', detail: '' });
        //                 this.jobInProgress = false;
        //             } else {
        //                 this.job_prev_status = 'in-progress';
        //                 this.job_status_msgs.push({ severity: 'info', summary: 'Content Import in Progress', detail: '' });
        //                 this.jobInProgress = true;
        //             }

        //             if (this.jobInProgress === false) {
        //                 this.job_status_sub.unsubscribe();
        //             }
        //         },
        //         err => {
        //             // this.error = err;
        //             this.requiredMsgs.push({ severity: 'error', summary: 'Error', detail: "Jobs Service API offline" });
        //             this.jobInProgress = false;
        //             this.job_prev_status = 'failed';
        //             this.job_status_sub.unsubscribe();
        //         },
        //         () => {}
        //     );

    }

    onReorder(e) {
        console.log('reorder', e);

        // this.isLoading = true;
        this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => console.log(res),
                err => {
                    console.log(err);
                    this.error = err.statusText;
                }
                //() => this.refetchContainer() // this.isLoading = false
            );

    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }

    isDateValid(dateString: string) {
        var date_regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
        if (date_regex.test(dateString)) {
            return true;
        }
        return false;
    }

    validateFormData() {
        this.msgs = [];

        if (('original_premiere_date' in this.container.data) && (this.isDateValid(this.container.data['original_premiere_date']) === false)) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Original premiere date format not valid. Please use YYYY-MM-DD.' });
        }

        if (('local_premiere_date' in this.container.data) && (this.isDateValid(this.container.data['local_premiere_date']) === false)) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Local premiere date date format not valid. Please use YYYY-MM-DD.' });
        }

        if (('release_date' in this.container.data) && (this.isDateValid(this.container.data['release_date']) === false)) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Release date format not valid. Please use YYYY-MM-DD.' });
        }

        if (!('houseId' in this.container.data) || (!this.container.data['houseId']) || !/\S/.test(this.container.data['houseId'])) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Series Id is required to import content.' });
        }
        if (!('keywords' in this.container.data) || (!this.container.data['keywords']) || !/\S/.test(this.container.data['keywords'])) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Keywords are required to import content.' });
        }

        if (!('airing_network' in this.container.data) || (!this.container.data['airing_network']) || !/\S/.test(this.container.data['airing_network'])) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'Please enter the Airing Network for Siri.' });
        }
    }

    // onImportEpisodes() {
    //     console.log('Starting Import for', this.container.guid);
    //     this.jobsService
    //         .startExternalIngest(this.container.guid)
    //         .subscribe(
    //             res => {
    //                 this.checkJobStatus();
    //             },
    //             err => this.error = err,
    //             () => this.isLoading = false
    //         );

    // }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this series?')) {
                this.refetchContainer();
            }
        }
    }
}
