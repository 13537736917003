<spinner *ngIf="isLoading"></spinner>
<div *ngIf="!isLoading" style="height:4px;"></div>
<div class="p-widget-header" style="padding:4px 10px;border-bottom: 0 none">
    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
    <input style="text-align:center;height:100%;width:40%;" type="text" [(ngModel)]="searchTerm" (ngModelChange)="changedSearchTerm($event)" pInputText placeholder="Search">
    <button type="button" class="filterButton" icon="fa fa-filter" (click)="handleToggleFilters($event)">
        <i *ngIf="filteredStatus" class="fa fa-filter" style="color:#469a20;"></i>
        <i *ngIf="!filteredStatus" class="fa fa-filter"></i>
    </button>
    <button style="float:right;" type="button" class="filterButton" icon="fa fa-refresh" (click)="handleRefresh($event)"><i class="fa fa-refresh"></i></button>
</div>
<p-table [columns]="columns" 
         [customSort]="true" 
         [value]="containers" 
         [rows]="rows" 
         [paginator]="true" 
         [first]="offset" 
         paginatorPosition="bottom" 
         dataKey="guid" 
         [lazy]="true" 
         (onLazyLoad)="loadResultsLazy($event)" 
         [totalRecords]="searchResult.count" 
         selectionMode="single" 
         [(selection)]="selectedContainer"
         styleClass="p-datatable-gridlines"
         (onRowSelect)="onRowSelected($event)" 
         (onRowUnselect)="onRowUnselected($event)">

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ngStyle]="col.style" [pSortableColumn]="col.field" [ngSwitch]="col.sort">
                {{col.header}}
                <p-sortIcon *ngSwitchCase="true" [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
            </th>
        </tr>

        <tr *ngIf="filterToggle">
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                    <button *ngSwitchCase="'info'" type="button" class="filterButton" icon="fa fa-filter" (click)="handleToggleFilters($event)"><i class="fa fa-filter"></i></button>

                    <p-dropdown *ngSwitchCase="'type'" [options]="entityTypes" (onChange)="onChangedFilteredType($event)" placeholder="No Type Filter" optionLabel="name" [showClear]="true" [ngModel]="selectedType" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                    <p-dropdown *ngSwitchCase="'status'" [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                 </th>
        </tr>

    </ng-template>
    <ng-template pTemplate="body" let-container let-expanded="expanded" let-columns="columns">
        <tr *ngIf="!isLoading" [pSelectableRow]="container" style="height:50px">
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <a *ngSwitchCase="'info'" [pRowToggler]="container">
                            <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                </a>
                <div *ngSwitchCase="'thumbnail'">
                <img *ngIf="container.thumbnail" style="max-height:6em;width:auto;max-width:100%" src="{{container.thumbnail}}">
                <img *ngIf="!container.thumbnail"  style="max-height:6em;width:auto;max-width:100%" src="/assets/layout/images/player-poster.png"/>
                </div>
                <div *ngSwitchCase="'type'" style="word-wrap:break-word;text-align:center;" [innerHTML]="container['table_data'][col.field]"></div>
                <div *ngSwitchCase="'status'" style="word-wrap:break-word;text-align:center;" [innerHTML]="container['table_data'][col.field]"></div>
                <!--
                <div style="word-wrap:break-word;" *ngSwitchDefault [innerHTML]="getFieldData(container, col)"></div>
                -->
                <div style="word-wrap:break-word;" *ngSwitchDefault [innerHTML]="container['table_data'][col.field]"></div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-container let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 3">
                <div class="ui-g p-fluid" style="font-size:14px;padding:0.2em">
                    <div class="ui-g-2" style="text-align:center">
                        <img style="max-height:8em;width:auto;max-width:100%" src="{{container.thumbnail}}">
                    </div>
                    <div class="ui-g-4">
                        <div>Description</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container.description}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-4">
                        <div>Keywords</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container.keywords}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-2">
                        <div>
                            <strong>Created:</strong><br>
                            {{container.created_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                        <br>
                        <!-- <hr/> -->
                        <div>
                            <strong>Updated:</strong><br>
                            {{container.updated_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
