import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityType, Resource } from '../../content/models';
import { Observable, throwError} from 'rxjs';
import { AppContext } from '../../app.context';
import { AuthService } from '../../auth/auth.service';
import { ContainerService } from '../../content/services/container/container.service';
import { PipelineService } from '../../pipeline/pipeline.service';

@Injectable()
export class CommerceService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}`;

    constructor(
        private http: HttpClient,
        private appContext: AppContext,
        private containerService: ContainerService,
        private authService: AuthService,
        private pipelineService: PipelineService,
    ) {}
    

    getKeywordSuggestion(keyword): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/api/segment/v1/?type=keyword&label__prefix=${keyword}&unique_by=label.raw&status=published&status=hidden`,
                { headers: headers }
            );
    }

    getUniqueByParentForKeywordValues(keywords: Array<string>, origins: Array<string>, limit: Number, offset: Number, rangeDates: Array<Date> = null): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let query = 'type=keyword&status=published&status=hidden&unique_by=parent.raw';

        if (origins && origins.length > 0) {
            for (let i = 0; i < origins.length; i++) {
                query += '&origin=' + origins[i];
            }
        }

        if (keywords && keywords.length > 0) {
            for (let i = 0; i < keywords.length; i++) {
                query += '&value=' + keywords[i];
            }
        }

        if (rangeDates && rangeDates.length > 0) {
            if (rangeDates.length == 1) {
                let startDate = rangeDates[0].toISOString();
                query += '&created_date__gte='+ startDate;
            }
            if (rangeDates.length == 2) {
                let startDate = rangeDates[0].toISOString();
                let endDate = rangeDates[1].toISOString();
                query += '&created_date__gte='+ startDate + '&created_date__lte=' + endDate;
            }
        }

        query += '&limit=' + limit + '&offset=' + offset;
        query += '&ordering=-created_date';

        return this.http
            .get(
                `${this.baseUrl}/api/segment/v1/?${query}`, { headers: headers }
            );
    }

    getContainersByGuid(guids: Array<string>, status:Array<string>= [], ordering: Array<string> = ['-reference_date','-id']): Observable < any > {
        if (!guids || guids.length == 0) {
            return throwError("No GUIDs to load");
        }
        
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let query = guids.map(g => `guid=${g}`).join('&');
        
        for (let ord of ordering) {
            query += `&ordering=${ord}`;
        }

        if (status.length < 1) {
            status = ContainerService.statusList;
        }
        for (let st of status) {
            query += `&status=${st}`;
        }

        return this.http
            .get(
                `${this.baseUrl}/api/container/v1/?${query}`, { headers: headers }
            );
    }
    getFacetsForSegmentTermByParent(parentGuid, term, facetType, segmentType='keyword'): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        let query = 'parent='+ parentGuid + '&facet=' + facetType + '&value=' + term;       
        return this.http
            .get(
                `${this.baseUrl}/api/segment/v1/?type=${segmentType}&status=published&status=hidden&limit=1&${query}`,
                { headers: headers }
            );
    }

    getSegmentForTermByParent(parentGuid, term, segmentType='keyword'): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        let query = 'parent='+ parentGuid + '&value=' + term;

        return this.http
            .get(
                `${this.baseUrl}/api/segment/v1/?type=${segmentType}&status=published&status=hidden&limit=9999&${query}`,
                { headers: headers }
            );
    }

    //http://localhost:8000/api/segment/v1/?limit=10&parent=1027575875944&facet=term_histogram&value=test
    
    
    getSomeSalesDataMaybe(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let origin = this.appContext.activeOrigin;
        
        return this.http
            .get(
                `${this.baseUrl}/`,
                { headers: headers }
            );
    }

    filterByPrimaryAndSecondaryParent(primaryParentGuid, secondaryParentGuid, status: string | Array<string> = []): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let query = 'primary_parent=' + primaryParentGuid + '&secondary_parent=' + secondaryParentGuid;

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];
            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        return this.http
            .get(
                `${this.baseUrl}/api/container/v1/?${query}`, { headers: headers }
            );
    }

    getFacetCountsForSegmentTermByParent(parentGuids: Array<string>, keywords: Array<string>)  {
        let headers = new HttpHeaders()
        .append('Accept', 'application/json')
        .append('Authorization', this.authService.getAuthHeader());

        let query = parentGuids.map(p => `parent=${p}`).join('&');

        for (let keyword of keywords) {
            query += `&value=${keyword}`;
        }

        return this.http
        .get(
            `${this.baseUrl}/api/segment/v1/?facet=parent_term_histogram&limit=1&offset=0&type=keyword&status=published&status=hidden&${query}`, { headers: headers }
        );
    }

}