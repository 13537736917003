import { Component, Inject, forwardRef } from '@angular/core';
import { AppComponent } from './app.component';
import { ContainerService } from './content/services/container/container.service';
import {DialogModule} from 'primeng/dialog';
import {FileUploadModule} from 'primeng/fileupload';

@Component({
    selector: 'app-sidebar',
    providers: [
        ContainerService
    ],
    templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent {

    contentMenu: any[];
    createMenu: any[];
    createSettingsMenu: any[];
    uploadMenu: any[];
    metricsMenu: any[];
    metricsReportsMenu: any[];
    configMenu: any[];
    newItemMenu: any[];
    workflowMenu: any[];
    salesMenu:any[];
    adsMenu:any[];
    createCommerceMenu:any[];
    displayDetailedUploadWindow: boolean = false;
    displayQuickUploadWindow: boolean = false;

    private distributions = [];
    private error: string;
    private isLoading = false;

    constructor(public app: AppComponent, private containerService: ContainerService) {}

    showQuickUploadDialog() {
        this.displayQuickUploadWindow = true;
    }

    closeQuickUploadDialog() {
        this.displayQuickUploadWindow = false;
    }

    showDetailedUploadDialog() {
        this.displayDetailedUploadWindow = true;
    }

    closeDetailedUploadDialog() {
        this.displayDetailedUploadWindow = false;
    }

    ngOnInit() {
        this.contentMenu = [
            { label: 'Media', icon: 'fa fa-fw fa-play', routerLink: ['/workflow/worklist'] },
            { label: 'Series', icon: 'fa fa-fw fa-th', routerLink: ['/series'] },
            { label: 'Movies', icon: 'fa fa-fw fa-film', routerLink: ['/movies'] },
            { label: 'Channels', icon: 'fa fa-fw fa-tv', routerLink: ['/channels'] },
            { label: 'Search', icon: 'fa fa-fw fa-search', routerLink: ['/containers'] }
        ];
        this.newItemMenu = [
            { label: 'Featured', icon: 'fa fa-fw fa-certificate', routerLink: ['/featured'] },
        ];
        this.metricsMenu = [
            // { label: 'Metrics Dashboard', icon: 'fa fa-fw fa-signal', routerLink: ['/metrics/dashboard'] },
            // { label: 'Reports', icon: 'fa fa-fw fa-line-chart', routerLink: ['/metrics/reports'] },
            { label: 'Content Activity', icon: 'fa fa-fw fa-thermometer-half', routerLink: ['/dashboard'] },
            { label: 'System Status', icon: 'fa fa-fw fa-check-square', routerLink: ['/status'] }
        ];
        this.metricsReportsMenu = [
            { label: 'Metrics Dashboard', icon: 'fa fa-fw fa-signal', routerLink: ['/metrics/dashboard'] },
            { label: 'Metrics Reports', icon: 'fa fa-fw fa-line-chart', routerLink: ['/metrics/reports'] },
        ];
        this.configMenu = [
            { label: 'Applications', icon: 'fa fa-fw fa-rocket', routerLink: ['/applications'] },
            { label: 'Views', icon: 'fa fa-fw fa-clone', routerLink: ['/views'] },
            { label: 'Platforms', icon: 'fa fa-fw fa-desktop', routerLink: ['/settings/platforms'] },
            { label: 'Policies', icon: 'fa fa-fw fa-lock', routerLink: ['/settings/policies'] },
            { label: 'Integrations', icon: 'fa fa-fw fa-puzzle-piece', routerLink: ['/settings/integrations'] },
            { label: 'Pipelines', icon: 'fa fa-fw fa-paperclip', routerLink: ['/pipelines'] },
            { label: 'Roles and Permissions', icon: 'fa fa-fw fa-users', routerLink: ['/roles'] },
            { label: 'Origins', icon: 'fa fa-fw fa-users', routerLink: ['/origins'] },
            { label: 'Providers', icon: 'fa fa-fw fa-users', routerLink: ['/providers'] },
            { label: 'Reports', icon: 'fa fa-fw fa-book', routerLink: ['/reports'] },
        ];
        this.createMenu = [
            { label: 'Collection', icon: 'fa fa-fw fa-plus', routerLink: ['/collections', 'create'] },
            { label: 'Series', icon: 'fa fa-fw fa-plus', routerLink: ['/series', 'create']},
            { label: 'Media', icon: 'fa fa-fw fa-plus', routerLink: ['/mediaobject', 'create']},
            { label: 'Document', icon: 'fa fa-fw fa-plus', routerLink: ['/document', 'create']},
        ];

        this.adsMenu = [
            { label: 'Campaigns', icon: 'fa fa-fw fa-globe', routerLink: ['/commerce/campaign'] },
            { label: 'Content Groups', icon: 'fa fa-fw fa-briefcase', routerLink: ['/commerce/contentgroup']},
            //{ label: 'Trafficking', icon: 'fa fa-fw fa-bus', routerLink: ['/commerce/ads/trafficking']},
        ];

        this.createCommerceMenu = [
            { label: 'Campaign', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/campaign/create']},
            { label: 'Content Group', icon: 'fa fa-fw fa-plus', routerLink: ['/commerce/contentgroup/create']},
        ];


        this.createSettingsMenu = [
            { label: 'Container', icon: 'fa fa-fw fa-plus', routerLink: ['/containers', 'create'] },

        ];

        this.uploadMenu = [
            { label: 'Quick', icon: 'fa fa-fw fa-upload', command: () => this.showQuickUploadDialog() },
            { label: 'Detailed', icon: 'fa fa-fw fa-upload', command: () => this.showDetailedUploadDialog() },

        ];

        this.isLoading = true;
        this.containerService
            .filterByTypeName('distribution', 'full', 1)
            .subscribe(
                res => {
                    console.log('distr:', res);
                    this.distributions = res;
                    this.distributions.forEach((element, index) => {
                        var distItem = {}
                        var viewItems = []
                        var views = element.containers.filter(subelement =>
                            subelement.type.name === 'view'
                        );
                        for (let view of views){
                            viewItems.push({label: view.data.name, icon: 'fa fa-fw fa-square', routerLink:['/view/'+view.id] })
                        }

                        this.newItemMenu.push({label: element.data.name, icon: 'fa fa-fw fa-th-large', items:viewItems})
                        console.log('view:', views);
                    });
                },
                err => {
                    console.log('err', err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );

    }

}
