import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContentGroupService } from '../../services/content-group.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'


@Component({
    selector: 'moment-table',
    templateUrl: 'moment-table.component.html',
    styleUrls: ['moment-table.component.css']
})

export class CAMomentTableComponent implements OnInit {
    @Input() inputContainer: Container;
    @Input() rows: number;
    @Input() showRemoveControls = false;
    @ViewChild(Table) itemTable;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @Output() momentRemove: EventEmitter < any > = new EventEmitter();
    public container: Container;
    private cgState: any;
    private destroy$ = new Subject();

    public isLoading = true;
    public totalRecords;
    public momentContainers = [];
    private limit: number;
    private offset: number;
    private count: number;
    private page: number;
    private lastSearch = null;
    private lastSearchTerms = [];
    private result;
    private error;

    constructor(
        private cgService: ContentGroupService,
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        this.destroy$.next(false);
        this.createCgSub();
        this.totalRecords = this.inputContainer.containers.length
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                //console.log('set active moment', cgState)
                this.cgState = cgState;
                if (this.cgState['activeMoment']) {
                    this.container = this.cgState['activeMoment'];
                    //console.log('set active moment container', cgState['activeMoment'])
                }
            });
    }

    loadMomentData(terms) {
        this.lastSearchTerms = terms;
        if (this.lastSearch) {
            this.lastSearch.unsubscribe();
            this.lastSearch = null;
        }
        this.isLoading = true;
        this.lastSearch = this.containerService
            .getRelatedByTypeAndGuidPaginated('moment', this.inputContainer.guid, this.page, this.count)
            .subscribe(
                res => {
                    this.momentContainers = res;
                },
                err => {
                    this.error = err;
                    console.log('error', this.error)
                },
                () => {
                    this.isLoading = false;
                }
            )
    }

    loadMomentsLazy(event) {
        this.limit = event.rows;
        this.offset = event.first
        if (this.offset == 0) {
            this.page = 1;
        } else {
            this.page = (this.offset / this.limit) + 1
        }
        this.count = this.limit;
        this.loadMomentData(this.lastSearchTerms);
    }

    onRowSelect() {
        //console.log("Selected Item MT");
        //console.log(this.container);
        this.cgService.updateCgState({
            'activeMoment': this.container,
            'source': 'moment-table'
        });
        this.rowSelect.emit({
            container: this.container
        });
    }

    unselectTableItems() {
        this.itemTable.selection = null;
    }

    onRemove(container) {
        this.momentRemove.emit({
            'container': container
        });
    }
}
