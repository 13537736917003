import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'policy-table',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'policy-table.component.html',
    styleUrls: ['policy-table.component.css']
})
export class PolicyTableComponent implements OnInit {
    @Input() containers: Array<Container>;
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() isLoading = false;
    @Input() modifyPermissions: Array <any> = [];
    private displayDialog = false;
    public container: Container;
    private entityTypes = [];
    private entityTypeId;
    public collapsed = false;

    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {}

    onRowSelect(event) {
        this.displayDialog = true;
    }

    save() {
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    // this.displayDialog = false;
                },
                err => {
                    console.log(err);
                    this.displayDialog = false;
                },
                () => this.isLoading = false
            );
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this policy?')) {
            console.log('remove', e, container);
            let i = this.containers.indexOf(container);
            this.containers.splice(i, 1);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                    },
                    err => console.log(err)
                );
        }
    }
}
