import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'campaign-form',
    providers: [
        // EntityTypeService
    ],
    templateUrl: 'campaign-form.component.html'
})
export class CACampaignFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityTypes = [];
    private entityType;
    private entityTypeId;
    private selectedSeasonGuid;
    public isLoading = false;

    private publishDate: Date;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    getDateFromTimeNumber(time) {
        if (!time) return null;
        let date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    ngOnInit() {
        // if (this.container.data && !('label' in this.container.data) && this.container.data['title']){
        //     this.container.data['label'] = this.container.data['title'];
        // }
        this.entityTypeId = this.container.type.id;
        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'campaign'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('label' in this.container.data) || !(this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the campaign label.' });
            valid = false;
        }

        if (valid) {
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onStatusComplete() {
        this.formSubmit.emit({
            container: this.container
        });
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onStatusChange(event) {}
}
