import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityType, Container } from '../../../content/models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { ResourceUploadService } from '../../../upload/resource-upload.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription,  Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContainerTagsTabComponent } from '../../../content/components/container-tags-tab/container-tags-tab.component';
import { CuePointEditComponent } from '../../../content/components/cue-point-edit/cue-point-edit.component';
import { UploadService } from '../../../upload/upload.service';


@Component({
    selector: 'worklist-episode-form',
    providers: [
        EntityTypeService,
        ContainerService,
        UploadService
    ],
    templateUrl: 'worklist-episode-form.component.html',
    styleUrls: ['worklist-episode-form.component.css']
})
export class WorklistEpisodeFormComponent implements OnInit {
    @Input() containerId: Number;
    @Input() seasons: Container[];
    @Output() onContainerUpdate: EventEmitter < any > = new EventEmitter();
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();
    @ViewChild('maintags') mainTags: ContainerTagsTabComponent;
    @ViewChild('syndicationtags') syndicationTags: ContainerTagsTabComponent;
    @ViewChild('cuepoints') cuePoints: CuePointEditComponent;

    public container: Container;
    private templateContainer: Container;

    public isProcessing = false;
    
    private entityTypes = [];
    private entityTypeId;
    private selectedSeasonGuid;
    private policies: Array < any > = [];
    private platforms: Array < any > = [];
    public isLoading = false;
    public isLoadingTemplate = false;
    publishDate: Date;

    private fileUploadStatusSubscription: Subscription;
    private isUploading = false;
    
    private tagTypeFilterList = [];
    private tagTypeHiddenList = [];

    private pipelineBundleId: string;

    private pipelineId: string;

    private keywordChips: string[];

    public containerStatus: string;

    private ratings = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
    private genre = genreList;

    public msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        public containerService: ContainerService,
        private resourceUploadService: ResourceUploadService,
        private pipelineService: PipelineService,
        private uploadService: UploadService,
        private authService : AuthService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['containerId'].previousValue != changes['containerId'].currentValue && this.containerId) {
            console.log('unsub');
            this.msgs = [];
            this.pipelineBundleId = "";
            this.pipelineId="";
            this.isProcessing = false;
            console.log(this.fileUploadStatusSubscription);
            this.isUploading = false;
            if (this.fileUploadStatusSubscription){
                this.fileUploadStatusSubscription.unsubscribe();
            }
            console.log(this.fileUploadStatusSubscription);
            //this.resourceUploadService.getUploadProgressForContainer(changes['containerId'].previousValue).
            this.fileUploadStatusSubscription = this.getFileUploadSubscription(this.containerId);

            this.refetchContainer();
        }
    }

    refetchContainer() {
        this.containerService
        .get(this.containerId)
        .subscribe(
            res => {
                console.log('res', res);
                this.container = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                //this.container = res;
                this.containerStatus = this.container.status;
                this.policies = this.container.containers.filter(t => t.type.name === 'policy');
                this.platforms = this.container.containers.filter(t => t.type.name === 'platform');
                this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
                if (this.container['data'] && 'template_profile_guid' in this.container['data']) {
                    this.getTemplateByGUID(this.container['data']['template_profile_guid']);
                } else {
                    this.clearTemplate();
                }

                //if (this.container['data'] && 'keywords' in this.container['data']) {
                    //this.keywordChips = this.container['data']['keywords'].split(',').map(k => k.trim());
                //}

                this.checkProcessingStatus();

                this.onContainerUpdate.emit({
                    container: this.container
                }); 
            }
        );
    }

    refetchResources(): Observable<any> {
        return this.containerService
                .get(this.containerId).pipe(
                tap(
                    res => {
                        let container = res instanceof Container
                            ? res
                            : Container.fromJson(res);
                        if (this.container && container.id == this.container.id) {
                            // Replace resource list
                            this.container.resources = container.resources;
                        }
                    }
                ));
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
    }
    
    ngOnInit() {
        this.fileUploadStatusSubscription = this.getFileUploadSubscription(this.containerId);
        console.log(this.fileUploadStatusSubscription);
        this.entityTypeId = 9; // this.container.type.id;

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    console.log(res);
                },
                err => console.log(err),
                () => {
                    this.isLoading = false;
                }
            );
    }

    ngOnDestroy() {
        if (this.fileUploadStatusSubscription){
            this.fileUploadStatusSubscription.unsubscribe();
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    getContainerTagTypes() {
        return this.container.tags ? this.container.tags.map(tag => tag['type']) : [];
    }

    getTemplateByGUID(guid) {
        this.containerService
        .getByGuid(guid)
        .subscribe(
            res => {
                this.isLoadingTemplate = true;
                console.log('res', res);
                this.templateContainer = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                console.log("GET TEMPLATE");

                if (this.templateContainer['data']['payload']['default_pipeline_bundle_id']) {
                    this.pipelineBundleId = this.templateContainer['data']['payload']['default_pipeline_bundle_id']
                } else {
                    this.pipelineBundleId = null;
                }
                
                if (this.templateContainer['data']['payload']['tag_type_filter']) {
                    this.tagTypeFilterList = this.templateContainer['data']['payload']['tag_type_filter'];
                } else {
                    this.tagTypeFilterList = this.getContainerTagTypes();
                }
                this.isLoadingTemplate = false;
            },
            err => console.log(err),
            () => {
                this.isLoadingTemplate = false;
            }
        );
    }

    clearTemplate() {
        this.templateContainer = null;
        this.pipelineBundleId = null;
        this.tagTypeFilterList = this.getContainerTagTypes();
    }

    getFileUploadSubscription(id) {
        return this.resourceUploadService
                .getUploadProgressForContainer(id)
                .subscribe(data => {
                    if ((!data)) {
                        this.isUploading = false
                        return
                    }

                    if ((data['status'] === 'uploading') && (data['container']['id'] === this.containerId)) {
                        this.isUploading = true;
                    } else if ((data['status'] === 'complete') && (data['container']['id'] === this.containerId)) {
                        if (this.isUploading) {
                            this.refetchResources()
                                .subscribe(
                                    res => {
                                        console.log('Updated resources', res.resources);
                                    },
                                    err => {
                                        console.error('Error refetching resources:', err);
                                    },
                                    () => {
                                        this.isUploading = false;
                                    }
                                );
                        }
                    } else if ((data['status'] === 'error') && (data['container']['id'] === this.containerId)) {
                        this.isUploading = false;
                        this.msgs = [];
                        this.msgs.push({ severity: 'error', summary: 'Error', detail: 'There was a problem uploading the files.' });
                    }
                });
    }

    onSeasonChange() {
        console.log('selected season', this.selectedSeasonGuid);
        this.container.data['secondary_parent_container_id'] = this.selectedSeasonGuid;
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        var season = this.seasons.find(t => t.guid === this.selectedSeasonGuid);
        
        if (this.publishDate) {
            this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        }
        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        //if (this.keywordChips.length > 0) {
        //    this.container['data']['keywords'] = this.keywordChips.join(', ');
        //}

        this.msgs = [];

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the episode title.' });
        }

        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                this.msgs = [];
                this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                this.container = Object.assign(new Container(), this.container);
                this.onContainerUpdate.emit({
                    container: this.container
                }); 
            },
            err => {
                console.log(err);
            },
            () => this.isLoading = false
        );

       this.formSubmit.emit({
            container: this.container
        });

    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date); 
        this.onContainerUpdate.emit({
            container: this.container
        }); 
    }

    onStatusComplete(res : Object = null) {
        console.log(res);
        if (res && res['data'] && res['data']['id']) {
            this.pipelineId = res['data']['id'];
        }

        this.formSubmit.emit({
            container: this.container,
            pipeline: res
        });
    }

    startPublishingPipeline() {
        if (this.pipelineBundleId) {
            this.isProcessing = true;
            console.log('Starting Publishing:', this.pipelineBundleId);

            let username = this.authService.getAuthenticatedUserName();
            let external_id = `vms:${username}:${this.container.guid}`;

            this.pipelineService
                .startPipeline(
                    this.pipelineBundleId,
                    { container_id: this.container.id },
                    external_id
                )
                .subscribe(
                    res => {
                        this.refetchContainer();
                        this.onStatusComplete(res);
                    },
                    err => {                     
                        console.error(err);
                        this.msgs = [];
                        this.msgs.push({ severity: 'error', summary: 'Error Triggering Publishing Pipeline.', detail: '' });  
                        this.isProcessing = false;
                        this.updateContainerStatus(this.containerStatus)
                    },
                    () => {}
                );
        } else {
            this.onStatusComplete();
        }
    }

    startPublishingProcessing() {
        this.isLoading = true;
        let start_publishing_pipeline = true;

        if (this.templateContainer) {
            let containerJSON = this.templateContainer.data['payload'];
            if (typeof containerJSON == 'string') {
                containerJSON = JSON.parse(containerJSON);
            }

            let uploadedFiles = this.container.resources
                                    .filter(r => r.status == 'new' && r.type.name == 'file')
                                    .map(r => r.data['source_uri']);
            console.log('Files uploaded:', uploadedFiles);
            let requiredFiles = containerJSON.required_files || [];

            let uploadCheck = this.resourceUploadService.checkRequiredFiles(uploadedFiles, requiredFiles);
            console.log('Upload check:', uploadCheck);

            // Fail only if missing any required
            if (uploadCheck['missing']['required'].length > 0) {
                this.msgs = [];
                this.msgs.push({ severity: 'error', summary: 'Error', detail: 'One or more required files missing' });
                for (let spec of uploadCheck['missing']['required']) {
                    this.msgs.push({
                        severity: 'info',
                        summary: 'Required',
                        detail: `${spec['label']}: ${spec['formats'].join(', ')}`,
                    });
                }
                start_publishing_pipeline = false;
            }
        }

        if (start_publishing_pipeline) {
            this.container.status = "waiting";
            this.containerService
                .save(this.container)
                .subscribe(
                    res => {
                        console.log(res);
                        this.onContainerUpdate.emit({
                            container: this.container
                        }); 
                        this.startPublishingPipeline();
                    },
                    err => {
                        console.error(err);
                        this.msgs = [];
                        this.msgs.push({ severity: 'error', summary: 'Error on queuing for publishing', detail: '' });
                    },
                    () => this.isLoading = false
                );
        } else {
            this.isLoading = false;
            console.error('Could not start processing pipeline');
        }        
    }

    updateContainerStatus(status: string) {
        this.container.status = status;
        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                this.onContainerUpdate.emit({
                    container: this.container
                }); 
                this.isLoading = false 
            },
            err => {
                console.error(err);
                this.msgs.push({ severity: 'error', summary: 'Error saving container status', detail: '' });
                this.isLoading = false  
            },
            () => this.isLoading = false
        );
    }

    getThumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }
  
    updateMainTags(newTags) {
        console.log(newTags);
        if (newTags && this.mainTags) {
            this.mainTags.updateTags(newTags);
        }
    }
  
    updateSyndicationTags(newTags) {
        console.log(newTags);
        if (newTags && this.syndicationTags) {
            this.syndicationTags.updateTags(newTags);
        }
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }

}
