import { EntityType } from './entity-type';

interface MetadataDatetimes {
    [fieldName: string]: Date
}

export class Container {
    id = -1;
    guid = '';
    status = 'new';
    origin = '';
    type: EntityType = new EntityType();
    data = {};
    tags = [];
    published_date: number = null;
    is_enabled = true;
    resources: any;
    containers: Array < Container > = new Array < Container > ();

    static fromJson(con: Object) {
        const container = new Container();
        // Simple copy fields
        for (let f of ['id', 'guid', 'origin', 'status', 'type', 'data', 'is_enabled']) {
            if (con[f] !== undefined) {
                container[f] = con[f];
            }
        }
        // Array fields
        // TODO: cast as appropriate
        for (let f of ['resources', 'containers', 'tags']) {
            if (con[f] !== undefined) {
                container[f] = con[f];
            }
        }
        // Cast published date
        if (con['published_date'] !== undefined) {
            if (con['published_date'] === null) {
                container.published_date = null;
            } else {
                container.published_date = parseInt(con['published_date']);
            }
        }

        return container;
    }

    // Bunch of stuff for handling certain common datetimes in 'data'

    private metadataDates: MetadataDatetimes = {};

    private getDate(fieldName: string): Date {
        let dateStr = this.data[fieldName];
        let value;
        if (dateStr) {
            // Add timezone to ensure UTC if required
            if (dateStr.length <= 19) {
                dateStr += 'Z';
            }
            // Compare/update stored
            value = new Date(dateStr);
            if (value.valueOf() == 0 || isNaN(value.valueOf())) {
                value = null;
            } else {
                let stored = this.metadataDates[fieldName];
                // Return same object for comparisons if equal
                if (stored && stored.valueOf() === value.valueOf()) {
                    value = stored;
                }
            }
        } else {
            value = null;
        }
        // Update Date object cache
        this.metadataDates[fieldName] = value;

        return value;
    }

    private setDate(fieldName: string, value: Date, forceValue: boolean = false): void {
        // Update container
        let dateStr;
        if (value === null) {
            dateStr = forceValue ? '1970-01-01T00:00:00+00:00' : '';
        } else {
            dateStr = value.toISOString().substr(0, 19) + '+00:00';
        }
        this.data[fieldName] = dateStr;
        // Update cache
        this.metadataDates[fieldName] = value;
    }

    get availableDate () {
        return this.getDate('available_date');
    }
    set availableDate (value) {
        this.setDate('available_date', value, true);
    }

    get expirationDate () {
        return this.getDate('expiration_date');
    }
    set expirationDate (value) {
        this.setDate('expiration_date', value, true);
    }

    get publicWindowEndDate () {
        return this.getDate('public_window_end_date');
    }
    set publicWindowEndDate (value) {
        this.setDate('public_window_end_date', value);
    }

    get originalAirDate () {
        return this.getDate('original_air_date');
    }
    set originalAirDate (value) {
        this.setDate('original_air_date', value);
    }

    get localAirDate () {
        return this.getDate('local_air_date');
    }
    set localAirDate (value) {
        this.setDate('local_air_date', value);
    }

    get releaseDate () {
        return this.getDate('release_date');
    }
    set releaseDate (value) {
        this.setDate('release_date', value);
    }

    // NOTE: slight name mismatch, b/c 'pub_date' is easily
    // confused for 'published_date'
    get referenceDate () {
        return this.getDate('pub_date');
    }
    set referenceDate (value) {
        this.setDate('pub_date', value);
    }

}
