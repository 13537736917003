<div class="card card-w-title top-level-card">
    <h1>Platforms</h1>
    <spinner *ngIf="isLoading"></spinner>

    <p-table #dt [value]="containers" 
            [globalFilterFields]="['data.name', 'data.platform_type', 'data.url_scheme']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="bottom" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines" 
            [responsive]="true">
        <ng-template pTemplate="caption">
            <div class="clearfix">
                <div class="ui-g-12 ui-md-4">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (keyup)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" class="filter-bar"/>
                </div>
                <div class="ui-g-12 ui-md-4">
                    <p-dropdown appendTo="body" [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                </div>
                <div class="ui-g-12 ui-md-4">
                    <button pButton type="button" label="Create" [routerLink]="['/platform', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th style="width: 8em">Platform Type</th>
                <th>Bundle Id</th>
                <th>GUID</th>
                <th style="width: 6em">Status</th>
                <th style="width: 6em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-platform>
            <tr>
                <td>{{platform.data.name}}</td>
                <td><div class="label label-default">{{platform.data.platform_type}}</div></td>
                <td style="word-break: break-all;">{{platform.data.bundle_id}}</td>
                <td style="word-break: break-all;">{{platform.guid}}</td>
                <td><div class="label" [ngClass]="statusLabelClass(platform.status)">{{platform.status|titlecase}}</div></td>
                <td>
                    <button pButton type="button" label="Edit" [routerLink]="['/platform', platform.id]" class="p-button-success" icon="pi pi-pencil" ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>