import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'landing',
    templateUrl: 'landing.component.html',
    styleUrls: ['landing.component.css']
})
export class LandingComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        console.log('landing initialized');
    }
}
