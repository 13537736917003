import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DocumentFormComponent } from '../../forms/document-form/document-form.component';
import { Message } from 'primeng/api';

@Component({
    selector: 'document-edite',
    templateUrl: 'document-edit.component.html',
    styleUrls: ['document-edit.component.css']
})

export class DocumentEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: DocumentFormComponent;
    private sub: any;
    private conatinerSub: any;
    private reorderSub: any;
    public container: Container = new Container();
    public error: HttpResponse<any>;
    public isLoading = false;
    public requiredMsgs: Message[] = [];
    private msgs: Message[] = [];
    public containerId: number;
    public isProcessing = false;
    private pipelineId = '';

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('document edit initialized');

        this.sub = this.route.params.subscribe(params => {
            this.containerId = params['id'];
            console.log('id', this.containerId);

            this.loadContainerData(this.containerId);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.conatinerSub = this.containerService
            .get(id)
            .subscribe(
                res => {
                    this.container = res;
                    this.checkProcessingStatus();
                },
                err => this.error = err,
                () => {
                    this.isLoading = false;
                    this.conatinerSub.unsubscribe();
                }
            );

    }

    onSubmit(e) {
        console.log('submit', e.model);
        console.log(this.container.data);
        this.sub = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                    this.msgs = [];
                    this.msgs.push({ severity: 'error', summary: 'Error', detail: err.statusText });
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }
    
    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
          this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onReorder(e) {
        this.isLoading = true;
        this.reorderSub = this.containerService
            .reorder(e.parent, e.from, e.to)
            .subscribe(
                res => {
                    this.isLoading = false;
                    this.reorderSub.unsubscribe();
                },
                err => console.log(err)
            );
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onPipelineTriggered(event) {
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.loadContainerData(this.containerId);
        }
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this document?')) {
                this.loadContainerData(this.containerId);
            }
        }
    }
}
