import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { Container, EntityType } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { AppContext } from '../../../app.context';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'search-table',
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'search-table.component.html',
    styleUrls: ['search-table.component.css']
})
export class SearchTableComponent implements OnInit {
    @Input() allowTypes: Array<string> = [];
    @Input() defaultSearchTerm: string;
    @Input() config: any;
    @Output() onRowSelect: EventEmitter < any > = new EventEmitter();
    @Output() onRowUnselect: EventEmitter < any > = new EventEmitter();

    public searchResult: any = {};
    public containers: Array<any> = [];

    private limit: number;
    public offset = 0;

    private filterToggle = false;

    public searchTerm = '';
    private defaultTypes = '';
    private filteredType = '';
    public filteredStatus = '';
    private ordering: Array<string> = [];
    public isLoading = true;
    private entityTypes = [];
    private selectedType: EntityType = null;
    private statusList: Array<SelectItem> = [];
    private selectedStatus: SelectItem = null;
    private error: string;

    private viewContextId: number;

    public selectedContainer;

    private defaultConfig = {
        csKey: 'search_view',
        keepSelectionOnReload: false
    }

    private allColumns = [
        { default: false, header: '', field: 'info', sort: false, style: { width: "2.5em" } },
        { default: true, header: '', field: 'thumbnail', sort: false, style: { width: "6.5em" } },
        { default: true, header: 'Title', field: 'title', sort: true },
        { default: false, header: 'Show Title', field: 'data.show_title', sort: true, style: { width: "14em",height: "14em"  }},
        { default: false, header: 'GUID', field: 'guid', sort: false, style: { width: "19em" } },
        { default: false, header: 'Type', field: 'type', sort: true, style: { width: "9em" }, transform: [this.toEntityTypeLabel.bind(this)] },
        { default: false, header: 'Published Date', field: 'published_date', sort: true, style: { width: "14em" }, transform: [this.formatDateTime.bind(this)] },
        { default: true, header: 'Created Date', field: 'created_date', sort: true, style: { width: "14em" }, transform: [this.formatDateTime.bind(this)] },
        { default: true, header: 'Status', field: 'status', sort: true, style: { width: "9em"}, transform: [this.toStatusLabel.bind(this)] },
        { default: false, header: 'Season Number', sort: false, field: 'season_number' }
    ];
    public columns = [];
    private columnFields = this.allColumns.map(column => column['field']);
    public rows = 20;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private appContext: AppContext,
        private datePipe: DatePipe,
    ) {}

    ngOnInit() {
        this.isLoading = true;

        if (!this.config) {
            this.config = this.defaultConfig;
        }

        if (this.config.customColumns) {
            //this.columns = this.config.customColumns.map(i => this.allColumns[i]);
            this.config.customColumns.forEach(column => {
                let columnIndex = this.columnFields.indexOf(column['field']);
                if (columnIndex > -1) {
                    this.columns.push(this.allColumns[columnIndex]);
                } else {
                    this.columns.push(column);
                }
            });
        } else {
            this.columns = this.allColumns.filter(col => col.default);
        }

        if (this.config.rows) {
            this.rows = this.config.rows;
        }
        // this.containers = this.searchResult.results;

        this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));

        if (this.allowTypes) {
            this.defaultTypes = this.allowTypes.join('__');
        }

        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    if (this.allowTypes && this.allowTypes.length > 0) {
                        this.entityTypes = this.entityTypes.filter(t => this.allowTypes.includes(t.name));
                    }
                    this.entityTypes.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        } else if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });
                    if (this.filteredType && !this.selectedType) {
                        this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                        // console.log('Initial type', this.selectedType);
                    }
                    if (this.config.keepSelectionOnReload && 'guid' in this.appContext.componentState[this.config.csKey]) {
                        let activeGuid = this.appContext.componentState[this.config.csKey]['guid'];
                        for (let i = 1; i < this.containers.length; i++) {
                            if (this.containers[i]['guid'] === activeGuid) {
                                this.selectedContainer = this.containers[i];
                                break;
                            }
                        }
                    }
                    this.setSearchParams();
                },
                err => console.log(err),
                () => this.isLoading = false
            );

        // Update on query param change
        if (this.config.csKey in this.appContext.componentState) {
            this.getSearchContextParameters();
        }

        if (this.defaultSearchTerm) {
            this.searchTerm = this.defaultSearchTerm;
        }
        // this.loadResults();
    }


    getSearchContextParameters() {
        let searchContextParameters = this.appContext.componentState[this.config.csKey];

        this.searchTerm = searchContextParameters['search'] || '';

        // Set type
        this.filteredType = (searchContextParameters['type'] || '').toLowerCase();
        if (this.filteredType) {
            if (!this.selectedType || this.selectedType.name !== this.filteredType) {
                this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                console.log('Selected Type', this.selectedType);
            }
        } else if (this.selectedType) {
            this.selectedType = null;
        }

        // Set status
        this.filteredStatus = (searchContextParameters['status'] || '').toLowerCase();
        if (this.filteredStatus) {
            this.filterToggle = true;
            if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
                this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
                // console.log('Selected Status', this.selectedStatus);
            }
        } else if (this.selectedStatus) {
            this.selectedStatus = null;
        }

        // Set offset
        this.offset = searchContextParameters['offset'] || 0;
    }

    setSearchParams(resetOffset: boolean = false) {
        if (resetOffset) {
            this.offset = 0;
        }
        let selecterContainerGuid;
        if (this.config.csKey in this.appContext.componentState && this.appContext.componentState[this.config.csKey]['guid']) {
            selecterContainerGuid = this.appContext.componentState[this.config.csKey]['guid'];
        }

        this.appContext.componentState[this.config.csKey] = {
            'type': this.filteredType || null,
            'search': this.searchTerm || null,
            'status': this.filteredStatus || null,
            'offset': this.offset || null,
            'ordering': this.ordering || null,
        };
        if (this.config.keepSelectionOnReload && (this.selectedContainer || selecterContainerGuid) ) {
            if (this.selectedContainer) {
                this.appContext.componentState[this.config.csKey]['guid'] = this.selectedContainer.guid;
            } else {
                this.appContext.componentState[this.config.csKey]['guid'] = selecterContainerGuid;
            }
        }
    }

    handleToggleFilters(event) {
        this.filterToggle = !this.filterToggle;
    }

    handleRefresh(event) {
        this.offset = 0;
        this.appContext.componentState[this.config.csKey]['offset'] = 0;
        this.loadResults();
    }

    loadResults() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(
                this.limit,
                this.offset,
                this.searchTerm ? [this.searchTerm] : [],
                this.filteredType || this.defaultTypes,
                this.filteredStatus || '',
                this.ordering.length > 0 ? this.ordering : ['-created_date']
            )
            .subscribe(
                res => {
                    this.searchResult = res;
                    //this.containers = this.searchResult.results;
                    this.containers = this.processTableData(this.searchResult.results);
                    let selectedContainer = this.containers[0];
                    if (this.config.keepSelectionOnReload && 'guid' in this.appContext.componentState[this.config.csKey]) {
                        let activeGuid = this.appContext.componentState[this.config.csKey]['guid'];
                        for (let i = 1; i < this.containers.length; i++) {
                            if (this.containers[i]['guid'] === activeGuid) {
                                selectedContainer = this.containers[i];
                                break;
                            }
                        }
                    }
                    this.selectedContainer = selectedContainer;
                    this.onRowSelect.emit({container: this.selectedContainer, origin: 'load'});
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    loadResultsLazy(event) {
        //title, status, type, show title
        if (event.sortField) {
            var orderingArg = '';

            if (event.sortOrder == -1) {
                orderingArg = orderingArg + '-';

            }
            //support for data. fields
            if (event.sortField.split('.').length > 1) {
                orderingArg = orderingArg + event.sortField.split('.')[1];
            } else {
                orderingArg = orderingArg + event.sortField;
            }

            this.ordering[0] = orderingArg;
        }

        console.log("LAZY EVENT")
        console.log(event)
        this.limit = event.rows;
        this.offset = event.first;
        this.loadResults();
        this.setSearchParams();
    }

    changedSearchTerm(event) {
        this.loadResults();
        this.setSearchParams(true);
    }

    findParentContainerId(container) {
        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id || '']);
    }

    toBold(data) {
        return '<b>' + data + '</b>';
    }

    formatDateTime(data) {
        return this.datePipe.transform(data, 'yyyy-MMM-dd HH:mm:ss');
    }


    toEntityTypeLabel(data) {
        return '<div class="label label-default">'
            + (data || '')
            + '</div>';
    }

    toStatusLabel(data) {
        if (!data) {
            return '';
        }
        switch (data.toLowerCase()) {
            case 'waiting':
            case 'processing':
                return '<div class="label label-info">' + this.toTitleCase(data) + '</div>';
            // case 'incomplete':
            case 'error':
            case 'rejected':
            case 'unpublished':
                return '<div class="label label-danger">' + this.toTitleCase(data) + '</div>';
            // case 'warning':
            case 'ready':
            case 'approved':
                return '<div class="label label-primary">' + this.toTitleCase(data) + '</div>';
            case 'scheduled':
                return '<div class="label label-warning">' + this.toTitleCase(data) + '</div>';
            // case 'complete':
            case 'published':
                return '<div class="label label-success">' + this.toTitleCase(data) + '</div>';
            default:
                return '<div class="label label-default">' + this.toTitleCase(data) + '</div>';
        }
    }

    toTitleCase(data) {
        let txt = data || '';
        if (!txt) {
            return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    processTableData(containers) {
        containers.forEach(container => {
            let row = {};
            this.columns.forEach(column => {
                let fieldName = column.field;
                row[fieldName] = this.getFieldData(container, column);
            });
            container['table_data'] = row
        });
        return containers;
    }

    getFieldData(container, column) {
        let data = container;
        let fields = column.field.split('.');
        for (let field of fields) {
            data = data[field];
        }
        data = data || '';
        if (column.transform) {
            for (let i = 0; i < column.transform.length; i++) {
                data = column.transform[i](data);
            }
        }
        return data;
    }

    onChangedFilteredType(event) {
        this.filteredType = event.value ? event.value.name : '';
        this.loadResults();
        this.setSearchParams(true);
    }

    onChangedFilteredStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.loadResults();
        this.setSearchParams(true);
    }

    onRowSelected($event){
        this.setSearchParams();
        this.onRowSelect.emit({container:this.selectedContainer, origin: 'select'});
    }

    onRowUnselected($event){

    }

    rowUpdate(guid) {
      this.containerService
        .getByGuid(guid, 'full', -1, true)
        .subscribe(
          res => {
              if (res.results && res.results.length === 1) {
                for (let i = 0; i < this.containers.length; i++) {
                    if (res.results[0].guid === this.containers[i].guid) {
                        let processedContainer = this.processTableData(res.results);
                        this.containers[i] = processedContainer[0];
                    }
                  }
              }
          });
    }

}

