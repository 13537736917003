import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Container } from '../../../content/models';
import { CAPIService } from '../../services/capi/capi.service';

import { Message } from 'primeng/api';

@Component({
    selector: 'container-search-autocomplete',
    providers: [
        CAPIService
    ],
    templateUrl: 'container-search-autocomplete.component.html',
    styleUrls: ['container-search-autocomplete.component.css']
})
export class ContainerSearchAutoCompleteComponent implements OnInit {

    @Input() filterDefinition: any;
    @Input() enabled: any;
    @Input() multiSelectEnabled: any;
    @Input() parentObjects: any;
    @Output() onValueChange: EventEmitter<any> = new EventEmitter();
    private query: any;
    private listValues: any[] = [];
    public suggestedValues: any[] = [];
    private error: any;
    public isLoading = false;

    public selectedValues: any[];

    private filterValuesQueryParameters: any;
    private filterValuesSearchParameter: any;

    private suggestionsSubscription: any;

    constructor(
        private CAPIService: CAPIService,
    ) { }

    ngOnInit() {
        if (this.parentObjects) {
            this.selectedValues = this.parentObjects
            //Selected Value are always stored in array.  Multi select requires selectedValues be an array, single select requires a {}. 
            if ((this.multiSelectEnabled == false) && (this.selectedValues.length >= 1)) {
                this.selectedValues = this.selectedValues[0]
            }
        }
        if (this.filterDefinition) {
            if (this.filterDefinition['query_parameters']) {
                this.filterValuesQueryParameters = this.filterDefinition['query_parameters'];
            }
            if (this.filterDefinition['search_parameter']) {
                this.filterValuesSearchParameter = this.filterDefinition['search_parameter'];
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.filterDefinition && changes['filterDefinition'].previousValue != changes['filterDefinition'].currentValue) {
            this.onClear()
            this.getValueSuggestions(null)
        }
    }


    onChange() {
        this.onValueChange.emit({
            selected: this.buildFilterTypeArray()
        });
    }

    getValueSuggestions(event) {
        if (this.suggestionsSubscription)
        {
            this.suggestionsSubscription.unsubscribe();
        }
        this.filterValuesQueryParameters = this.filterDefinition['query_parameters'];
        console.log("search_autocomplete", this.filterValuesQueryParameters, this.filterDefinition)
        if (this.filterDefinition['search_parameter']) {
            this.filterValuesSearchParameter = this.filterDefinition['search_parameter'];
        }
        this.isLoading = true;
        if (event) {
            this.query = event.query.toLowerCase()
        }
        else {
            this.query = null
        }
        this.suggestionsSubscription = this.CAPIService
            .getContainerWithSearchParams(this.query, this.filterValuesQueryParameters, this.filterValuesSearchParameter)
            .subscribe(
                res => {
                    this.listValues = [];
                    let containerPayload = res;
                    if ("results" in containerPayload) {
                        containerPayload = containerPayload['results'];
                    }
                    for (let c = 0; c < containerPayload.length; c++) {
                        this.listValues.push({ 'label': containerPayload[c]['label'], 'value': containerPayload[c]['guid'] });
                    }
                },
                err => this.error = err,
                () => {
                    this.suggestedValues = this.listValues;
                    this.isLoading = false;
                }
            );
    }

    buildFilterTypeArray() {
        let value_array = [];
        if (Array.isArray(this.selectedValues)) {
            this.selectedValues.forEach(value => {
                value_array.push(value);
            })
        }
        else {
            value_array.push(this.selectedValues);
        }
        return value_array;
    }

    onClear() {
        this.selectedValues = [];
    }

    onDeselect(event) {
    }

    ngOnDestroy() {
        if (this.suggestionsSubscription)
        {
            this.suggestionsSubscription.unsubscribe();
        }
    }
    

}
