<div class="card card-w-title top-level-card">
    <h1>Edit Configuration{{container && container.data['name'] ? ': ' + container.data['name'] : ''}}</h1>

    <page-header title="Edit Configuration{{container && container.data['name'] ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <configuration-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formChangesScrapped)="onScrap($event)" (formCancelled)="onBack($event)"></configuration-form>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data.entries" title="Entries"></object-advancedview>
        </div>
    </div>
</div>
