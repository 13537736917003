import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'movie-form',
    templateUrl: 'movie-form.component.html'
})
export class MovieFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() pipelineTriggered: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityTypes = [];
    private entityTypeId;
    private selectedSeasonGuid;
    public isLoading = false;

    private publishDate :Date;

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private movieRatings = ['G', 'PG', '14A', '18A', 'R', 'A'];
    private ratings = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
    private genre = genreList;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.msgs = [];

        var valid = this.ngForm.form.status === 'VALID';
        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onStatusComplete() {
        this.formSubmit.emit({
            container: this.container
        });
    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onPipelineTriggered(e) {
        this.pipelineTriggered.emit(e);
    }

    onCuepointEdit(event){
        this.container.data['chapter_start_times'] = event.edited;
    }
}
