import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDiffModule } from 'ngx-diff';

import {
    appRoutes,

} from './app.routes';
import { AppComponent } from './app.component';
import { AppContext } from './app.context';

import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppSideBarComponent } from './app.sidebar.component';
import { AppSideBarTabContentComponent } from './app.sidebartabcontent.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { LandingComponent } from './landing/landing.component';
import { FeaturedComponent } from './content/components/featured/featured.component';
import { SeriesComponent } from './content/components/series/series.component';
import { ChannelsComponent } from './content/components/channels/channels.component';
import { StatusComponent } from './status/status.component';
import { LoginComponent } from './auth/login.component';
import { LogoutComponent } from './auth/logout.component';
import { LoginFormComponent } from './auth/login-form.component';
import { ExternalLoginComponent } from './auth/login-external.component';

import { ContainerComponent } from './content/components/container/container.component';
import { ContainerParentComponent } from './content/components/container-parent/container-parent.component';
import { ContainerRootComponent } from './content/components/container-root/container-root.component';
import { ContainerTreeComponent } from './content/components/container-tree/container-tree.component';
import { ContainerTableComponent } from './content/components/container-table/container-table.component';
import { ContainerCollectionComponent } from './content/components/container-collection/container-collection.component';
import { ContainerListComponent } from './content/components/container-list/container-list.component';
import { ContainerRowComponent } from './content/components/container-row/container-row.component';
import { ContainerCreateComponent } from './content/components/container-create/container-create.component';
import { ContainerEditComponent } from './content/components/container-edit/container-edit.component';
import { ContainerRelateComponent } from './content/components/container-relate/container-relate.component';
import { ContainerMultiParentRelateComponent } from './content/components/container-multi-parent-relate/container-multi-parent-relate.component';
import { ContainerDatarowComponent } from './content/components/container-datarow/container-datarow.component';
import { ContainerPaginatedDatarowComponent } from './content/components/container-paginated-datarow/container-paginated-datarow.component';
import { ContainerDataviewComponent } from './content/components/container-dataview/container-dataview.component';
import { ContainerFormComponent } from './content/forms/container-form/container-form.component';
import { ContainerRelateFormComponent } from './content/forms/container-relate-form/container-relate-form.component';
import { KeyValueFormComponent } from './content/forms/keyvalue-form/keyvalue-form.component';
import { ObjectAdvancedViewComponent } from './content/components/object-advancedview/object-advancedview.component';
import { ObjectAdvancedViewGridComponent } from './content/components/object-advancedview-grid/object-advancedview-grid.component';
import { ContainerDefaultOrderingComponent } from './content/components/container-default-ordering/container-default-ordering.component';
import { ContainerAddComponent } from './content/components/container-add/container-add.component';

import { ContainerStatusEditComponent } from './content/components/container-status-edit/container-status-edit.component';
import { ContainerOperationsComponent } from './content/components/container-operations/container-operations.component';
import { ContainerSearchAutoCompleteComponent } from './content/components/container-search-autocomplete/container-search-autocomplete.component';

import { SettingsOriginsComponent } from './settings/components/origins/settings-origins.component';
import { OriginCreateComponent } from './settings/components/origin-create/origin-create.component';
import { OriginEditComponent } from './settings/components/origin-edit/origin-edit.component';
import { OriginFormComponent } from './settings/forms/origin-form/origin-form.component';

import { SettingsProvidersComponent } from './settings/components/providers/settings-providers.component';
import { ProviderCreateComponent } from './settings/components/provider-create/provider-create.component';
import { ProviderEditComponent } from './settings/components/provider-edit/provider-edit.component';
import { ProviderFormComponent } from './settings/forms/provider-form/provider-form.component';

import { ViewMetadataFormPanelComponent } from './content/forms/view-metadata-form-panel/view-metadata-form-panel.component';

import { ContainerTypeFilterPipe } from './content/components/container/containerTypeFilter.pipe';
import { CountByTypeNamePipe } from './content/components/container/countByTypeName.pipe';
import { MapToIterablePipe } from './content/components/container-create/maptoiterable.pipe';

import { ToolsComponent } from './tools/tools.component';

import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';

import { CACampaignWorklistComponent } from './commerce/components/contextual-ads-campaign/contextual-ads-campaign.component';
import { CACampaignSearchTableComponent } from './commerce/components/contextual-ads-campaign-search/contextual-ads-campaign-search.component';
import { CACampaignsFormComponent } from './commerce/forms/contextual-ads-campaign-form/contextual-ads-campaign-form.component';

import { CACampaignEditComponent } from './commerce/components/campaign-edit/campaign-edit.component';
import { CACampaignFormComponent } from './commerce/forms/campaign-form/campaign-form.component';
import { CACampaignCreateComponent } from './commerce/components/campaign-create/campaign-create.component';
import { CACampaignTableComponent } from './commerce/components/campaign-table/campaign-table.component';

import { CAContextWorklistComponent } from './commerce/components/contextual-ads-context/contextual-ads-context.component';
import { CAContextSearchTableComponent } from './commerce/components/contextual-ads-context-search/contextual-ads-context-search.component';
import { CAContextFormComponent } from './commerce/forms/contextual-ads-context-form/contextual-ads-context-form.component';

import { CAContentGroupFormComponent } from './commerce/forms/content-group-form/content-group-form.component';
import { CAContentGroupEditComponent } from './commerce/components/content-group-edit/content-group-edit.component';
import { CAContentGroupCreateComponent } from './commerce/components/content-group-create/content-group-create.component';
import { CAMomentTableComponent } from './commerce/components/moment-table/moment-table.component';

import { CASegmentMediaTableComponent } from './commerce/components/segment-media-table/segment-media-table.component';
import { CASegmentTimelimePlayerComponent } from './commerce/components/segment-timeline-player/segment-timeline-player.component';
import { CAGraphComponent } from './commerce/components/contextual-ads-graph/contextual-ads-graph.component';

import { CASegmentKeywordComponent } from './commerce/components/segment-keyword-autocomplete/segment-keyword-autocomplete.component';


import {TimelineFilterBarChartComponent} from './commerce/components/timeline-chart/timeline-chart.component';

import { OriginSelectComponent } from './commerce/components/origin-select/origin-select.component';

import { WorklistComponent } from './workflow/components/worklist/worklist.component';
import { WorklistTableComponent } from './workflow/components/worklist-table/worklist-table.component';
import { WorklistEpisodeFormComponent } from './workflow/forms/worklist-episode-form/worklist-episode-form.component';


import { SearchTableComponent } from './content/components/search-table/search-table.component';

import { ResourceComponent } from './content/components/resource/resource.component';
import { ResourceRootComponent } from './content/components/resource-root/resource-root.component';
import { ResourceListComponent } from './content/components/resource-list/resource-list.component';
import { ResourceCreateComponent } from './content/components/resource-create/resource-create.component';
import { ResourceEditComponent } from './content/components/resource-edit/resource-edit.component';
import { ResourceDatarowComponent } from './content/components/resource-datarow/resource-datarow.component';
import { ResourceFormComponent } from './content/forms/resource-form/resource-form.component';

import { UploadMediaFormComponent } from './content/forms/upload-media-form/upload-media-form.component';
import { CreateMediaFormComponent } from './content/forms/create-media-form/create-media-form.component';

import { UploadFilesFormComponent } from './content/forms/upload-files-form/upload-files-form.component';
import { ResourceUploadComponent } from './content/components/resource-upload/resource-upload.component';

import { RelationTagFormComponent } from './content/forms/relation-tag-form/relation-tag-form.component';

import { UploadResourceSimpleFormComponent } from './content/forms/upload-resource-simple-form/upload-resource-simple-form.component';


import { MediaObjectCreateComponent } from './content/components/media-object-create/media-object-create.component';

import { HistoryComponent } from './content/components/history/history.component';
import { HistoryListComponent } from './content/components/history-list/history-list.component';
import { HistoryDiffComponent } from './content/components/history-diff/history-diff.component';
import { HistoryRevertComponent } from './content/components/history-revert/history-revert.component';

import { PageHeaderComponent } from './ui/page-header.component';
import { ErrorComponent } from './ui/error.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UploadComponent } from './upload/upload.component';

import { AuthService } from './auth/auth.service';
import { EntityTypeService } from './content/services/entitytype/entitytype.service';
import { ContainerService } from './content/services/container/container.service';
import { CAPIService } from './content/services/capi/capi.service';
import { HistoryService } from './content/services/history/history.service';
import { ResourceService } from './content/services/resource/resource.service';
import { UploadService } from './upload/upload.service';
import { ResourceUploadService } from './upload/resource-upload.service';
import { PipelineService } from './pipeline/pipeline.service';
import { AuthGuard } from './auth/auth-guard.service';
import { EpochDatePipe } from './status/epochdate.pipe';
import { DragulaModule, DragulaService } from 'ng2-dragula';

import { ViewAPIService } from './preview/services/view.api.service';
import { MetricsService } from './dashboard/services/metrics.service';
import { WorkflowService } from './workflow/services/workflow.service';
import { CommerceService } from './commerce/services/commerce.service';


import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AccordionModule } from 'primeng/accordion';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { ListboxModule } from 'primeng/listbox';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';



import { ApplicationsComponent } from './settings/components/applications/applications.component';
import { ApplicationCreateComponent } from './settings/components/application-create/application-create.component';
import { ApplicationEditComponent } from './settings/components/application-edit/application-edit.component';
import { ApplicationFormComponent } from './settings/forms/application-form/application-form.component';

import { DistributionCreateComponent } from './settings/components/distribution-create/distribution-create.component';
import { DistributionEditComponent } from './settings/components/distribution-edit/distribution-edit.component';
import { DistributionRowComponent } from './settings/components/distribution-row/distribution-row.component';
import { DistributionContainerComponent } from './settings/components/distribution-container/distribution-container.component';
import { DistributionFormComponent } from './settings/forms/distribution-form/distribution-form.component';

import { PipelinesComponent } from './settings/components/pipelines/pipelines.component';
import { PipelineCreateComponent } from './settings/components/pipeline-create/pipeline-create.component';
import { PipelineEditComponent } from './settings/components/pipeline-edit/pipeline-edit.component';
import { PipelineFormComponent } from './settings/forms/pipeline-form/pipeline-form.component';

import { TriggerPipelineModalFormComponent } from './content/forms/trigger-pipeline-modal-form/trigger-pipeline-modal-form.component';
import { ReparentModalFormComponent } from './content/forms/reparent-modal-form/reparent-modal-form.component';

import { PipelineStatusComponent } from './content/components/pipeline-status-component/pipeline-status.component';
import { PipelineActionComponent } from './dashboard/components/pipeline-action/pipeline-action.component';

import { ProfileTableComponent } from './settings/components/profile-table/profile-table.component';
import { ProfileCreateComponent } from './settings/components/profile-create/profile-create.component';
import { ProfileEditComponent } from './settings/components/profile-edit/profile-edit.component';
import { ProfileFormComponent } from './settings/forms/profile-form/profile-form.component';

import { UserProfileCreateComponent } from './settings/components/user-profile-create/user-profile-create.component';
import { UserProfileEditComponent } from './settings/components/user-profile-edit/user-profile-edit.component';
import { UserProfileFormComponent } from './settings/forms/user-profile-form/user-profile-form.component';

import { WorkerCreateComponent } from './settings/components/worker-create/worker-create.component';
import { WorkerEditComponent } from './settings/components/worker-edit/worker-edit.component';
import { WorkerFormComponent } from './settings/forms/worker-form/worker-form.component';

import { RolesComponent } from './settings/components/roles/roles.component';
import { RoleCreateComponent } from './settings/components/role-create/role-create.component';
import { RoleEditComponent } from './settings/components/role-edit/role-edit.component';
import { RoleFormComponent } from './settings/forms/role-form/role-form.component';

import { VersionCreateComponent } from './settings/components/version-create/version-create.component';
import { VersionEditComponent } from './settings/components/version-edit/version-edit.component';
import { VersionFormComponent } from './settings/forms/version-form/version-form.component';

import { ConfigurationCreateComponent } from './settings/components/configuration-create/configuration-create.component';
import { ConfigurationEditComponent } from './settings/components/configuration-edit/configuration-edit.component';
import { ConfigurationFormComponent } from './settings/forms/configuration-form/configuration-form.component';

import { SettingsPlatformsComponent } from './settings/components/platforms/settings-platforms.component';
import { SettingsPoliciesComponent } from './settings/components/policies/settings-policies.component';
import { SettingsIntegrationsComponent } from './settings/components/integrations/settings-integrations.component';
import { SettingsReportsComponent } from './settings/components/reports/settings-reports.component';

import { PlatformCreateComponent } from './content/components/platform-create/platform-create.component';

import { PlatformFormComponent } from './content/forms/platform-form/platform-form.component';
import { PlatformEditComponent } from './content/components/platform-edit/platform-edit.component';
import { PlatformTableComponent } from './content/components/platform-table/platform-table.component';

import { PolicyFormComponent } from './content/forms/policy-form/policy-form.component';
import { PolicyEditComponent } from './content/components/policy-edit/policy-edit.component';
import { PolicyCreateComponent } from './content/components/policy-create/policy-create.component';

import { ReportFormComponent } from './settings/forms/report-form/report-form.component';
import { ReportEditComponent } from './settings/components/report-edit/report-edit.component';
import { ReportCreateComponent } from './settings/components/report-create/report-create.component';

import { ServerFormComponent } from './content/forms/server-form/server-form.component';
import { ServerEditComponent } from './content/components/server-edit/server-edit.component';
import { ServerCreateComponent } from './content/components/server-create/server-create.component';
import { ServerTableComponent } from './content/components/server-table/server-table.component';

import { CatalogCreateComponent } from './content/components/catalog-create/catalog-create.component';

import { CatalogFormComponent } from './content/forms/catalog-form/catalog-form.component';
import { CatalogEditComponent } from './content/components/catalog-edit/catalog-edit.component';
import { CatalogTableComponent } from './content/components/catalog-table/catalog-table.component';

import { CatalogModalFormComponent } from './content/forms/catalog-modal-form/catalog-modal-form.component';

import { DocumentFormComponent } from './content/forms/document-form/document-form.component';
import { DocumentEditComponent } from './content/components/document-edit/document-edit.component';
import { DocumentCreateComponent } from './content/components/document-create/document-create.component';

import { PolicyTableComponent } from './content/components/policy-table/policy-table.component';
import { OriginTableComponent } from './content/components/origin-table/origin-table.component';

import { SeriesFormComponent } from './content/forms/series-form/series-form.component';
import { SeriesEditComponent } from './content/components/series-edit/series-edit.component';
import { SeriesCreateComponent } from './content/components/series-create/series-create.component';

import { EpisodeContainerPolicyTableComponent } from './content/components/episode-container-policy-table/episode-container-policy-table.component';
import { EpisodeContainerPlatformTableComponent } from './content/components/episode-container-platform-table/episode-container-platform-table.component';

import { PreviewFeaturedContentComponent } from './preview/components/featured/preview-featured-content.component';

import { PreviewSeriesDetailComponent } from './preview/components/series/preview-series-detail.component';

import { PreviewPlayerComponent } from './preview/components/player/preview-player.component';

import { MoviesComponent } from './content/components/movies/movies.component';
import { MoviesEditComponent } from './content/components/movies-edit/movies-edit.component';
import { MovieFormComponent } from './content/forms/movie-form/movie-form.component';

import { EpisodeFormComponent } from './content/forms/episode-form/episode-form.component';
import { EpisodeEditComponent } from './content/components/episode-edit/episode-edit.component';
import { EpisodeRowComponent } from './content/components/episode-row/episode-row.component';
import { EpisodeContainerComponent } from './content/components/episode-container/episode-container.component';

import { MediaFormComponent } from './content/forms/media-form/media-form.component';
import { MediaEditComponent } from './content/components/media-edit/media-edit.component';
import { MediaRowComponent } from './content/components/media-row/media-row.component';
import { MediaContainerComponent } from './content/components/media-container/media-container.component';

import { ChannelFormComponent } from './content/forms/channel-form/channel-form.component';
import { ChannelEditComponent } from './content/components/channel-edit/channel-edit.component';

import { SeasonsRowComponent } from './content/components/seasons-row/seasons-row.component';

import { ContainerMembershipRowComponent } from './content/components/container-membership-row/container-membership-row.component';

import { SeasonFormComponent } from './content/forms/season-form/season-form.component';
import { SeasonEditComponent } from './content/components/season-edit/season-edit.component';
import { SeasonCreateComponent } from './content/components/season-create/season-create.component';


import { CollectionEditComponent } from './content/components/collection-edit/collection-edit.component';
import { CollectionFormComponent } from './content/forms/collection-form/collection-form.component';
import { CollectionRowComponent } from './content/components/collection-row/collection-row.component';
import { CollectionCreateComponent } from './content/components/collection-create/collection-create.component';
import { CollectionContainerComponent } from './content/components/collection-container/collection-container.component';

import { CuePointEditComponent } from './content/components/cue-point-edit/cue-point-edit.component';
import { UniversalRowComponent } from './content/components/universal-row/universal-row.component';

import { ViewsComponent } from './settings/components/views/views.component';
import { ViewCreateComponent } from './settings/components/view-create/view-create.component';
import { ViewEditComponent } from './settings/components/view-edit/view-edit.component';
import { ViewFormComponent } from './settings/forms/view-form/view-form.component';

import { ContainerTagsPanelComponent } from './content/components/container-tags-panel/container-tags-panel.component';
import { ContainerTagsTabComponent } from './content/components/container-tags-tab/container-tags-tab.component';
import { ContainerResourceTagsComponent } from './content/components/container-resource-tags/container-resource-tags.component';
import { ContainerSubgenreComponent } from './content/components/container-subgenre/container-subgenre.component';
import { ContainerCardComponent } from './content/components/container-card/container-card.component';

import { MetricsDashboardComponent } from './metrics/components/metrics-dashboard/metrics-dashboard.component';
import { MetricsReportsComponent } from './metrics/components/metrics-reports/metrics-reports.component';
import { MetricsReportsListComponent } from './metrics/components/metrics-reports-list/metrics-reports-list.component';
import { MetricsReportLineGraphComponent } from './metrics/reports/metrics-report-line-graph/metrics-report-line-graph.component';
import { MetricsReportsFilterBarComponent } from './metrics/components/metrics-reports-filter-bar/metrics-reports-filter-bar.component';
import { MetricsReportsDateRangeComponent } from './metrics/components/metrics-reports-date-range/metrics-reports-date-range.component';
import { MetricsReportPanelComponent } from './metrics/components/metrics-report-panel/metrics-report-panel.component';
import { MetricsReportAdvancedPieGridComponent } from './metrics/reports/metrics-report-advanced-pie-grid/metrics-report-advanced-pie-grid.component';
import { MetricsReportAdvancedPieGraphComponent } from './metrics/reports/metrics-report-advanced-pie-graph/metrics-report-advanced-pie-graph.component';
import { MetricsReportHorizontalBarGraphComponent } from './metrics/reports/metrics-report-horizontal-bar-graph/metrics-report-horizontal-bar-graph.component';
import { MetricsReportNumberCardsComponent } from './metrics/reports/metrics-report-number-cards/metrics-report-number-cards.component';
import { MetricsReportPolarGraphComponent } from './metrics/reports/metrics-report-polar-graph/metrics-report-polar-graph.component';

import { MetricsReportsDataTableComponent } from './metrics/components/metrics-reports-data-table/metrics-reports-data-table.component';

import { MetricsReportsFilterContainerAutoCompleteComponent } from './metrics/components/metrics-reports-filter-containers-autocomplete/metrics-reports-filter-containers-autocomplete.component';
import { MetricsReportsFilterContainerPulldownComponent } from './metrics/components/metrics-reports-filter-containers-pulldown/metrics-reports-filter-containers-pulldown.component';
import { MetricsReportsFilterEnumPulldownComponent } from './metrics/components/metrics-reports-filter-enum-pulldown/metrics-reports-filter-enum-pulldown.component';


import { DatePipe } from '@angular/common';
import { UriSchemePipe, FileExtPipe, FileNamePipe, TimeframePipe, RawcasePipe, LabelToDisplayPipe } from './shared/custom.pipes';


// Import your library
import { OwlModule } from 'ngx-owl-carousel';

import { NgJsonEditorModule } from 'ang-jsoneditor'
import { ZoomTimelineComponent } from './commerce/components/zoom-timeline/zoom-timeline.component';
import { ContentGroupService } from './commerce/services/content-group.service';
import { KeywordFrequencyComponent } from './commerce/components/keyword-frequency/keyword-frequency.component';
import { CanvasService } from './shared/canvas.service';


@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        FeaturedComponent,
        SeriesComponent,
        ChannelsComponent,

        ContainerComponent,
        ContainerParentComponent,
        ContainerTreeComponent,
        ContainerTableComponent,
        ContainerRootComponent,
        ContainerEditComponent,
        ContainerCreateComponent,
        ContainerListComponent,
        ContainerRowComponent,
        ContainerRelateComponent,
        ContainerMultiParentRelateComponent,
        ContainerCollectionComponent,
        ContainerDatarowComponent,
        ContainerDataviewComponent,
        ContainerFormComponent,
        ContainerRelateFormComponent,
        KeyValueFormComponent,
        ContainerPaginatedDatarowComponent,
        ObjectAdvancedViewComponent,
        ObjectAdvancedViewGridComponent,
        ContainerDefaultOrderingComponent,
        ContainerStatusEditComponent,
        ContainerOperationsComponent,
        ContainerAddComponent,
        ContainerCardComponent,
        ContainerSearchAutoCompleteComponent,

        AppMenuComponent,
        AppSubMenuComponent,
        AppSideBarComponent,
        AppSideBarTabContentComponent,
        AppTopBarComponent,
        AppFooterComponent,

        DashboardComponent,

        WorklistComponent,
        WorklistTableComponent,
        WorklistEpisodeFormComponent,

        SearchTableComponent,

        UniversalRowComponent,

        SeasonsRowComponent,
        PolicyTableComponent,
        OriginTableComponent,

        ResourceComponent,
        ResourceRootComponent,
        ResourceListComponent,
        ResourceCreateComponent,
        ResourceEditComponent,
        ResourceDatarowComponent,
        ResourceFormComponent,

        ApplicationsComponent,
        ApplicationCreateComponent,
        ApplicationEditComponent,
        ApplicationFormComponent,

        ZoomTimelineComponent,
        KeywordFrequencyComponent,

        RolesComponent,
        RoleCreateComponent,
        RoleEditComponent,
        RoleFormComponent,

        DistributionCreateComponent,
        DistributionEditComponent,
        DistributionRowComponent,
        DistributionContainerComponent,
        DistributionFormComponent,

        PipelinesComponent,
        PipelineCreateComponent,
        PipelineEditComponent,
        PipelineFormComponent,
        PipelineStatusComponent,
        PipelineActionComponent,

        ProfileCreateComponent,
        ProfileEditComponent,
        ProfileFormComponent,
        ProfileTableComponent,

        UserProfileCreateComponent,
        UserProfileEditComponent,
        UserProfileFormComponent,

        WorkerCreateComponent,
        WorkerEditComponent,
        WorkerFormComponent,

        VersionCreateComponent,
        VersionEditComponent,
        VersionFormComponent,

        ConfigurationCreateComponent,
        ConfigurationEditComponent,
        ConfigurationFormComponent,

        SettingsIntegrationsComponent,
        SettingsPoliciesComponent,
        SettingsPlatformsComponent,
        SettingsReportsComponent,

        PlatformCreateComponent,
        PlatformFormComponent,
        PlatformEditComponent,
        PlatformTableComponent,

        PolicyFormComponent,
        PolicyEditComponent,
        PolicyCreateComponent,

        ReportFormComponent,
        ReportEditComponent,
        ReportCreateComponent,

        ServerFormComponent,
        ServerEditComponent,
        ServerCreateComponent,
        ServerTableComponent,


        CatalogCreateComponent,
        CatalogFormComponent,
        CatalogEditComponent,
        CatalogTableComponent,
        CatalogModalFormComponent,

        DocumentFormComponent,
        DocumentEditComponent,
        DocumentCreateComponent,

        EpisodeContainerPolicyTableComponent,
        EpisodeContainerPlatformTableComponent,

        PreviewFeaturedContentComponent,
        PreviewSeriesDetailComponent,
        PreviewPlayerComponent,

        ChannelEditComponent,
        ChannelFormComponent,

        MoviesComponent,
        MoviesEditComponent,
        MovieFormComponent,

        EpisodeFormComponent,
        EpisodeEditComponent,
        EpisodeRowComponent,
        EpisodeContainerComponent,

        MediaFormComponent,
        MediaEditComponent,
        MediaRowComponent,
        MediaContainerComponent,

        SeriesEditComponent,
        SeriesFormComponent,
        SeriesCreateComponent,

        SeasonFormComponent,
        SeasonEditComponent,
        SeasonCreateComponent,

        CollectionEditComponent,
        CollectionFormComponent,
        CollectionRowComponent,
        CollectionCreateComponent,
        CollectionContainerComponent,

        CuePointEditComponent,
        ViewMetadataFormPanelComponent,

        ContainerMembershipRowComponent,

        ViewsComponent,
        ViewCreateComponent,
        ViewEditComponent,
        ViewFormComponent,

        UploadMediaFormComponent,
        CreateMediaFormComponent,
        UploadFilesFormComponent,
        RelationTagFormComponent,
        UploadResourceSimpleFormComponent,
        MediaObjectCreateComponent,
        ResourceUploadComponent,
        TriggerPipelineModalFormComponent,
        ReparentModalFormComponent,
        
        MetricsDashboardComponent,
        MetricsReportsComponent,
        MetricsReportsListComponent,
        MetricsReportLineGraphComponent,
        MetricsReportsFilterBarComponent,
        MetricsReportPanelComponent,
        MetricsReportsDateRangeComponent,
        MetricsReportAdvancedPieGraphComponent,
        MetricsReportAdvancedPieGridComponent,
        MetricsReportHorizontalBarGraphComponent,
        MetricsReportNumberCardsComponent,
        MetricsReportPolarGraphComponent,
        MetricsReportsDataTableComponent,
        MetricsReportsFilterContainerAutoCompleteComponent,
        MetricsReportsFilterContainerPulldownComponent,
        MetricsReportsFilterEnumPulldownComponent,

        SettingsOriginsComponent,
        OriginEditComponent,
        OriginCreateComponent,
        OriginFormComponent,

        SettingsProvidersComponent,
        ProviderEditComponent,
        ProviderCreateComponent,
        ProviderFormComponent,

        ToolsComponent,

        StatusComponent,
        LoginComponent,
        LogoutComponent,
        LoginFormComponent,
        ExternalLoginComponent,

        PageHeaderComponent,
        SpinnerComponent,
        UploadComponent,

        ErrorComponent,

        ContainerTagsPanelComponent,
        ContainerTagsTabComponent,
        ContainerResourceTagsComponent,
        ContainerSubgenreComponent,
        HistoryComponent,
        HistoryListComponent,
        HistoryDiffComponent,
        HistoryRevertComponent,

        EpochDatePipe,
        ContainerTypeFilterPipe,
        CountByTypeNamePipe,
        MapToIterablePipe,
        UriSchemePipe,
        FileExtPipe,
        FileNamePipe,
        CACampaignWorklistComponent,
        CACampaignSearchTableComponent,
        CACampaignsFormComponent,
        CACampaignEditComponent,
        CACampaignFormComponent,
        CACampaignCreateComponent,
        CACampaignTableComponent,
        CAContextWorklistComponent,
        CAContextSearchTableComponent,
        CAContextFormComponent,
        CAContentGroupFormComponent,
        CAContentGroupEditComponent,
        CAContentGroupCreateComponent,
        CAMomentTableComponent,
        CASegmentKeywordComponent,
        CASegmentMediaTableComponent,
        CASegmentTimelimePlayerComponent,
        TimelineFilterBarChartComponent,
        TimeframePipe,
        RawcasePipe,
        LabelToDisplayPipe,
        OriginSelectComponent,
        CAGraphComponent
    ],

    providers: [
        AuthService,
        AuthGuard,
        AppContext,
        EntityTypeService,
        ContainerService,
        CAPIService,
        HistoryService,
        ContentGroupService,
        CanvasService,
        ResourceService,
        ResourceUploadService,
        UploadService,
        MessageService,
        ConfirmationService,
        DragulaService,
        ViewAPIService,
        MetricsService,
        PipelineService,
        WorkflowService,
        CommerceService,
        DatePipe,
    ],
    imports: [
        NgJsonEditorModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'reload',
            enableTracing: false
          }),

        MenuModule,
        DialogModule,
        FieldsetModule,
        TabViewModule,
        ButtonModule,
        ToolbarModule,
        PanelModule,
        FileUploadModule,
        BreadcrumbModule,
        OwlModule,
        InputSwitchModule,
        AccordionModule,
        CalendarModule,
        SplitButtonModule,
        CardModule,
        ToastModule,
        CheckboxModule,
        DataViewModule,
        DropdownModule,
        InputTextModule,
        KeyFilterModule,
        MultiSelectModule,
        ProgressBarModule,
        ScrollPanelModule,
        TableModule,
        ToolbarModule,
        ChartModule,
        StepsModule,
        ListboxModule,
        ProgressSpinnerModule,
        TooltipModule,
        ChipsModule,
        AutoCompleteModule,
        EditorModule,
        ConfirmDialogModule,
        NgxChartsModule,
        NgxPermissionsModule.forRoot(),
        NgxDiffModule,
        DragulaModule.forRoot(),
    ],
    exports: [RouterModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
