import { Component, OnInit,Input, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MetricsService } from '../../services/metrics.service';


@Component({
    selector: 'metrics-report-horizontal-bar-graph',
    templateUrl: './metrics-report-horizontal-bar-graph.component.html',
    styleUrls: ['metrics-report-horizontal-bar-graph.component.css']
})
export class MetricsReportHorizontalBarGraphComponent implements OnInit {

    @Input() reportData: any;
    @Input() renderOptions: object;
    @Input() showReportDetails: boolean = true;
    @Input() view: any;

    private sub: any;
    private requiredMsgs = [];
    private error: any;
    private isLoading = false;
    private reportResults: any;

    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = false;
    showXAxisLabel: boolean = true;
    yAxisLabel: string = '';
    showYAxisLabel: boolean = true;
    xAxisLabel: string = '';
    //Note: When upgraded to @swimlane/ngx-charts 10.0.0, the length of x/y axis tics will be customizable
    //maxYAxisChars: 16;
    resolveLabels: boolean = false;

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };
    data: any;
    rawData: any;
    formattedDataNGX: any;
    resolvedGUIDContainers: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metricsService: MetricsService

    ) {
        this.initializeGraphData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('reportData' in changes && changes['reportData'].previousValue != changes['reportData'].currentValue) {
            this.initializeGraphData();
            if (this.reportData) {
                this.createGraphSeriesFromResultsNGX(this.reportData['results']);
            }
        }
        if ('renderOptions' in changes && changes['renderOptions'].previousValue != changes['renderOptions'].currentValue ){
            this.xAxisLabel = this.renderOptions['x_axis_label'] || '';
            this.yAxisLabel = this.renderOptions['y_axis_label'] || '';
            this.resolveLabels = !!(this.renderOptions['resolve_labels']);
        }
    }
    
    ngOnInit() {
    }

    initializeGraphData() {
        this.formattedDataNGX = [];
    }

    createGraphSeriesFromResultsNGX(results) {
        let graphValues = results.map(element => {
            return { name: element['label'], value: element['value'] };
        });

        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }

        if (this.resolveLabels) {
            // TODO: other resolution methods
            this.resolveGUIDLabels(graphValues);
        } else {
            this.formattedDataNGX = graphValues;
        }
    }

    capitalize(word) {
        if (!word) return '<undefined>';
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    resolveGUIDLabels(graphValues){
        let guidArray = graphValues.map(element => element['name']);
        this.isLoading = true;
        this.sub = this.metricsService
            .getContainerByGUIDArray(guidArray)
            .subscribe(
                res => {
                    this.resolvedGUIDContainers = res;
                },
                err => {
                    console.log(err,'Report GUID Resolver Error');
                    this.error = err.statusText;
                },
                () => {
                    const updatedValues = [];
                    const resolvedMap = new Map(this.resolvedGUIDContainers.map(container => {
                        return [container['guid'], container];
                    }));

                    graphValues.forEach((element, i) => {
                        if (resolvedMap.has(element['name'])) {
                            const container = resolvedMap.get(element['name']);
                            let label = container['label'];
                            if (container['data']['show_title']) {
                                label += " ("+ container['data']['show_title'] + ")";
                            }
                            updatedValues.push({ name: label, value: element['value'] });
                        } else {
                            updatedValues.push(element);
                        }
                    })

                    this.formattedDataNGX = updatedValues;
                    this.isLoading = false;
                }
            );
    }

    onSelect(event) {
    }

    onActivate(event) {
    }

    onDeactivate(event) {
    }

}
