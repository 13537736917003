<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left"
                    style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger"
                    icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button"
                    (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up"
                    style="float:right;"></button>
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['title']" name="title"
                        placeholder="Required">
                </div>
                <div class="ui-g-5">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description">
                </div>
                <div class="ui-g-3">
                    <label for="rating">Country of Origin</label>
                    <select [(ngModel)]="container.data.country_of_origin" name="country_of_origin" class="form-control">
                        <option *ngFor="let ccountry of country_of_origin" [value]="ccountry">{{ccountry}}</option>
                    </select>
                </div>
            </div>
        </p-panel>

        <view-metadata-form-panel [container]=container class="ui-g-12"></view-metadata-form-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['relation']" *ngxPermissionsOnly="['object.write.all', 'object.write.episode']"></trigger-pipeline-modal-form>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <select required [(ngModel)]="entityTypeId" disabled (ngModelChange)="onTypeChange()" name="type"
                        class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-4">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
                <div class="ui-g-4">
                    <label for="status">Status</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="container.status" name="status">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>
        
        <container-tags-panel [container]=container class="ui-g-12"></container-tags-panel>


    </div>
</form>