<page-header [showSpinner]="isLoading" [error]="error?.statusText">
</page-header>
<div *ngIf="!isLoading" class="worklist-component">

    <div class="ui-g-7 worklist-table-view strong-highlight">
        <worklist-table [defaultSearchTerm]="defaultSearchTerm" [containers]="this.containers" (rowSelect)="containerSelected($event)"></worklist-table>
    </div>

    <div class="ui-g-5 worklist-form-view" *ngIf="selectedContainer">
        <worklist-episode-form  [containerId]="selectedContainer.id" [seasons]="[]" (formSubmit)="onSubmit($event)" (onContainerUpdate)="onContainerUpdate($event)"  (formCancel)="onCancel($event)"></worklist-episode-form>
    </div>

</div>
