<p-panel [style]="{'textAlign':'center'}">
    <p-header>
        <div class="ui-g" *ngIf="typeString != 'version' && typeString != 'configuration'">
            <span class="p-panel-title">{{container.data ? container.data['title'] || container.data['name'] : container.title || container.name}}</span>
        </div>
        <div class="ui-g" *ngIf="typeString == 'version'">
            <span class="p-panel-title">{{container.data['name'] ? container.data['name'] : "Version"}} {{container.data.number}}</span>
        </div>
        <div class="ui-g" *ngIf="typeString == 'configuration'">
            <span class="p-panel-title">{{container.data['name'] ? container.data['name'] : "Config"}} {{container.data.version}}</span>
        </div>
       
    </p-header>
    <div class="ui-g">
            
        <div *ngIf="appContext.activeOrigin !== container.origin" class="ui-g-4 ">
                <div class="label label-warning">{{container.origin}}</div></div>
        <div *ngIf="container.data && container.data['source_status']" class="ui-g-4">
            <div class="label label-info">Pinned</div>
        </div>
        <img *ngIf="thumbnailUrl()" class="ui-g-12 thumbnail" [src]="thumbnailUrl()" style="padding-bottom:0;">
        <div *ngIf="!thumbnailUrl() && typeString != 'application' && typeString != 'distribution' && typeString != 'version' && typeString != 'configuration' && typeString != 'view' && typeString != 'collection' && typeString != 'season'" class="ui-g-12 thumbnail" style="padding-bottom:0;"></div>
        <div *ngIf="typeString == 'application'" class="ui-g-12">
            <div class="ui-g-12" style="padding:0;">
                <div *ngIf="container.containers.length > 0" class="ui-g-12 label label-success">{{container.containers.length}} Distribution(s)</div>
                <div *ngIf="container.containers.length <= 0" class="ui-g-12 label label-danger">{{container.containers.length}} Distribution(s)</div>
            </div>
        </div>
        <div *ngIf="container.data['show_title'] || container.data['keywords']" class="ui-g-12">
            <div class="ui-g-12" style="padding-left:0;padding-right:0;padding-bottom:0;">
                <div *ngIf="container.data['show_title']" class="ui-g-12 label label-primary">{{container.data['show_title']}}</div>
                <!-- TODO: only show keywords for collections -->
                <div *ngIf="!container.data['show_title']" class="ui-g-12 label label-warning">{{container.data['keywords']}}</div>
            </div>
        </div>
        <div *ngIf="typeString != 'application' && typeString != 'episode'" class="ui-g-12" style="padding-bottom:0;">
            <div class="ui-g-12 label label-default">{{typeString}}</div>
        </div>
        <div class="ui-g-12">
            <div class="ui-g-12 label" [ngClass]="statusLabelClass()">{{ container.status | titlecase }}</div>
        </div>
    </div>
</p-panel>

<p-toolbar *ngIf="isProcessing">
        <div class="ui-g p-toolbar-group-right">
            <div class="ui-g" *ngIf="isProcessing" >
                <p-progressSpinner [style]="{width: '25px', height: '25px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
</p-toolbar>

<p-toolbar *ngIf="!isProcessing">
    <div class="ui-g">
        <div class="ui-g-6" *ngIf="container.origin === appContext.activeOrigin && (typeString == 'application' || typeString == 'distribution' || typeString == 'worker' || typeString == 'version' || typeString == 'configuration' ||  typeString == 'view' || typeString == 'channel' || typeString == 'series' || typeString == 'episode' || typeString == 'season' || typeString == 'media' || typeString == 'collection' || typeString == 'rotator' || typeString == 'pipeline' || typeString == 'role')">
            <button *ngIf="typeString == 'application'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/applications', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'distribution'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/distributions', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'version'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/versions', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'configuration'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/configurations', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'view'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/view', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'channel'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/channels', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'series'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/series', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'episode'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/episodes', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'season'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/season', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'media'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/media', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'collection'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/collection', container.id, childContainer.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'pipeline'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/pipelines', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'worker'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/workers', container.id]" icon="pi pi-pencil"></button>
            <button *ngIf="typeString == 'role'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/roles', container.id]" icon="pi pi-pencil"></button>
            <!-- <button *ngIf="typeString == 'rotator'" label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/collection', container.id, childContainer.id]" icon="pi pi-pencil"></button> -->
        </div>
        <div class="ui-g-6" *ngIf="container && container.origin === appContext.activeOrigin" style="padding-left:0">
            <button label="Remove" class="p-button-danger panel-button" pButton type="button" (click)="remove($event, container)" icon="pi pi-times"></button>
        </div>
        <div class="ui-g-6" *ngIf="typeString == 'version' || typeString == 'configuration'">
            <container-operations [containerId]="container.id" [parentContainerId]="parentContainer.id" (onOperationComplete)="update($event)"></container-operations>
        </div>
        
    </div>
</p-toolbar>
