<div class="card card-w-title top-level-card">
    <h1>Edit Content Group{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>
    
    <page-header title="Edit Content Group{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
     
    <div *ngIf="container && !isLoading" class="ui-g card-content">
        
        <div class="ui-g-12 ui-g-nopad">
            <p-toast key="ceMsg"></p-toast>
            <content-group-form  [container]="container" 
                                 (formSubmit)="onSubmit($event)" 
                                 (formCancel)="onCancel($event)"
                                 (formFailed)="onFailure($event)">

                <div class="ui-g-12 ui-md-5 segment-moment-wrap">
                    <div class="segment-elm">
                        <segment-media-table *ngIf="segmentKeywords && segmentOrigins && !(isLoading || isReLoading)"
                                             [rows]=10
                                             [segmentKeywords]="segmentKeywords"
                                             [segmentOrigins]="segmentOrigins"
                                             (rowSelect)="handleMediaRowSelect($event)">
                        </segment-media-table>
                    </div>
                    <div class="moment-elm">
                        <spinner *ngIf="isMomentLoading"></spinner>
                        <moment-table *ngIf = "container && !isMomentLoading" 
                                      [rows]=10
                                      [inputContainer]="container"
                                      [showRemoveControls]="true"
                                      (momentRemove)="handleMomentRemove($event)"
                                      (rowSelect)="handleMomentRowSelect($event)">
                        </moment-table>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-7">
                    <segment-timeline-player *ngIf="selectedMediacontainer && !isLoading"
                                            (onSaveMoment)="createMoment($event)">
                    </segment-timeline-player>
                </div>
            </content-group-form>

        <hr>

        <div class="ui-g-12">
            <object-advancedview  *ngIf="!isLoading" [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
    
</div>