<ngx-charts-polar-chart
  [scheme]="colorScheme"
  [legend]="legend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [results]="formattedDataNGX"
  (select)="onSelect($event)"
  [view]="view">
</ngx-charts-polar-chart>