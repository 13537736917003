<div class="card card-w-title top-level-card">
        <h1>Roles and Permissions</h1>
    
        <p-table #dt [value]="roleContainers" 
                [rows]="10" 
                [paginator]="false" 
                paginatorPosition="bottom" 
                selectionMode="single" 
                (onRowSelect)="onRowSelect($event)" 
                [(selection)]="container"
                styleClass="p-datatable-gridlines"
                [responsive]="true">
            <ng-template pTemplate="caption">
                <button pButton type="button" label="Create" [routerLink]="['/roles', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
                <spinner *ngIf="rolesLoading"></spinner>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:20%">Name</th>
                    <th style="width:20%">Mapping Id</th>
                    <th>Permissions</th>
                    <th style="width:6em"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
                <tr>
                    <td>{{role.data.name}}</td>
                    <td>{{role.data.mapping_id}}</td>
                    <td>
                        <div style="padding:0.5em;overflow:hidden;">
                            <span *ngFor="let view of role.data['permissions']['view']['show']" style="margin-right:10px"> 
                                <div class="label label-success"> {{view}}</div>
                            </span>
                            <span *ngFor="let object of role.data['permissions']['object']['write']" style="margin-right:10px">
                                <div class="label label-primary"> {{object}}</div>
                            </span>
                            <span *ngFor="let api of role.data['permissions']['api']['crud']" style="margin-right:10px">
                                <div class="label label-warning"> {{api}}</div>
                            </span>
                            <span *ngIf="role.data['permissions']['api']['read']">
                                <span *ngFor="let api of role.data['permissions']['api']['read']" style="margin-right:10px">
                                    <div class="label label-default"> {{api}}</div>
                                </span>
                            </span>
                            <span *ngIf="role.data['permissions']['pipeline'] && role.data['permissions']['pipeline']['type']">
                                <span *ngFor="let pipelineType of role.data['permissions']['pipeline']['type']" style="margin-right:10px">
                                    <div class="label label-info"> {{pipelineType}}</div>
                                </span>
                            </span>
                            <span *ngIf="role.data['permissions']['pipeline'] && role.data['permissions']['pipeline']['action']">
                                <span *ngFor="let pipelineAction of role.data['permissions']['pipeline']['action']" style="margin-right:10px">
                                    <div class="label label-info"> {{pipelineAction}}</div>
                                </span>
                            </span>
                        </div>
                    </td>
                    <td>
                        <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/roles', role.id]"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>


