import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'report-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'report-form.component.html'
})
export class ReportFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;
    private status: any;
    private isProcessing = false;

    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    // Currently advisory-only, should match query transforms
    public readonly subtypeList = [
        { label: 'Key/values', value: 'key_values' },
        { label: 'Columns', value: 'columns' },
        { label: 'Containers', value: 'containers' },
    ];
    // Add to list when new options become available in content service
    public readonly queryDomainList = [
        { label: 'Realtime service', value: 'realtime_service' },
        { label: 'Django queryset', value: 'django_queryset' },
        { label: 'Compound', value: 'compound' },
    ];
    // Add to list when new options become available in content service
    public readonly queryTransformList = [
        { label: 'Label/value', value: 'label_value_list_transform' },
        { label: 'Column values', value: 'column_values_transform' },
        { label: 'Container GUIDs', value: 'container_guids_transform' },
    ];
    // Add to list when new options become available in report rendering
    public readonly renderTypeList = [
        { label: 'Advanced pie graph', value: 'advanced_pie_graph' },
        { label: 'Advanced pie grid', value: 'advanced_pie_grid' },
        { label: 'Horizontal bar graph', value: 'horizontal_bar_graph' },
        { label: 'Line graph', value: 'line_graph' },
        { label: 'Number cards', value: 'number_cards' },
        { label: 'Polar graph', value: 'polar_graph' },
    ];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        if (!this.container.data['query_domain']) {
            this.container.data['query_domain'] = "realtime_service";
        }
        if (!('query_path' in this.container.data)) {
            this.container.data['query_path'] = "/metrics/session/";
        }
        if (!('description' in this.container.data)) {
            this.container.data['description'] = "";
        }

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'report'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('label' in this.container.data) || (!this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the profile name.' });
            valid = false;
        }
        if (!('bundle_id' in this.container.data) || (!this.container.data['bundle_id']) || !/\S/.test(this.container.data['bundle_id'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the bundle id.' });
            valid = false;
        }
        if (!('report_type' in this.container.data) || (!this.container.data['report_type']) || !/\S/.test(this.container.data['report_type'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the bundle id.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

}
