
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tag } from '../../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { AppContext } from '../../../app.context';

@Injectable()
export class TagService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private appContext: AppContext,
    ) {}

    get(id): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/tags/${id}/`, { headers: headers }
            );
    }

    getTagsByContainer(containerId): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        containerId = encodeURIComponent(containerId);

        return this.http
            .get(
                `${this.baseUrl}/containers/${containerId}/tags/`, { headers: headers }
            );
    }

    getTagsFromContainerRelationships(containerId): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        containerId = encodeURIComponent(containerId);

        return this.http
            .get(
                `${this.baseUrl}/containers/${containerId}/relationship/tags/`, { headers: headers }
            );
    }

    list(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/tags/`, { headers: headers }
            ).pipe(
            map(
                (res: Array<any>) => {
                    // Only show tags from system and current origin
                    let origins = new Set(['system']);
                    origins.add(this.appContext.activeOrigin);

                    return res.filter(t => origins.has(t['origin'])).reverse();
                },
            ));
    }
    

    save(tag: Tag, parentContainerId): Observable < any > {
        console.log('tag service save', tag);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        // headers.append('Accept',       'application/vnd.corus.api.core.services.proxy+json');

        let resp;
        let data = JSON.stringify({
            value: tag.value,
        });

        // create new resource
        if (tag.id === -1) {
            resp = this.http.post(
                `${this.baseUrl}/containers/${parentContainerId}/tags/`,
                data, { headers: headers }
            );
        } else {
            resp = this.http.put(
                `${this.baseUrl}/tags/${tag.id}/`,
                data, { headers: headers }
            );
        }

        return resp;
    }

    saveTagOnRelationship(tag: Tag, parentContainerId, childContainerId, type): Observable < any > {
        console.log('tag service save', tag);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        // headers.append('Accept',       'application/vnd.corus.api.core.services.proxy+json');

        let resp;
        let data = JSON.stringify({
            value: tag.value,
        });

        // create new resource
        if (tag.id === -1) {
            resp = this.http.post(
                `${this.baseUrl}/containers/${parentContainerId}/${type}/${childContainerId}/tags/`,
                data, { headers: headers }
            );
        } else {
            resp = this.http.put(
                `${this.baseUrl}/tags/${tag.id}/`,
                data, { headers: headers }
            );
        }

        return resp;
    }

    delete(tag: Tag): Observable < any > {
        console.log('tag service delete', tag);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/tags/${tag.id}/`, { headers: headers }
            );
    }

    deleteById(tagId): Observable < any > {
        console.log('tag service delete by id:', tagId);

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/tags/${tagId}/`, { headers: headers }
            );
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }


    getResourceTags(): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/resourcetags/`, { headers: headers }
            );
    }

    getTagsByResource(resourceId): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
            resourceId = encodeURIComponent(resourceId);

        return this.http
            .get(
                `${this.baseUrl}/resources/${resourceId}/tags/`, { headers: headers }
            );
    }
}
