<p-panel>
    <p-header>
            <span class="p-panel-title ui-g-12 ui-md-6 ui-lg-4 view-title" style="padding:0;">Media</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <div style="float:right;">
                <p-calendar
                    [(ngModel)]="rangeDates"
                    [showTime]="false"
                    [inline]="false"
                    [showIcon]="true"
                    appendTo="body"
                    placeholder="Select Date Range"
                    selectionMode="range"
                    (onSelect)="handleDateSelect()">
                </p-calendar>
            </div>
            <!--
            <spinner [hidden]= "!isLoading"></spinner>
            -->
    </p-header>

    <p-table [value]="episodeContainers"
             [rows]="rows"  
             selectionMode="single" 
             dataKey="guid"
             [lazy]="true"
             (onLazyLoad)="loadDataOnScroll($event)"
             [paginator]='true'
             paginatorPosition="bottom"
             (onRowSelect)="onRowSelect()" 
             [(selection)]="container"
             styleClass="p-datatable-gridlines p-datatable-nested"
             [totalRecords]="totalRecords">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:17%">Origin</th>
                <th style="width:33%">Title</th>
                <!--<th style="width:18%">Status</th>-->
                <th style="width:50%">Relevance</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-media>
            <tr [pSelectableRow]="media">
                <td style="width:17%;word-break:break-all;">{{media.origin}}</td>
                <td style="width:33%;"><strong>{{media.data.show_title}}</strong><br>{{media.label}}</td>
                <!--<td style="width:18%;word-break:break-all;text-align:center"><div class="label" [ngClass]="statusLabelClass(media.status)">{{media.status|titlecase}}</div></td>-->
                <td style="width:50%;text-align"><contextual-ads-graph *ngIf ="transformedData && objectLength > 0" [inputData] = "transformedData[media.guid]"></contextual-ads-graph></td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>