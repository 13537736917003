import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from '../../models';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'container-add',
  templateUrl: './container-add.component.html',
  styleUrls: ['./container-add.component.css']
})
export class ContainerAddComponent implements OnInit {
    @Input() container: Container;
    @Input() defaultAddAction: string;
    @Input() addActionsList: any;

    public containerAddTypes: MenuItem[] = [];
    public defaultLabel: string = ''; 

    constructor(
        private router: Router
    ) {}

    ngOnInit() {

        this.defaultLabel = this.capitalize(this.defaultAddAction);

        this.addActionsList.forEach(action => {
            this.containerAddTypes.push({label: this.capitalize(action), icon: 'fa-plus', routerLink: ['/containers', this.container.id, 'relate', action]})
        })
        
    }

    addDefaultContainer() {
        return this.router.navigate(['/containers', this.container.id, 'relate', this.defaultAddAction]);
    }

    capitalize(word) {

        let new_word = word
            .split('_')
            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');

        return new_word;
    
    }

}
