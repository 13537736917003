<page-header [showSpinner]="isLoading" [error]="error?.statusText">
</page-header>


<div *ngIf="!isLoading" class="card card-w-title top-level-card">
    <h1>Metrics Reports</h1>
    <div class="ui-g card-content" >
        <div class="ui-g-12 ui-md-3 ui-lg-2 ui-g-nopad worklist-table-view">
            <metrics-reports-list (onReportSelected)="containerSelected($event)"></metrics-reports-list>
        </div>
        <div class="ui-g-12 ui-md-9 ui-lg-10" *ngIf="selectedContainer">
            <metrics-report-panel  [selectedContainer]="selectedContainer"></metrics-report-panel>
        </div>
    </div>
</div>
