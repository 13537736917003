import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of as observableOf, combineLatest as observableCombineLatest, Observable , BehaviorSubject } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthLoginData, AuthProvider } from './auth.types';
import { TokenAuthProvider } from './auth.provider.token';
import { CognitoAuthProvider } from './auth.provider.cognito';
import { allPermGroups } from './auth.permissions';

type AuthProviderMap = {
    [providerName: string]: AuthProvider;
}
type AuthProviderMapReadonly = Readonly<AuthProviderMap>;

@Injectable()
export class AuthService {
    private http : HttpClient;
    private router: Router;
    private permissionService: NgxPermissionsService;
    private authProviderMap: AuthProviderMapReadonly;
    private loginData: BehaviorSubject<AuthLoginData>;
    private userData: BehaviorSubject<any>;
    private permsByOrigin: Object;

    // Public observable versions for login and user state
    public loginState: Observable<AuthLoginData>;
    public userState: Observable<any>;

    // Private constants
    private baseUrl = environment.PROXY_API_BASE_URL;
    private userUrl = '/auth/user/';
    private defaultAuthProvider = 'cognito';
    private defaultRedirectUrl = '/featured';
    private redirectUrlName = 'auth_redirect_url';
    private lastProviderName = 'auth_last_provider';

    constructor(
        http: HttpClient,
        router: Router,
        permissionService: NgxPermissionsService
    ) {
        console.log('Auth service initializing');
        this.http = http;
        this.router = router;
        this.permissionService = permissionService;
        this.permsByOrigin = {};
        // Instantiate provider instances
        this.authProviderMap = {
            token: new TokenAuthProvider(http, router),
            cognito: new CognitoAuthProvider(http, router)
        };
        // Login state observable stuff
        this.loginData = new BehaviorSubject(null);
        this.loginState = this.loginData.asObservable();
        // Collate the various providers
        observableCombineLatest(
                this.authProviderMap.token.loginState,
                this.authProviderMap.cognito.loginState,
                (...loginStates) => {
                    // First provider with a current authorization header
                    return loginStates.find(p => !!p && !!p.header) || null;
                }
            )
            .subscribe(this.loginData);
        // And handle the combined pipeline
        this.userData = new BehaviorSubject({});
        this.userState = this.userData.asObservable();
        this.loginData.pipe(
            tap(        // Log, and update last-used provider
                (res) => {
                    console.log('Login data:', res);
                    const authProvider = (res && res.provider) || '';
                    if (authProvider) {
                        console.log(`Logged in via ${authProvider}`);
                        // Set last-used provider name
                        localStorage.setItem(this.lastProviderName, authProvider);
                    } else {
                        console.log(`Logged out of all providers`);
                    }
                },
                (err) => {
                    console.error('Login provider error:', err);
                    // TODO: trigger some indicator to refresh?
                },
                () => console.log('Combined login observable completed'),
            ),
            switchMap( // Get user info from API
                (res) => {
                    if (!res) {
                        return observableOf({});
                    }
                    // Fetch logged-in user info
                    const headers = new HttpHeaders()
                        .append('Accept', 'application/json')
                        .append('Authorization', this.getAuthHeader());

                    // Make sure we don't kill the stream
                    return this.http
                        .get(`${this.baseUrl}${this.userUrl}`, { headers }).pipe(
                        catchError(
                            (err) => {
                                console.error('User error:', err);
                                return observableOf({});
                            }
                        ));
                }
            ),
            tap(
                (user) => {
                    // Mostly for debugging
                    console.log('User:', user);
                    // Update permissions when user changes
                    this.parsePermissions(user);
                    console.log('Permissions:', this.permsByOrigin);
                    // this.permissionService.loadPermissions(permList);
                }
            ),)
            .subscribe(this.userData);
    }

    private get enabledAuth () {
        // Take from latest login provider data
        return this.loginData.value ? this.loginData.value.provider : '';
    }

    private get enabledAuthHeader () {
        // Take from latest login provider data
        return this.loginData.value ? this.loginData.value.header : 'None';
    }

    private getAuth(authProvider: string) {
        return this.authProviderMap[authProvider];
    }

    private getEnabledAuth(required = false) {
        const auth = this.enabledAuth ? this.getAuth(this.enabledAuth) : null;
        // If no enabled provider, possibly throw
        // TODO: check isAuthenticated() as well?
        if (required && !auth) {
            throw new Error('Not currently authenticated.');
        }
        return auth;
    }

    private parsePermissions(user : object = {}) {
        // const permList = [];
        this.permsByOrigin = {};
        const permObj = user['permissions'] || {};
        // Default getter
        function getPerms (perms) {
            return Array.isArray(perms) ? perms : Object.keys(perms)
        }
        // Flatten all permission strings
        for (let [origin, originObj] of Object.entries(permObj)) {
            const permList = [];
            for (let [kind, groups] of Object.entries(originObj)) {
                // Special-case superuser, since backend can possibly
                // have no canonical list of groups for a given kind
                // (currently only applies to 'view')
                if (groups.superuser === true) {
                    groups = allPermGroups[kind];
                    // console.log(groups);
                }
                for (let [group, perms] of Object.entries(groups)) {
                    permList.push(...getPerms(perms).map(p => `${kind}.${group}.${p}`));
                }
            }
            this.permsByOrigin[origin] = permList;
        }
        // return permList;
    }

    isValidProvider(authProvider: string) : boolean {
        return !!this.getAuth(authProvider);
    }

    isAuthenticated() : boolean {
        const auth = this.getEnabledAuth();
        return !!auth && auth.isAuthenticated();
    }

    getAuthenticatedUser() : Object {
        return this.userData.value;
    }

    getAuthenticatedUserName() : string {
        const user = this.getAuthenticatedUser();
        return (user && user['username']) || '';
    }

    getAuthHeader() : string {
        const auth = this.getEnabledAuth(true);
        // Check isAuthenticated() here, and throw if not?
        // Or check and force logout if not?
        return auth ? this.enabledAuthHeader : 'None';
    }

    getAuthFullName(authProvider: string) : string {
        const auth = this.getAuth(authProvider);
        return auth ? auth.providerNameFull : 'None';
    }

    getLoginUrl() {
        // Check for last used login method
        // Otherwise return configured default
        const lastProvider = localStorage.getItem(this.lastProviderName);
        return this.getAuth(lastProvider || this.defaultAuthProvider).loginUrl;
    }

    getRedirectUrl() {
        const redirectUrl = localStorage.getItem(this.redirectUrlName);
        return redirectUrl || this.defaultRedirectUrl;
    }

    setRedirectUrl(value: string) {
        if (value) {
            localStorage.setItem(this.redirectUrlName, value);
        } else {
            localStorage.removeItem(this.redirectUrlName);
        }
    }

    goToRedirectUrl() {
        const redirectUrl = this.getRedirectUrl();
        console.log(`Redirecting to ${redirectUrl}`);
        this.setRedirectUrl('');
        this.router.navigate([redirectUrl]);
    }

    setPermissionsOrigin(origin) {
        let permList = this.permsByOrigin[origin] || [];
        this.permissionService.loadPermissions(permList);
    }

    login(authProvider: string, details: Object) : Observable<any> {
        const auth = this.getAuth(authProvider);
        if (!auth) {
            throw new Error(`Invalid authentication provider: ${authProvider}`);
        }
        // Log out of existing if current enabled differs from requested
        if (this.enabledAuth && this.enabledAuth !== authProvider) {
            this.logout();
        }
        // Forward login attempt to provider
        console.log(`Logging in via ${authProvider}`);
        return auth.login(details);
    }

    logout(redirect: boolean = false) {
        const auth = this.getEnabledAuth();
        // May redirect or do other things...
        if (auth && auth.isAuthenticated()) {
            auth.logout(redirect);
        } else if (redirect) {
            this.router.navigate([this.getRedirectUrl()]);
        }
    }
}
