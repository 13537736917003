<page-header title="Edit Profile{{!isLoading && container ? ': ' + container.data['name'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>

<div class="card card-w-title">
    <h1>Edit Profile{{!isLoading && container ? ': ' + container.data['name'] : ''}}</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <profile-form #form class="ui-g-12" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formChangesScrapped)="onScrap($event)" (formCancelled)="onBack($event)"></profile-form>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
