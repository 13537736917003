import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentGroupService } from '../../services/content-group.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'contextual-ads-graph',
  templateUrl: 'contextual-ads-graph.component.html',
})

export class CAGraphComponent implements OnInit, OnDestroy {

    @Input() inputData: any;
    private cgState: any;
    private destroy$ = new Subject();
    public skipPlay = true;

    view: any[] = [120, 60];

    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = false;
    yAxis: boolean = false;
    showYAxisLabel: boolean = false;
    showXAxisLabel: boolean = false;
    xAxisLabel: string = '';
    yAxisLabel: string = '';
    timeline: boolean = false;

    colorScheme = {
      domain: ['#E53A74', '#F76C40', '#F7A224', '#F8C428', '#F7E758','#FFFFFF']
    };
    public data: any;

    constructor(
      private cgService: ContentGroupService,
    ) {}

    ngOnInit() {
        this.data = this.inputData;
    }

    ngAfterViewInit(): void {
      this.destroy$.next(false);
      this.createCgSub();
    }

    ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.complete();
    }

    private createCgSub(): void {
      this.cgService.getCgState().pipe(takeUntil(this.destroy$))
          .subscribe((cgState) => {
              this.cgState = cgState;
          });
    }

    onSelect(event) {
      this.cgService.updateCgState({
        'graphTimecode': event.name,
        'skipPlay': true,
        'source': 'contextual-ads-graph',
      });
    }

}