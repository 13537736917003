import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { CASegmentKeywordComponent } from '../../components/segment-keyword-autocomplete/segment-keyword-autocomplete.component'
import { OriginSelectComponent } from '../../components/origin-select/origin-select.component';
import { CASegmentMediaTableComponent } from '../../components/segment-media-table/segment-media-table.component';
import { Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { AppContext } from '../../../app.context';


@Component({
    selector: 'content-group-form',
    providers: [
        EntityTypeService,
    ],
    templateUrl: 'content-group-form.component.html'
})
export class CAContentGroupFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    private entityTypeSrvSubscr: Subscription;
    public isLoading = false;
    public msgs: Message[] = [];
    private msgDetail = [];

    @ViewChild(CASegmentKeywordComponent)segmentKeywordComponent: CASegmentKeywordComponent;
    @ViewChild(OriginSelectComponent)segmentOriginComponent: OriginSelectComponent;
    @ViewChild(CASegmentMediaTableComponent) mediaTableComponent;


    constructor(
        private entityTypeService: EntityTypeService,
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;
        this.isLoading = true;
        this.entityTypeSrvSubscr = this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'content_group'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    ngOnDestroy(): void {
        this.entityTypeSrvSubscr.unsubscribe();
    }

    // onTypeChange() {
    //     console.log('type changed', this.entityTypeId);
    //     this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    // }

    onSubmit(e) {

        this.msgs = [];
        this.msgDetail = [];

        let valid = null;
        //let segmentKeywords = this.segmentKeywordComponent.selectedKeywords;
        let segmentKeywords = this.container['data']['segment_keywords'];
        let segmentOrigins =  this.segmentOriginComponent.selectedOrigins;

        let keywordsMissing = (!segmentKeywords || segmentKeywords.length < 1);
        let originsMissing =  (!segmentOrigins || segmentOrigins.length < 1);

        if (keywordsMissing) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please choose at least one keyword.' });
            valid = false;
        }
        if (originsMissing) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please choose at least one origin.' });
            valid = false;
        }
        if (!keywordsMissing && !originsMissing) {
            valid = this.ngForm.form.status === 'VALID';
        }
        if (!('label' in this.container.data) || !(this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the content group label.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            //this.container['data']['segment_keywords'] = this.segmentKeywordComponent.selectedKeywords;
            this.container['data']['origins'] = this.segmentOriginComponent.selectedOrigins;

            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }

    onStatusChange(e) {
        console.log(e);
    }

    keywordsOriginsWarning(event) {
        if (!this.msgDetail.includes(event.requiredmsgs.detail)) {
            this.msgs = [];
            this.msgDetail.push(event.requiredmsgs.detail);
            this.msgDetail.forEach((msg) => {
                this.msgs.push({ severity: 'warn', summary: 'Warning', detail: msg });
            });
        }
    }

}
