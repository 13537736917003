
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { ResourceUploadService } from '../../../upload/resource-upload.service';
import { PipelineService } from '../../../pipeline/pipeline.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription,  Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContainerTagsTabComponent } from '../../../content/components/container-tags-tab/container-tags-tab.component';
import { UploadService } from '../../../upload/upload.service';
import { CASegmentKeywordComponent } from '../../components/segment-keyword-autocomplete/segment-keyword-autocomplete.component'


@Component({
    selector: 'contextual-ads-campaign-form',
    providers: [
        ContainerService,
        UploadService,
    ],
    templateUrl: 'contextual-ads-campaign-form.component.html',
    styleUrls: ['contextual-ads-campaign-form.component.css']
})
export class CACampaignsFormComponent implements OnInit {
    @Input() containerId: Number;
    @Input() seasons: Container[];
    @Output() onContainerUpdate: EventEmitter < any > = new EventEmitter();
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancel: EventEmitter < any > = new EventEmitter();

    public container: Container;
    private templateContainer: Container;

    private error: any;
    public isLoading = false;

    publishDate: Date;

    private tagTypeFilterList = [];
    private tagTypeHiddenList = [];

    private keywordChips: string[];


    public containerStatus: string;

    public msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService,
        public containerService: ContainerService,
        private authService : AuthService,

    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['containerId'].previousValue != changes['containerId'].currentValue && this.containerId) {
            console.log('unsub');
            this.msgs = [];

            this.refetchContainer();
        }
    }

    loadContainerData(id) {
        this.isLoading = true;
        this.containerService
            .get(id, 'full', 0, 'none')
            .subscribe(
                res => {
                    this.container = res;
                    console.log(this.container);
                    this.containerService
                        .getRelatedByTypeAndGuidPaginated('content_group', this.container.guid, 1, 200)
                        .subscribe(
                            res => {
                                this.isLoading = false
                                this.container.containers = res;
                                console.log("content_group container", this.container.containers);
                            },
                            err => this.error = err,
                            () => this.isLoading = false

                        )
                },
                err => this.error = err,
                () => this.isLoading = false
            );

    }

    refetchContainer() {
        this.containerService
        .get(this.containerId)
        .subscribe(
            res => {
                console.log('res', res);
                this.container = res instanceof Container
                    ? res
                    : Container.fromJson(res);
                //this.container = res;
                this.containerStatus = this.container.status;
                this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

                this.loadContainerData(this.container.id)
                //if (this.container['data'] && 'keywords' in this.container['data']) {
                    //this.keywordChips = this.container['data']['keywords'].split(',').map(k => k.trim());
                //}


              //  this.onContainerUpdate.emit({
              //      container: this.container
              //  });
            }
        );
    }

    refetchResources(): Observable<any> {
        return this.containerService
                .get(this.containerId).pipe(
                tap(
                    res => {
                        let container = res instanceof Container
                            ? res
                            : Container.fromJson(res);
                        if (this.container && container.id == this.container.id) {
                            // Replace resource list
                            this.container.resources = container.resources;
                        }
                    }
                ));
    }

    ngOnInit() {

        //this.isLoading = true;
    }

/*
    filterKeywords(event) {
        let query = event.query;

        this.filteredKeywords = [];
        for(let i = 0; i < this.matchingKeywords.length; i++) {
            let keyw = this.matchingKeywords[i];
            if(keyw.label.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
                this.filteredKeywords.push(keyw);
            }
            console.log("KEYWORD Filtered: filter",this.filteredKeywords)
        }


    }

    */

    ngOnDestroy() {
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        }
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date =  new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    getContainerTagTypes() {
        return this.container.tags ? this.container.tags.map(tag => tag['type']) : [];
    }

    onSubmit(e) {
        console.log('container submitted', this.container);


        if (this.publishDate) {
            this.container.published_date = Math.trunc(this.publishDate.getTime() / 1000);
        }


        //if (this.keywordChips.length > 0) {
        //    this.container['data']['keywords'] = this.keywordChips.join(', ');
        //}

        this.msgs = [];

        if (!('label' in this.container.data) || !(this.container.data['label']) || !/\S/.test(this.container.data['label'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the label.' });
        }




        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                this.msgs = [];
                this.msgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                this.container = Object.assign(new Container(), this.container);
                this.onContainerUpdate.emit({
                    container: this.container
                });
            },
            err => {
                console.log(err);
            },
            () => this.isLoading = false
        );

       this.formSubmit.emit({
            container: this.container
        });

    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
        this.onContainerUpdate.emit({
            container: this.container
        });
    }

    updateContainerStatus(status: string) {
        this.container.status = status;
        this.containerService
        .save(this.container)
        .subscribe(
            res => {
                console.log(res);
                this.onContainerUpdate.emit({
                    container: this.container
                });
                this.isLoading = false
            },
            err => {
                console.error(err);
                this.msgs = [];
                this.msgs.push({ severity: 'error', summary: 'Error saving container status', detail: '' });
                this.isLoading = false
            },
            () => this.isLoading = false
        );
    }

    getThumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    onCancel(e) {
        this.formCancel.emit({
            container: this.container
        });
    }

    updateMainTags(newTags) {
        console.log(newTags);

    }


}
