<p-table #dt [columns]="columns"
        styleClass="p-datatable-gridlines"
        [value]="reportData">
    <ng-template pTemplate="summary">
        <div class="clearfix" style="text-align: left">
            <button type="button" pButton icon="fa fa-file-o" iconPos="left" label="Export CSV" (click)="dt.exportCSV()"
                style="margin-left: 0.5em;float:right;" class="p-button-success"></button>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
</p-table>