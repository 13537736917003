import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MetricsReportsListComponent } from './../metrics-reports-list/metrics-reports-list.component';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'metrics-reports-data-table',
    templateUrl: './metrics-reports-data-table.component.html',
    styleUrls: ['metrics-reports-data-table.component.css']
})
export class MetricsReportsDataTableComponent implements OnInit, OnDestroy {


    @Input() reportData: any;
    // formattedReportData: any[];
    columns: any[];
    constructor(
        private route: ActivatedRoute,
        private router: Router

    ) { }

    ngOnInit() {
        this.columns = [
            { field: 'name', header: 'Key' },
            { field: 'value', header: 'Value' },
        ];
        // this.formattedReportData = this.reportData;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('Data table', changes);
    }

    ngOnDestroy() {
    }

    onSubmit(e) {
    }

}
