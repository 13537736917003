import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'channel-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'channel-form.component.html'
})
export class ChannelFormComponent implements OnInit {
    @Input() container: Container;
    @Input() seasons: Container[];
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;

    private entityTypes = [];
    private entityTypeId;
    private selectedSeasonGuid;
    public isLoading = false;

    private msgs: Message[] = [];

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onTypeChange() {
        console.log('type changed', this.entityTypeId);
        this.container.type = this.entityTypes.find(t => t.id === parseInt(this.entityTypeId, 10));
    }

    onSubmit(e) {
        console.log('container submitted', this.container);

        this.msgs = [];

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the channel title.' });
        }

        this.formSubmit.emit({
            container: this.container
        });
    }

    onStatusComplete() {
        this.container.data['metadata_ingest_workflow_status'] = 'Complete';
        this.formSubmit.emit({
            container: this.container
        });
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }
}
