<div class="card card-w-title top-level-card">
    <h1>Reports</h1>
        <p-toolbar>
            <div class="ui-g">
                <div class="ui-g-4">
                    <input *ngIf="!collapsed" type="text" pInputText placeholder="Filter" (input)="dt.filterGlobal($event.target.value.toLowerCase(), 'contains')" style="text-align:center;height:100%;width:100%;"/>
                </div>
                <div class="ui-g-4">
                    <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
                </div>
                <div class="ui-g-4">
                    <button pButton type="button" label="Create" [routerLink]="['/reports', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
                </div>
                <spinner *ngIf="isLoading"></spinner>
            </div>
        </p-toolbar>
    <p-table [columns]="columns" #dt 
            [value]="containers" 
            [globalFilterFields]="['data.label', 'guid', 'data.bundle_id', 'data.report_type', 'data.category', 'data.subtype', 'status']" 
            [rows]="10" 
            [paginator]="true" 
            paginatorPosition="both" 
            dataKey="guid" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container" 
            [responsive]="true"
            styleClass="p-datatable-gridlines"
            expandableRows="true">
        <ng-template pTemplate="header" let-columns="columns">
            <tr>
                <th>Label</th>
                <th style="width: 9em">Category</th>
                <th>GUID</th>
                <th style="width: 6em">Report Type</th>
                <th style="width: 6em">Subtype</th>
                <th style="width: 6em">Status</th>
                <th style="width: 6em"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-report let-expanded="expanded" let-columns="columns">
            <tr *ngIf="!isLoading">
                <td style="word-break: break-all;">{{report.data.label}}</td>
                <td>{{report.data.category}}</td>
                <td style="word-break: break-all;">{{report.guid}}</td>
                <td><div class="label label-default">{{report.data.report_type}}</div></td>
                <td>{{report.data.subtype}}</td>
                <td><div class="label" [ngClass]="statusLabelClass(report.status)">{{report.status|titlecase}}</div></td>
                <td>
                    <div class="text-center">
                        <button pButton type="button" label="Edit" class="p-button-primary" [routerLink]="['/reports', report.id]" icon="pi pi-pencil"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
