import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'policy-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'policy-form.component.html'
})
export class PolicyFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;
    private status: any;
    private isProcessing = false;

    private policyType = ['access', 'protection', 'location'];

    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'policy'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );

            //this.selectedStatus = this.container.status;
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the policy title.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onCancel(e) {
        this.formCancelled.emit();
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }
    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    //onStatusChange(event) {
        //this.status = this.container.status;
        //this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    //}

}
