import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { ContainerService } from '../../services/container/container.service';
import { AuthService } from '../../../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Container } from '../../models/container';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';

@Component({
    selector: 'reparent-modal-form',
    providers: [],
    templateUrl: 'reparent-modal-form.component.html',
})
export class ReparentModalFormComponent implements OnInit {
    @ViewChild('ngForm') ngForm: NgForm;
    @Input() container: Container;
    @Output() reparenting: EventEmitter<any> = new EventEmitter();

    public displayDialog: boolean = false;
    public isSeriesLoading = false;
    public isSeasonLoading = false;
    public isPrimaryParentLoading = false;
    public isSecondaryParentLoading = false;
    private error: string;
    private isProcessing = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    private primaryParentContainer: Container;
    private secondaryParentContainer: Container;
    private parentContainer: Container;
    private seriesContainerId;
    private selectedSeries;
    private primaryParentGuid = '';
    private secondaryParentGuid = '';
    private series: any[] = [];
    private filteredSeriesList: any[] = [];
    private seriesStatusList = ['published', 'unpublished', 'scheduled', 'approved', 'ready', 'new'];
    private seasons: any[] = [];
    private filteredSeasonList: any[] = [];
    private selectedSeasonId;
    private selectedSeason;

    constructor(
        private entityTypeService: EntityTypeService,
        private route: ActivatedRoute,
        private router: Router,
        private containerService : ContainerService,
        private authService : AuthService,
    ) { }

    resetFormData() {

        if (this.selectedSeries) {
            if (this.selectedSeason) {
                this.selectedSeasonId = this.selectedSeason.id;
            }
            else {
                this.secondaryParentContainer = null;
                this.selectedSeasonId = '';
            }
        }
        else {
            this.primaryParentContainer = null;
            this.secondaryParentContainer = null;
            this.selectedSeasonId = '';
        }     
    }

   ngOnInit() {}

   fetchParentContainers() {
        this.containerService
        .getByGuid(this.primaryParentGuid, 'full', -1, false, true)
        .subscribe(
            res => {
                this.primaryParentContainer = res;
                this.selectedSeries = this.primaryParentContainer;
                if (this.selectedSeries) {
                    this.seriesContainerId = this.selectedSeries.id;
                }
                else {
                    this.seriesContainerId = '';
                }

                this.isPrimaryParentLoading = false;  
                this.getSeasonsData();
                this.isSecondaryParentLoading = true;
                if (this.secondaryParentGuid) {
                    this.containerService
                    .getByGuid(this.secondaryParentGuid, 'full', -1, false, true)
                    .subscribe(
                        res => {
                            this.secondaryParentContainer = res;
                            this.selectedSeason = this.secondaryParentContainer;
                            this.resetFormData();
                        },
                        err => console.log(err)
                    );
                }
                else {
                    this.resetFormData();
                }

                this.isSecondaryParentLoading = false;

            },
            err => console.log(err)
        );
    }

    showDialog() {

        this.displayDialog = true;
        this.getSeriesData();
        if (this.container.data['primary_parent_container_id']) {
            this.isPrimaryParentLoading = true;
            this.primaryParentGuid = this.container.data['primary_parent_container_id'];
            if (this.container.data['secondary_parent_container_id']) {
                this.secondaryParentGuid = this.container.data['secondary_parent_container_id'];
            }
            this.fetchParentContainers();
        }
        else {
            this.resetFormData();
        }  
    }

    closeDialog() {
        this.displayDialog = false;
    }

    relateContainers(from_container, to_container) {

        return this.containerService
            .relate(from_container, to_container, true)
            .toPromise()
            .then(
                res => {
                    console.log(res);

                },
                err => {
                    console.log(err);
                }
            );
    }

    unRelateContainers(from_container, to_container) {

        return this.containerService
            .unrelate(from_container, to_container)
            .toPromise()
            .then(
                res => {  
                    console.log(res);
                },
                err => {
                    console.log(err);
                }
            );
    }

    relateToPrimaryParent() {
        this.relateContainers(this.selectedSeries, this.container);
        this.container.data['primary_parent_container_id'] = this.selectedSeries.guid;
        this.container.data['show_title'] = this.selectedSeries.data['title'];
    }

    relateToSecondaryParent() {
        this.relateContainers(this.selectedSeason, this.container);
        this.container.data['secondary_parent_container_id'] = this.selectedSeason.guid;
        this.container.data['season_number'] = this.selectedSeason.data['season_number'];
    }


    async saveParents() {

        if(this.primaryParentContainer) {
            if (this.selectedSeries) {
                if (this.primaryParentContainer.guid != this.selectedSeries.guid) {
                    if (this.secondaryParentContainer) {
                        await this.unRelateContainers(this.secondaryParentContainer, this.container);
                        this.container.data['secondary_parent_container_id'] = '';
                        this.container.data['season_number'] = '';
                    }
                    if (this.selectedSeason) {
                        await this.relateToSecondaryParent();
                    }
                    
                    await this.unRelateContainers(this.primaryParentContainer, this.container);
                    this.container.data['primary_parent_container_id'] = '';
                    this.container.data['show_title'] = '';  
                    await this.relateToPrimaryParent();
                }
                else {
                    if (this.selectedSeason) {
                        if (this.secondaryParentContainer) {
                            if (this.secondaryParentContainer.guid != this.selectedSeason.guid) {
                                await this.unRelateContainers(this.secondaryParentContainer, 
                                    this.container);
                                await this.relateToSecondaryParent();
                            }
                            
                        } else {
                            await this.relateToSecondaryParent();
                        }
                    } else {
                        if (this.secondaryParentContainer) {
                            await this.unRelateContainers(this.secondaryParentContainer, 
                                this.container);
                            this.container.data['secondary_parent_container_id'] = '';
                            this.container.data['season_number'] = '';
                        }
                        
                    }
                }
            }
            else {
                if (this.secondaryParentContainer) {
                   await this.unRelateContainers(this.secondaryParentContainer, this.container);
                   this.container.data['secondary_parent_container_id'] = '';
                   this.container.data['season_number'] = '';
                }
                await this.unRelateContainers(this.primaryParentContainer, this.container);
                this.container.data['primary_parent_container_id'] = '';
                this.container.data['show_title'] = '';  
            }
        }else {
            
            await this.relateToPrimaryParent();
            if (this.selectedSeason){ 
                await this.relateToSecondaryParent();
            }
        }

        await this.saveData();
        let ev = {
            container: this.container,
        };

        this.reparenting.emit(ev);

        this.closeDialog();

    }

    saveData() {

        return this.containerService
            .save(this.container)
            .toPromise()
            .then(
                res => {
                    console.log(res);
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', 
                        detail: '' });
                },
                err => {
                    console.log(err);
                },
            );
    }

    getSeriesData() {
        
        this.isSeriesLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'series', this.seriesStatusList, ['title'])
            .subscribe(
                res => {
                    this.series = res.results;
                    this.filteredSeriesList = this.series;
                    this.isSeriesLoading = false;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    this.isSeriesLoading = false;
                },
                
            );
    }

    getSeasonsData() {
        this.isSeasonLoading = true;
        if (this.selectedSeries && this.selectedSeries.guid ) {
            this.containerService
                .getRelatedByTypeAndGuidPaginated('season', this.selectedSeries.guid, 1, 100)
                .subscribe(
                    res => {
                        this.seasons = res;
                        this.filteredSeasonList = this.seasons;
                        this.isSeasonLoading = false;

                    },
                    err => {
                        this.error = err;
                        console.error('Error fetching seasons:', err);
                        this.isSeasonLoading = false;
                    }
                );
        }
        else {
            this.seasons = [];
            this.isSeasonLoading = false;
        }
    }

    onSeriesChange(){
        this.selectedSeries = this.filteredSeriesList.find(t => t.id === parseInt(
            this.seriesContainerId, 10));
        this.selectedSeason = null;
        this.selectedSeasonId = '';
        this.getSeasonsData();


    }

    onSeasonChange() {
        this.selectedSeason = this.filteredSeasonList.find(t => t.id === parseInt(
            this.selectedSeasonId, 10));
    }

}