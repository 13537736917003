<button pButton type="button" label="Export" icon="pi pi-arrow-right" style="float:right; margin-left:4px;"
    (click)="showDialog()" [(disabled)]="isDisabled"> </button>

<p-dialog header="Export Catalogs" [modal]="true" [(visible)]="displayDialog" responsive="true">
    <spinner *ngIf="isLoading"></spinner>
    <div class="ui=g">
        <div class="ui-g-12">
            <label for="name">Select Catalog:</label>
            <select [(ngModel)]="selectedCatalogId" name="catalog" class="form-control"
                (ngModelChange)="onCatalogChange()">
                <option *ngFor="let catalog of catalogs" [value]="catalog.id">{{catalog.data.label}}</option>
            </select>
        </div>
    </div>
    <p-footer>
        <a id="link" [attr.href]="url" target="_blank" *ngIf="!isDisabled">
        <button type="button" pButton icon="pi pi-check" (click)="exportCatalog()" label="Export" class="p-button-success"></button>
        </a>
        <button type="button" pButton icon="fa fa-close" (click)="displayDialog=false" label="Close"
            class="p-button-secondary"></button>
    </p-footer>

    <p-dialog>