import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../container/container.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
import { DragulaService } from 'ng2-dragula';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'catalog-table',
    viewProviders: [
        DragulaService
    ],
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'catalog-table.component.html'
})
export class CatalogTableComponent implements OnInit {
    public containers = [];
    private isLoading: boolean = false;
    private displayDialog: boolean = false;
    public container: Container;

    private entityTypes = [];
    private entityTypeId;

    constructor(
        public containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.containerService
            .filterByTypeName('catalog', 'full', 0, true)
            .subscribe(
                res => {
                    this.containers = res;
                    console.log(res);
                },
                err => {
                    console.log(err);
                    // this.error = err.statusText;
                    this.isLoading = false;
                },
                () => this.isLoading = false
            );
    }

    onRowSelect(event) {
        this.displayDialog = true;
    }

    save() {
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    // this.displayDialog = false;
                },
                err => {
                    console.log(err);
                    this.displayDialog = false;
                },
                () => this.isLoading = false
            );
    }

    statusLabelClass(status) {
        return this.containerService.getStatusLabelClass(status);
    }
}
