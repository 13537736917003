import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { EntityType, Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import { PermissionGroup, PermissionGroups } from '../../../auth/auth.types';
import { apiPermGroup, objectPermGroup, viewPermGroup, pipelinePermGroup } from '../../../auth/auth.permissions';

@Component({
    selector: 'role-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'role-form.component.html'
})
export class RoleFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    // TODO: generalize to allow additional perm keys
    private viewPermissions: PermissionGroup = { 'show': [] };
    private objectPermissions: PermissionGroup = { 'write': [], 'read': [] };
    private apiPermissions: PermissionGroup = { 'crud': [], 'read': [] };
    private pipelinePermissions: PermissionGroup = { 'type': [], 'action': [] };
    
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;

    private msgs: Message[] = [];

    // Convert permission definitions into arrays for use in ngFor
    private readonly viewPermDefs: PermissionGroups = formatPermGroupDef(viewPermGroup);
    private readonly objectPermDefs: PermissionGroups = formatPermGroupDef(objectPermGroup);
    private readonly apiPermDefs: PermissionGroups = formatPermGroupDef(apiPermGroup);
    private readonly pipelinePermDefs: PermissionGroups = formatPermGroupDef(pipelinePermGroup);

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        if (!('permissions' in this.container.data)) {
            this.initPermissions();
        }

        this.getPermissions();

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'role'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the provider name.' });
            valid = false;
        }
        if (!('mapping_id' in this.container.data) || (!this.container.data['mapping_id']) || !/\S/.test(this.container.data['mapping_id'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the role mapping id.' });
            valid = false;
        }
        
        //this.container.data['permissions']['view']['show'] = ['settings'];
        this.setPermissions();

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    getPermissions() {
        let sourcePermissions = this.container.data['permissions'];
        for (let key of Object.keys(this.viewPermissions)) {
            if (sourcePermissions['view'] && sourcePermissions['view'][key]) {
                this.viewPermissions[key] = sourcePermissions['view'][key].slice();
            }
        }
        for (let key of Object.keys(this.objectPermissions)) {
            if (sourcePermissions['object'] && sourcePermissions['object'][key]) {
                this.objectPermissions[key] = sourcePermissions['object'][key].slice();
            }
        }
        for (let key of Object.keys(this.apiPermissions)) {
            if (sourcePermissions['api'] && sourcePermissions['api'][key]) {
                this.apiPermissions[key] = sourcePermissions['api'][key].slice();
            }
        }
        for (let key of Object.keys(this.pipelinePermissions)) {
            if (sourcePermissions['pipeline'] && sourcePermissions['pipeline'][key]) {
                this.pipelinePermissions[key] = sourcePermissions['pipeline'][key].slice();
            }
        }
    }

    setPermissions() {   
        this.container.data['permissions']['view'] = this.viewPermissions;
        this.container.data['permissions']['object'] = this.objectPermissions;
        this.container.data['permissions']['api'] = this.apiPermissions;
        this.container.data['permissions']['pipeline'] = this.pipelinePermissions;
    }

    initPermissions() {
        this.container.data['permissions'] = {
            'object': {
                'write': [],
                'read': [],
            },
            'view': {
                'show': [],
            },
            'api': {
                'crud': [],
                'read': [],
            },
            'pipeline': {
                'type': [],
                'action': [],
            },
        };
    }

    onChange(e) {
        console.log(e);
        this.setPermissions();
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}

function formatPermGroupDef (groupDef : Object) : PermissionGroups {
    return Object.entries(groupDef).reduce(
        (obj, [group, perms]) => Object.assign(obj, {
            [group]: Object.entries(perms).map(
                ([name, label]) => { return { name, label }; }
            )
        }), {}
    );
}
