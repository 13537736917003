
<p-dialog header="Upload Media" [modal]="true" [(visible)]="displayQuickUploadWindow" [responsive]="true" styleClass="dialog-wide">
 <upload-resource-simple-form *ngIf=displayQuickUploadWindow (formFailed)="closeQuickUploadDialog()" (formCancelled)="closeQuickUploadDialog()" (formSubmit)="closeQuickUploadDialog()"></upload-resource-simple-form>
</p-dialog>

<p-dialog header="Upload Media" [modal]="true" [(visible)]="displayDetailedUploadWindow" [responsive]="true" styleClass="dialog-tall-wide">
 <upload-media-form *ngIf="displayDetailedUploadWindow" (formFailed)="closeDetailedUploadDialog()" (formCancelled)="closeDetailedUploadDialog()" (formSubmit)="closeDetailedUploadDialog()"></upload-media-form>
</p-dialog>

<div *ngIf="app.isAuthenticated()" class="layout-sidebar" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}"
    (click)="app.onSidebarClick($event)">
  
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li [ngClass]="{'active-item':app.activeTabIndex === 0}">
                <a href="#" class="ripplelink tabmenuitem-link" [routerLink]="['']">
                    <i class="fa fa-fw fa-home"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Dashboard</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 1}" *ngxPermissionsOnly="['view.show.content']">
                <a href="#" class="tabmenuitem-link" (click)="app.onTabClick($event, 1)">
                    <i class="fa fa-th-list"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Content</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 6}" *ngxPermissionsOnly="['view.show.upload']">
                    <a class="tabmenuitem-link" (click)="showQuickUploadDialog()">
                        <i class="fa fa-upload"></i>
                    </a>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 2}" *ngxPermissionsOnly="['view.show.curate']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 2)">
                    <i class="fa fa-bookmark"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Curate Content</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 7}" *ngxPermissionsOnly="['view.show.commerce']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 7)">
                    <i class="fa fa-dollar"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Commerce</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 4}" *ngxPermissionsOnly="['view.show.metrics']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 4)">
                    <i class="fa fa-tachometer"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Metrics</div>
                </div>
            </li>
            <li [ngClass]="{'active-item':app.activeTabIndex === 5}" *ngxPermissionsOnly="['view.show.settings']">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, 5)">
                    <i class="fa fa-cogs"></i>
                </a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Settings</div>
                </div>
            </li>
        </ul>
        <div class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 0}">
                <div class="layout-submenu-title clearfix">
                    <span>DASHBOARD</span>
                </div>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 1}">
               
             
                <app-sidebarTabContent>
                    <div class="layout-submenu-title clearfix">
                        <span>CONTENT</span>
                    </div>
                    <app-menu [model]="this.contentMenu"></app-menu>
                    <div class="layout-submenu-title clearfix" *ngxPermissionsOnly="['view.show.create']">
                        <span>CREATE</span>
                    </div>
                    <app-menu [model]="this.createMenu" *ngxPermissionsOnly="['view.show.create']"></app-menu>
                    <div class="layout-submenu-title clearfix" *ngxPermissionsOnly="['view.show.upload']">
                    <span>UPLOAD</span>
                        </div>
                        <app-menu [model]="this.uploadMenu" *ngxPermissionsOnly="['view.show.upload']"></app-menu>       
                </app-sidebarTabContent>
                
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 2}">
                <div class="layout-submenu-title clearfix">
                    <span>CURATE</span>
                </div>
                <app-sidebarTabContent>
                    <app-menu [model]="this.newItemMenu"></app-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 7}">
                <app-sidebarTabContent>
                    <div class="layout-submenu-title clearfix">
                        <span>ADS</span>
                    </div>
                    <app-menu [model]="this.adsMenu"></app-menu> 
                    <div class="layout-submenu-title clearfix">
                        <span>CREATE</span>
                    </div>
                    <app-menu [model]="this.createCommerceMenu"></app-menu> 
                
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 4}">
                <div class="layout-submenu-title clearfix">
                    <span>METRICS</span>
                </div>
                <app-sidebarTabContent>
                    <app-menu [model]="this.metricsReportsMenu" *ngxPermissionsOnly="['view.show.reports']"></app-menu>
                    <app-menu [model]="this.metricsMenu"></app-menu>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === 5}">
                <app-sidebarTabContent>
                <div class="layout-submenu-title clearfix">
                    <span>SETTINGS</span>
                </div>
                
                    <app-menu [model]="this.configMenu"></app-menu>
                
                <div class="layout-submenu-title clearfix">
                        <span>CREATE</span>
                </div>
                <app-menu [model]="this.createSettingsMenu"></app-menu>
              </app-sidebarTabContent>
            </div>
        </div>
    </div>
</div>
