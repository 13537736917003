import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';


@Injectable()
export class MetricsService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/templates/reports`;
    private baseAPIUrl = `${environment.PROXY_API_BASE_URL}/api/container/v1/`;


    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    private constructParameterURLString(parameters = []) {        
        
        let urlParams = new URLSearchParams();

        parameters.forEach(param => {
            console.log(param,"parameterString")
            for (const [key, value] of Object.entries(param)){
            urlParams.append(key,param[key]);
            }
        })

        let parameterString = urlParams.toString(); 

        console.log(parameterString,"parameterString")
        return parameterString
    }
    



    getReportByGUID(guid): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/${guid}/`, { headers: headers }
            );
    }

    getReportByGUIDWithParameters(guid, parameters = []): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        let urlWithOptions =  `${this.baseUrl}/${guid}/` + '?' +this.constructParameterURLString(parameters);
        return this.http
            .get(
                urlWithOptions , { headers: headers }
            );
    }

    getContainerByGUIDArray(guids): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
            let paramsArr = '';
            guids.forEach(guid => {
                paramsArr = paramsArr + 'guid='+ guid +"&"
            })
            paramsArr = paramsArr + "status=published&status=unpublished&status=scheduled&status=hidden";
        return this.http
            .get(
                `${this.baseAPIUrl}?${paramsArr}`, { headers: headers }
            );
    }

    getContainerWithSearchParams(searchTerm, parameters = "", searchParam = "search"): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let paramsStr = '';
        if (!searchParam) {
            searchParam = 'search';
        }
        if (searchTerm) {
            paramsStr += `${searchParam}=${searchTerm}&`;
        }
        if (parameters) {
            paramsStr += parameters;
        }

        return this.http
            .get(
                `${this.baseAPIUrl}?${paramsStr}`, { headers: headers }
            );
    }

    getContainerWithParams(parameters = ""): Observable < any > {
        return this.getContainerWithSearchParams('', parameters);
    }

}

