import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContainerService } from '../../services/container/container.service';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerRelateFormComponent } from '../../forms/container-relate-form/container-relate-form.component';
import { Container } from '../../models';

@Component({
    selector: 'container-relate',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-relate.component.html',
    styleUrls: ['container-relate.component.css']
})
export class ContainerRelateComponent implements OnInit, OnDestroy {
    private sub: any;
    public container: Container;
    private isLoading = false;
    public error: string;
    public containerFilterType: string;
    public containerDisplayType: string;
    public processing = false;
    private progress = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            this.containerFilterType = params['type'] || '';
            this.containerDisplayType = this.containerFilterType.replace('_', ' ');

            console.log('id', id);
            console.log('containerFilterType', this.containerFilterType);

            this.isLoading = true;
            this.containerService
                .get(id, 'flat', -1)

                
                .subscribe(
                    res => {
                        this.container = res;
                        this.isLoading = false;
                    },
                    err => {
                        console.log(err);
                        this.error = err.statusText;
                    }
                );
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        console.log('relate submit heard', e);
        this.processing = true;
        this.progress = 0;
        this.submitAllContainersRecursively(e.containers, 0, () => {
            this.location.back();
        });
    }

    submitAllContainersRecursively(containers, currentIndex, onDone) {
        this.containerService
            .relate(this.container, containers[currentIndex])
            .subscribe(
                res => {
                    console.log(res);
                    this.progress = ((currentIndex + 1) / containers.length) * 100;
                    if (currentIndex < containers.length - 1) {
                        console.log('Next relation...');
                        this.submitAllContainersRecursively(containers, currentIndex + 1, onDone);
                    } else {
                        console.log('Finished');
                        onDone();
                    }
                },
                err => {
                    console.log('FAILURE:', err);
                    this.error = 'Relationship failed or already exists';

                    this.progress = ((currentIndex + 1) / containers.length) * 100;
                    if (currentIndex < containers.length - 1) {
                        console.log('Next relation...');
                        this.submitAllContainersRecursively(containers, currentIndex + 1, onDone);
                    } else {
                        console.log('Finished');
                        onDone();
                    }
                }
            );
    }

    onBack(e) {
        this.location.back();
    }
}
