
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of as observableOf, concat as observableConcat, interval, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppContext } from '../app.context';
import { AuthService } from '../auth/auth.service';
import { ContainerService } from '../content/services/container/container.service';


@Injectable()
export class PipelineService {
    public static readonly pipelineTypes = [
        "ingest",
        "export",
        "upload",
        "media",
        "resource",
        "availability",
        "policy",
        "relation",
        "testing",
        "other",
        "admin",
        "commerce",
    ];

    public static readonly statusList = [
        'waiting',
        'pending',
        'running',
        'success',
        'failure',
        'cancelled',
    ];

    public static readonly pipelineOrigins = [
        "system",
    ];

    private baseUrl = `${environment.PROXY_API_BASE_URL}/templates`;

    constructor(
        private http: HttpClient,
        private appContext: AppContext,
        private authService: AuthService,
        private containerService: ContainerService
    ) {}

    getPipelineStatusObservable(origin, status: Array<string> = [], params: Object = {}): Observable < any > {

        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());


        let useParams = { origin: origin };
        useParams = Object.assign(useParams, params);

        if (status && status.length > 0) {
            useParams['status'] = status;
        }

        let httpParams = new HttpParams({ fromObject: useParams });

        const options = { headers: headers, params: httpParams };

        return observableConcat(observableOf(null), interval(5000)).pipe(
            mergeMap(() => this.http.get(`${this.baseUrl}/pipelines/`, options))
        );

    }

    getPipelineIdAndStatusObservable(pipelineId, origin, status: Array<string> = []): Observable < any > {
        return this.getPipelineStatusObservable(origin, status, { id: pipelineId });
    }

    getPipelineContextAndStatusObservable(contextId, origin, status: Array<string> = []): Observable < any > {
        return this.getPipelineStatusObservable(origin, status, { context_id: contextId });
    }

    getPipelineList(page = 1, bundle_id: string, context_id: string, status='', origin = true): Observable < any > {
        
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let originStr = origin ? `&origin=${this.appContext.activeOrigin}` : '';

        var query = '';

        if (bundle_id) {
            query += '&bundle_id=' + bundle_id;
        }
        if (context_id) {
            query += '&context_id=' + context_id;
        }
        if (status) {
            query += '&status=' + status;
        }
        
        return this.http
            .get(
                `${this.baseUrl}/pipelines/?page=${page}${originStr}${query}`, { headers }
            );
    }

    startPipeline(bundle_id: string, inputs: Object = {}, external_id = ''): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let data = {
            'origin': this.appContext.activeOrigin,
            bundle_id,
            inputs,
        };
        if (external_id) {
            data['external_id'] = external_id;
        }

        return this.http
            .post(
                `${this.baseUrl}/pipelines/`,
                JSON.stringify(data),
                { headers }
            );
    }

    pipelineAction(pipeline_id, pipeline_action): Observable < any > {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let data = {
            'pipeline_action': pipeline_action,
            'pipeline_guid': pipeline_id,
            'origin': this.appContext.activeOrigin,
        };

        return this.http
            .post(
                `${this.baseUrl}/pipelines/action/`,
                JSON.stringify(data),
                { headers }
            );
    }

    pipelineSearch(pipelineTypes: Array<string> = [], requiredInputs: Array<string> = [], user: Object = {}): Observable < any > {

        function isPipelineValid(p) {
            return p.is_enabled && p.data && p.data.inputs;
        }

        function comparePipelineTypes(p, pipelineTypes, currentOrigin) {
            var authenticatedPipelineTypes = [];

            if (pipelineTypes && pipelineTypes.length) {
                if(Object.keys(user).length > 0 && user['permissions'][currentOrigin].hasOwnProperty("pipeline")){
                    if(user['permissions'][currentOrigin]['pipeline']['type'].includes('all')) {
                        return pipelineTypes.includes(p.data.pipeline_type)
                    }
                    return (user['permissions'][currentOrigin]['pipeline']['type'].includes(p.data.pipeline_type) && pipelineTypes.includes(p.data.pipeline_type))
                }
            } else {
                if(Object.keys(user).length > 0 && user['permissions'][currentOrigin].hasOwnProperty("pipeline")){
                    if(user['permissions'][currentOrigin]['pipeline']['type'].includes('all')){
                        return true
                    }
                    else {
                        return Object.keys(user).length > 0 && user['permissions'][currentOrigin]['pipeline']['type'].includes(p.data.pipeline_type);
                    }
                }
            }
        }

        function compareRequiredInputs(p, requiredInputs) {
            if (requiredInputs && requiredInputs.length) {
                return requiredInputs.every(i => i in p.data.inputs);
            } else {
                return true;
            }
        }


        // Request system and current origin
        let origins = PipelineService.pipelineOrigins.slice();
        if (!origins.includes(this.appContext.activeOrigin)) {
            origins.push(this.appContext.activeOrigin);
        }
        var currentOrigin = origins.slice(-1)[0];

        return this.containerService
            .detailedSearch(1000, 0, [], 'pipeline', 'published', ['title'], origins.join(',')).pipe(
            map(
                res => {
                    return res.results.filter(
                        p => (
                            isPipelineValid(p)
                            && comparePipelineTypes(p, pipelineTypes, currentOrigin)
                            && compareRequiredInputs(p, requiredInputs)
                        )
                    )
                }
            ));
    }

    // TODO: get worker manifest (once in backend)
}
