<relation-tag-form *ngIf="showTagDialog" [showTagDialog]="showTagDialog" [container]="container" [parentId]="parentContainer.id" (onCloseTagDialog)="onCloseTagDialog()"></relation-tag-form>
<p-panel [style]="{'textAlign':'center'}">
    <p-header>
        <div class="ui-g" *ngIf="typeString != 'version' && typeString != 'configuration'">
            <span class="p-panel-title">{{container.data ? container.data['title'] || container.data['name'] || container.data['label'] : container.title || container.name}}</span>
        </div>
        <div class="ui-g" *ngIf="typeString == 'version'">
            <span class="p-panel-title">{{container.data['name'] ? container.data['name'] : "Version"}} {{container.data.number}}</span>
        </div>
        <div class="ui-g" *ngIf="typeString == 'configuration'">
            <span class="p-panel-title">{{container.data['name'] ? container.data['name'] : "Config"}} {{container.data.version}}</span>
        </div>
    </p-header>
    <div class="ui-g">

        <div *ngIf="appContext.activeOrigin !== container.origin" class="ui-g-4 ">
            <div class="label label-warning">{{container.origin}}</div>
        </div>
        <div *ngIf="container.data && container.data['source_status']" class="ui-g-4">
            <div class="label label-info">Pinned</div>
        </div>
        <img *ngIf="thumbnailUrl()" class="ui-g-12 thumbnail" [src]="thumbnailUrl()" style="padding-bottom:0;">
        <div *ngIf="!thumbnailUrl() && typeString != 'application' && typeString != 'distribution' && typeString != 'version' && typeString != 'configuration' && typeString != 'view' && typeString != 'collection' && typeString != 'season'"
            class="ui-g-12 thumbnail" style="padding-bottom:0;"></div>
        <div *ngIf="typeString == 'application'" class="ui-g-12">
            <div class="ui-g-12" style="padding:0;">
                <div class="ui-g-12 label label-success">{{container.containers | countByTypeNamePipe:'distribution'}} Distribution(s)</div>   
            </div>       
        </div>
        <div *ngIf="typeString == 'episode'" class="ui-g-12" style="padding-bottom:0;">
            <div class="ui-g-6" style="padding:0;padding-right:0.25em">
                <div class="ui-g-12 label label-default">Season: {{container.data.season_number}}</div>
            </div>
            <div class="ui-g-6" style="padding:0;padding-left:0.25em">
                <div class="ui-g-12 label label-default">Episode: {{container.data.episode_number}}</div>
            </div>
            <!--
            <div class="ui-g-12" style="padding-left:0;padding-right:0;padding-bottom:0;">
                <div *ngIf="container.data.metadata_ingest_workflow_status == 'Incomplete'" class="ui-g-12 label label-danger">Mandatory data missing</div>
                <div *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" class="ui-g-12 label label-warning">Some data may be missing</div>
                <div *ngIf="container.data.metadata_ingest_workflow_status == 'Complete'" class="ui-g-12 label label-success">Data is OK</div>
            </div>
             -->
        </div>
        <div *ngIf="typeString != 'application' && typeString != 'episode'" class="ui-g-12" style="padding-bottom:0;">
            <div class="ui-g-12 label label-default">{{typeString}}</div>
        </div>
        <div *ngIf="showTagControls" class="ui-g-12">
            <div class="ui-g-10 label" [ngClass]="statusLabelClass()">{{ container.status | titlecase }}</div>
            <div *ngIf="container.tags.length === 0 && container.relation_tags.length === 0" class="ui-g-2" style="padding:0; padding-left:.85em;" pTooltip="No tags">
                <button (click)="manageTags($event)" title="" class="label label-default  fa fa-fw fa-tag" style="border:0px transparent none; width:1.85em; height:1.85em; padding:0; vertical-align:text-top; display:inline-block"> </button>
            </div>
            <div *ngIf="container.tags.length > 0 || container.relation_tags.length > 0" class="ui-g-2" style="padding:0; padding-left:.85em;" pTooltip="{{tagTooltip}}">
                    <button (click)="manageTags($event)" title="" class="label label-success  fa fa-fw fa-tag" style="border:0px transparent none; width:1.85em; height:1.85em; padding:0; vertical-align:text-top; display:inline-block"> </button>
            </div>
        </div>
        <div *ngIf="!showTagControls" class="ui-g-12">
            <div class="ui-g-12 label" [ngClass]="statusLabelClass()">{{ container.status | titlecase }}</div>
        </div>
    </div>
</p-panel>
<p-toolbar>
    <div class="ui-g">
        <div class="ui-g-6"  style="margin:0 auto;">
            <button *ngIf="container.origin === appContext.activeOrigin" label="Edit" class="p-button-primary panel-button" pButton
                type="button" [routerLink]="editRoute" icon="pi pi-pencil"></button>
        </div>

        <div class="ui-g-6" *ngIf="parentContainer && parentContainer.origin === appContext.activeOrigin" style="padding-left:0">
            <button label="Remove" class="p-button-danger panel-button" pButton type="button" (click)="remove($event, container)" icon="pi pi-times"></button>
        </div>
        <div class="ui-g-6" *ngIf="typeString == 'version' || typeString == 'configuration'">
            <container-operations [containerId]="container.id" [parentContainerId]="parentContainer.id" (onOperationComplete)="update($event)"></container-operations>
        </div>

    </div>
</p-toolbar>
