<div class="card card-w-title top-level-card">
    <h1>Edit Season{{!isLoading && container ? ': ' + container.data['title'] : ''}}</h1>

    <page-header title="Edit Season{{!isLoading && container ? ': ' + container.data['title'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" [container]="container" [pipelineId]="pipelineId" (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <season-form #form [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formChangesScrapped)="onScrap($event)" (formCancelled)="onBack($event)"></season-form>

        <hr>

        <div class="ui-g-12">
            <resource-datarow [resources]="container.resources" [parentContainer]="container" title="Resources" rows="10" filterBy="type.name" [sortOptions]="resourceSortOptions">
                <button pButton type="button" label="Upload" [routerLink]="['/containers', container?.id, 'resources', 'upload']" icon="pi pi-upload" *ngxPermissionsOnly="['view.show.upload']"></button>
                <trigger-pipeline-modal-form [container]=container [pipelineTypes]="['resource']" *ngxPermissionsOnly="['object.write.all', 'object.write.season']" (pipelineTriggered)="onPipelineTriggered($event)"></trigger-pipeline-modal-form>
                <button pButton type="button" label="Create" [routerLink]="['/containers', container?.id, 'resources', 'create']" icon="pi pi-plus"></button>
            </resource-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="episode" itemsPerPage=20 [parentContainer]="container" title="Episodes" filterBy="data.title, data.keywords" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'episode']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
          <container-paginated-datarow type="media" itemsPerPage=20 [parentContainer]="container" title="Media" filterBy="data.title, data.keywords" (reorder)="onReorder($event)">
              <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'media']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
          </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="collection" itemsPerPage=20 [parentContainer]="container" title="Collections" filterBy="data.title, data.keywords" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'collection']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
