import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Container } from '../../../content/models/container';
import { Location } from '@angular/common';
import { Message } from 'primeng/api';
import { ContainerService } from '../../../content/services/container/container.service';
import { ViewFormComponent } from '../../forms/view-form/view-form.component';

@Component({
    selector: 'view-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'view-create.component.html',
    styleUrls: ['view-create.component.css']
})
export class ViewCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ViewFormComponent;
    private sub: any;
    public container: Container = new Container();
    private parentContainer: Container;
    private requiredMsgs: Message[] = [];
    private error: HttpResponse<any>;
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('View create initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['parentId']; // + converts string to number
            console.log('parentId', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    ngOnDestroy() {}

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        let newContId;
        this.containerService
            .save(this.container)
            .subscribe(
                function (res) {
                    console.log("Created: ", res);
                    newContId = res.id;
                    this.containerService
                        .relate(this.parentContainer, res)
                        .subscribe(
                            res => {
                                console.log("Related: ", res);
                                this.router.navigate(['/view', newContId], { replaceUrl: true });
                            },
                            function (err) {
                                // TODO: Fix this fallback deletion, it doesn't delete the container
                                console.log("Failed to relate: ", err);
                                this.error = err.statusText;

                                this.containerService
                                    .delete(res);
                                this.location.back();
                            }.bind(this)
                        );
                }.bind(this),
                err => {
                    console.log("Failed to create: ", err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() ||
            confirm('You may have unsaved changes that will not be saved if' +
                'you leave this page. Are you sure you want to leave this page?'))
        {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error',
            summary: 'Changes Were Not Saved',
            detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
