import { Component, Input, ElementRef, ViewChild, OnInit,  AfterViewInit, OnDestroy } from '@angular/core';
import { Resource } from '../../../content/models';
import { fromEvent as observableFromEvent, Subject, Observable } from 'rxjs';
import { map, distinctUntilChanged, pluck, filter, takeUntil } from 'rxjs/operators';
import { ContentGroupService } from '../../../commerce/services/content-group.service';

declare var shaka: any;
@Component({
    selector: 'preview-player',
    templateUrl: 'preview-player.component.html',
    styleUrls: ['preview-player.component.css']
})
export class PreviewPlayerComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() resource: Resource;
    @Input() playerStyle: any;
    @Input() trackPlayerState: boolean = false;
    @ViewChild('video') videoElementRef: ElementRef;
    private videoElement: HTMLVideoElement;
    private config = {
        'range': 30,
        'allowAutoplay': false,
        'autoplay': false
    };
    private destroy$ = new Subject();
    private player;
    private currentCgState;

    constructor(
        private cgService: ContentGroupService
    ) {}

    ngOnDestroy(): void {
        // Unsubscribe from all Subscriptions
        if (this.trackPlayerState) {
            this.cgService.updateCgState({
                'autoplay': this.config.autoplay,
                'source': 'preview-player-destroy'
            });
        }
        this.destroy$.next(true);
        this.destroy$.complete();
        this.player.removeEventListener('error', this.onErrorEvent);
        this.videoElement.removeEventListener('play', this.onPlay);
        this.videoElement.removeEventListener('pause', this.onPause);
    }

    ngOnInit() {
        this.currentCgState = this.cgService.getCurrentCgState();
        if (this.trackPlayerState) {
            if (this.currentCgState && 'allowAutoplay' in this.currentCgState) {
                this.config.allowAutoplay = this.currentCgState['allowAutoplay'];
            }

            if (this.currentCgState && 'autoplay' in this.currentCgState && this.currentCgState['autoplay'] !== null) {
                this.config.autoplay = this.currentCgState['autoplay'];
            }
        }
    }

    // Instead ngOnInit. Dealing with DOM
    ngAfterViewInit(): void {
        this.destroy$.next(false);
        this.initApp();
        if (this.trackPlayerState) {
            this.createSubscriptions();
            if (this.currentCgState && 'autoplay' in this.currentCgState && this.currentCgState['autoplay'] === null) {
                this.config.autoplay = true;
            }
        }
    }

    private createSubscriptions(): void {
        // Create observables for singular state changes
        const activeTimecodeObs$: Observable<any> = this.cgService.getCgState()
            .pipe(
                pluck('userTimecode'),
                filter(val => val >= 0)
            );

        // Subscription 1: Player controls while changing active moment
        activeTimecodeObs$.pipe(takeUntil(this.destroy$))
            .subscribe((userTimecode) => {
                //this.videoElement.pause();
                this.videoElement.currentTime = userTimecode;
                if (this.config.allowAutoplay && this.config.autoplay) {
                    this.videoElement.autoplay = true;
                } else {
                    this.videoElement.autoplay = false;
                }

            });

        // Create observable from native event 'timeupdate'
        const playTime$ = observableFromEvent(this.videoElement, 'timeupdate')
        .pipe(
            map(val =>  Math.round(val['srcElement']['currentTime']) ), // rounding to 1 second interval
            //filter(val => val > 0), // filtering init value of 0
            distinctUntilChanged() // emiting only changed values
        );

        // Subscription 2: UI marker position on play
        playTime$.pipe(takeUntil(this.destroy$))
            .subscribe((val) => { this.cgService.updateCgState({ 'playerTimecode' : val, 'userTimecode': -1, 'source': 'preview-player' }); 
        });

        const allowAutoplay$: Observable<any> = this.cgService.getCgState()
            .pipe(pluck('allowAutoplay'));

        allowAutoplay$.pipe(takeUntil(this.destroy$))
            .subscribe ((val) => {
                this.config.allowAutoplay = val;
            });
    }

    initApp() {
        // Install built-in polyfills to patch browser incompatibilities.
        shaka.polyfill.installAll();

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
            // Everything looks good!
            this.videoElement = this.videoElementRef.nativeElement;
            this.initPlayer();

        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }
    }

    initPlayer() {
        // Create a Player instance.
        this.player = new shaka.Player(this.videoElement);

        // Listen for error events.
        this.player.addEventListener('error', this.onErrorEvent);

        //Listen to play + pause; adjust settings accordingly
        this.videoElement.addEventListener('play', this.onPlay.bind(this));
        this.videoElement.addEventListener('pause', this.onPause.bind(this));

        // Try to load a manifest.
        // This is an asynchronous process.

        let licenseServer = 'https://corus-authentication-dev.herokuapp.com/authorization/widevine/getresourcekey';

        this.player.configure({
            drm: {
                servers: { 'com.widevine.alpha': licenseServer }
            }
        });

        let manifestUri = this.resource.data['resources']['streaming_url'];
        this.player.load(manifestUri)
            .then(function() {
                // This runs if the asynchronous load is successful.
                console.log('The video has now been loaded!');

            })
            .catch(error => { this.onError(error); }); // onError is executed if the asynchronous load fails.

        
        
        this.player.getNetworkingEngine()
            .clearAllRequestFilters();

        this.player.getNetworkingEngine()
            .registerRequestFilter(function(type, request) {
                if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) return;
                let StringUtils = shaka.util.StringUtils;
                if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                    console.log(request);
                    request.headers = {
                        'Content-Type': 'application/json'
                    };

                    let originalContent = Array.apply(null, new Uint8Array(request.body));
                    let newContent = {
                        'manifest_url': this.manifestUri,
                        'resource_id': '1087556675795',
                        'license_request_data': originalContent
                    };

                    let contentString = JSON.stringify(newContent);
                    let contentBody = StringUtils.toUTF8(contentString);
                    request.body = contentBody;

                    console.log(request.body);
                }
            });

    }

    onErrorEvent(event) {
        // Extract the shaka.util.Error object from the event.
        console.log(event.body);
        this.onError(event.detail);
    }

    onPlay() {
        if (this.trackPlayerState && this.config.allowAutoplay) {
            this.config.autoplay = true;
            this.videoElement.autoplay = true;
            //console.log(this.config);
        }
    }

    onPause() {
        if (this.trackPlayerState && this.config.allowAutoplay) {
            this.config.autoplay = false;
            this.videoElement.autoplay = false;
            //console.log(this.config);
        }
    }

    onError(error) {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }
}
