import { Component, OnInit, Input } from '@angular/core';
import { Container, Resource, EntityType } from '../../models';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'resource-datarow',
    templateUrl: 'resource-datarow.component.html',
    styleUrls: ['resource-datarow.component.css']
})
export class ResourceDatarowComponent implements OnInit {
    @Input() set resources(resources: Array<Resource>) {
        console.log(resources);
        this.allResources = resources;

        this.shownResources = [];
        resources.forEach(element => {
            this.shownResources.push(element);
            if (element.type.name !== 'file') {
                element['show'] = true;
            } else {
                this.fileResources.push(element);
                element['show'] = false;
            }
        });

        this.filter();
    }
    @Input() parentContainer: Container;
    @Input() title: string;
    @Input() rows: number;
    @Input() filterBy: string;
    @Input() sortOptions: Array<any>;
    @Input() isLoading: boolean;

    private error: string;
    private allResources: Array<Resource> = [];
    public shownResources: Array<Resource> = [];
    public fileResources: Array<Resource> = [];
    private sortKey: string;
    private sortField: string;
    private sortOrder: number;
    private activeFilter: string;
    private filterByFields: Array<any>;
    public displayManageDialog: boolean = false;
    private rowGroupMetadata: any;
    public collapsed = false;

    constructor() {}

    ngOnInit() {
        let filters = this.filterBy.split(', ');
        this.filterByFields = [];
        filters.forEach(element => {
            this.filterByFields.push(element.split('.'));
        });
        this.filterByFields.push('file');
    }

    onFilter(value) {
        this.activeFilter = value;
        this.filter();
    }

    showManageDialog() {
        this.displayManageDialog = true;
    }

    closeManageDialog() {
        this.displayManageDialog = false;
    }

    filter() {
        this.shownResources.forEach(resource => {
            if (this.activeFilter && this.activeFilter !== '') {
                resource['show'] = false;
                this.filterByFields.forEach(filter => {
                    if (!resource['show']) {
                        let result = resource;
                        if (typeof(filter) === 'object') {
                            filter.forEach(element => {
                                result = result[element];
                            });
                        }
                        if (String(result).toLowerCase().includes(this.activeFilter.toLowerCase())) {
                            resource['show'] = true;
                        }
                    }
                });
            } else {
                resource['show'] = true;
            }

            if (resource.type.name === 'file') {
                resource['show'] = false;
            }
        });
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }

        this.sort();
    }

    sort() {
        if (!this.sortField || this.sortField === '') {
            this.shownResources = [];
            this.allResources.forEach(element => {
                this.shownResources.push(element);
            });

            this.filter();
            return;
        }

        this.shownResources.sort((a, b) => {
            let value1 = this.resolveObjectField(a, this.sortField);
            let value2 = this.resolveObjectField(b, this.sortField);

            let result = null;
            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (this.sortOrder * result);
        });
    }

    resolveObjectField(object: Object, value: string) {
        let tokens = value.split('.');
        let result = object;
        tokens.forEach(element => {
            result = result[element];
        });
        return result;
    }

    resourceRemoved(e) {
        let i = this.allResources.indexOf(e.resource);
        this.allResources.splice(i, 1);

        this.shownResources = [];
        this.allResources.forEach(element => {
            this.shownResources.push(element);
        });

        this.filter();
        this.sort();
    }
}
