<spinner *ngIf="isLoading"></spinner>
<form  #ngForm="ngForm" *ngIf="container && !isLoading" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-12 ui-md-6">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-12 ui-md-6">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Basic Information</span>
            </p-header>
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12 ui-md-6">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" required [(ngModel)]="container.data['title']" name="title" placeholder="Required" required>
                </div>
                <div class="ui-g-12 ui-md-6">
                    <label for="subtype">Subtype</label>
                    <select required [(ngModel)]="container.data.subtype" name="subtype" class="form-control">
                        <option *ngFor="let subtype of subtypes" [value]="subtype">{{subtype}}</option>
                    </select>
                </div>
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" placeholder="Optional">
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;display:inline-block;margin-top:2px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-12">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <view-metadata-form-panel [container]=container class="ui-g-12"></view-metadata-form-panel>

    </div>

</form>

<div class="ui-g-12">
    <p-editor #pEditor 
    [(ngModel)]="content"
    [style]="{'height':'320px'}">
</p-editor>
</div>

