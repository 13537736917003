import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EpisodeFormComponent } from '../../forms/episode-form/episode-form.component';

import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';

@Component({
    selector: 'container-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'container-create.component.html',
    styleUrls: ['container-create.component.css']
})
export class ContainerCreateComponent implements OnInit {
    private container: Container = new Container();
    public error: string;
    public isLoading = false;

    constructor(
        private location: Location,
        private containerService: ContainerService,
        private router: Router
    ) {}

    ngOnInit() {
        console.log('container create init');
    }

    onSubmit(e) {
        console.log('container create submit', e);

        this.containerService
            .save(e.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.router.navigate(['/containers', res.id], { replaceUrl: true });
                },
                err => {
                    console.log(err);
                    let body = err.json();
                    this.error = body.guid || err.statusText;
                }
            );
    }
}
