import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { genre as genreList } from '../../../shared/enum';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'series-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'series-form.component.html'
})
export class SeriesFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;

    private publishDate :Date;

    private country_of_origin = ['CA', 'CA_premium', 'US', 'UK'];
    private ratings = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
    private seriesType = ['series', 'miniseries', 'seasonless'];
    private genre = genreList;


    private msgs: Message[] = [];


    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.setup();
    }

    resetToDefaultState() {
        this.ngForm.reset();
        this.setup();
    }

    setup() {
        this.entityTypeId = this.container.type.id;
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);

        if (!('locked' in this.container.data) || !this.container.data['locked']) {
            this.container.data['locked'] = true;
        }

        // if (!this.container.is_enabled) {
        //     this.container.is_enabled = true;
        // }

        if (!('series_type' in this.container.data) || (!this.container.data['series_type']) ) {
            this.container.data['series_type'] = 'series';
        }

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'series'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getDateFromTimeNumber(time) {
        if (!time) return null;
        var date = new Date(0);
        date.setUTCSeconds(time);
        return date;
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the series title.' });
            valid = false;
        }
        if (!('description' in this.container.data) || (!this.container.data['description']) || !/\S/.test(this.container.data['description'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the series description.' });
            valid = false;
        }

        // Backward compatibility with older clients. display_pretitle and display_title are deprecated.
        if ('super_label' in this.container.data) {
            this.container.data['display_pretitle'] = this.container.data['super_label'];
        }
        if ('display_label' in this.container.data) {
            this.container.data['display_title'] = this.container.data['display_label'];
        }
        if ('sub_label' in this.container.data) {
            this.container.data['display_subtitle'] = this.container.data['sub_label'];
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onStatusChange(event) {
        this.publishDate = this.getDateFromTimeNumber(this.container.published_date);
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}
