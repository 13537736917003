import { Component, OnInit, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import { ContainerComponent } from '../../../content/components/container/container.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { Pipe, PipeTransform } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { Location } from '@angular/common';

@Component({
    selector: 'distribution-row',
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'distribution-row.component.html',
    styleUrls: ['distribution-row.component.css']
})
export class DistributionRowComponent implements OnInit, AfterContentInit {
    @Input() containers: Array < any > = [];
    @Input() parentContainer: Container;
    @Output() reorder: EventEmitter < any > = new EventEmitter();
    // TODO: implement controlsActive check

    private isLoading = true;
    private error: string;
    public name = '';

    private entityTypes = [];
    private entityTypeId;
    private seasonEntityTypeId;

    constructor(
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService
    ) {}

    ngAfterContentInit() {
        this.name = Math.random()
            .toString(36)
            .substring(7);
    }

    ngOnInit() {
        console.log("containers dist", this.containers);
        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    // console.log(res);
                    this.entityTypeId = (this.entityTypes.find(t => t.name === 'distribution'))
                        .id;
                    this.containers = this.containers.filter(t => t.type.id === this.entityTypeId);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    private getElementIndex(el) {
        // previousElementSibling is only supported in IE10+
        for (var i = 0; el = el.previousElementSibling; i++);
        return i;
    }

    private onRemove(e, container) {
        if (confirm('Are you sure you want to remove this distribution?')) {
            console.log('remove', e, container);
            let i = this.containers.indexOf(container);
            this.containers.splice(i, 1);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                    },
                    err => console.log(err)
                );
        }
    }

}
