import { Component, OnInit, Input } from '@angular/core';
import { Container } from '../../models';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'container-subgenre',
  templateUrl: './container-subgenre.component.html',
  styleUrls: ['./container-subgenre.component.css']
})
export class ContainerSubgenreComponent implements OnInit {
    @Input() container: Container;

    public subgenreOptions = [
        { 'label': 'Baking', 'value': 'baking' },
        { 'label': 'Cooking', 'value': 'cooking' },
        { 'label': 'DIY', 'value': 'diy' },
        { 'label': 'Factual', 'value': 'factual' },
        { 'label': 'Home Improvement', 'value': 'home_improvement' },
        { 'label': 'Real Estate', 'value': 'real_estate' },
    ]
    public selectedSubgenreOption: SelectItem = null;

    constructor() { }

    ngOnInit() {
        if ('subgenre' in this.container.data && this.container.data['subgenre']) {
            this.selectedSubgenreOption = this.container.data['subgenre'];
        }
    }

    onValueChange() {
        if (this.selectedSubgenreOption) {
            this.container.data['subgenre'] = this.selectedSubgenreOption;
        } else {
            delete this.container.data['subgenre'];
        }
    }

}
