<p-panel>
    <spinner *ngIf="isLoading"></spinner>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Moments</span>
            <div class="ui-g-12 ui-md-6 ui-lg-4" style="padding:0;">
                <ng-content></ng-content>
            </div>
        </div>
    </p-header>

    <p-table #dt [value]="momentContainers" 
                 [rows]="rows" 
                 [paginator]="true" 
                 paginatorPosition="bottom" 
                 [lazy]="true"
                 (onLazyLoad)="loadMomentsLazy($event)"
                 selectionMode="single" 
                 (onRowSelect)="onRowSelect()" 
                 [(selection)]="container" 
                 [responsive]="true"
                 styleClass="p-datatable-gridlines p-datatable-nested"
                 [totalRecords]="totalRecords">
        <ng-template pTemplate="header">
            <tr>
                <th>Label</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-moment>
            <tr [pSelectableRow]="moment">
                <td style="word-break: break-all;">{{moment.data?.label}}
                    <button *ngIf='showRemoveControls' 
                            pButton type="button" 
                            class="p-button-danger" 
                            (click)="onRemove(moment)" 
                            icon="pi pi-times" 
                            style="font-weight:600;display:block;float:right;">
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>