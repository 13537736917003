import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { Container } from '../../../content/models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'view-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'view-form.component.html'
})
export class ViewFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;

    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;
        this.setup();
    }

    resetToDefaultState() {
        this.ngForm.reset();
        this.setup();
    }

    setup() {
        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'view'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        var valid = this.ngForm.form.status === 'VALID';
        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}
