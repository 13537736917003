import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Container, EntityType } from '../../models';
import { ContainerService } from '../../services/container/container.service';
import { AppContext } from '../../../app.context';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';

@Component({
    selector: 'container-table',
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'container-table.component.html',
    styleUrls: ['container-table.component.css']
})
export class ContainerTableComponent implements OnInit {
    public searchResult: any = {};
    public containers: Array<any> = [];
    private limit: number;
    public offset: number;
    public searchTerm = '';
    private filteredType = '';
    private filteredStatus = '';
    private orderBy: Array<string> = [];
    public isLoading = true;
    public entityTypes = [];
    public selectedType: EntityType = null;
    public statusList: Array<SelectItem> = [];
    public selectedStatus: SelectItem = null;
    private error: string;

    private allColumns = [
        { default: true, header: 'Title', field: 'title', transform: [this.toBold.bind(this)] },
        { default: true, header: 'Show Title', field: 'data.show_title', transform: [this.toBold.bind(this)] },
        { default: true, header: 'GUID', field: 'guid', style: { width: "19em" } },
        { default: true, header: 'Type', field: 'type', style: { width: "6.5em" }, transform: [this.toEntityTypeLabel.bind(this)] },
        { default: true, header: 'Status', field: 'status', style: { width: "6.5em" }, transform: [this.toStatusLabel.bind(this)] },
        { default: false, header: 'Season Number', field: 'season_number' }
    ];
    public columns = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private containerService: ContainerService,
        private entityTypeService: EntityTypeService,
        private appContext: AppContext,
    ) {
        this.columns = this.allColumns.filter(col => col.default);
    }

    ngOnInit() {
        this.isLoading = true;
        // this.containers = this.searchResult.results;

        this.statusList = ContainerService.statusList.map(s => ({ label: s, value: s }));

        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityTypes.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1;
                        } else if (a.name < b.name) {
                            return -1;
                        }
                        return 0;
                    });
                    // console.log(res);
                    if (this.filteredType && !this.selectedType) {
                        this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                        // console.log('Initial type', this.selectedType);
                    }
                    this.setSearchParams();
                },
                err => console.log(err),
                () => this.isLoading = false
            );

        // Update on query param change
        this.getSearchContextParameters();
        // this.loadResults();
    }


    getSearchContextParameters() {
        let searchContextParameters = this.appContext.componentState['search_view'];

        this.searchTerm = searchContextParameters['search'] || '';

        // Set type
        this.filteredType = (searchContextParameters['type'] || '').toLowerCase();
        if (this.filteredType) {
            if (!this.selectedType || this.selectedType.name !== this.filteredType) {
                this.selectedType = this.entityTypes.find(t => t.name == this.filteredType);
                // console.log('Selected Type', this.selectedType);
            }
        } else if (this.selectedType) {
            this.selectedType = null;
        }

        // Set status
        this.filteredStatus = (searchContextParameters['status'] || '').toLowerCase();
        if (this.filteredStatus) {
            if (!this.selectedStatus || this.selectedStatus.value !== this.filteredStatus) {
                this.selectedStatus = this.statusList.find(t => t.value == this.filteredStatus);
                // console.log('Selected Status', this.selectedStatus);
            }
        } else if (this.selectedStatus) {
            this.selectedStatus = null;
        }

        // Set offset
        this.offset = searchContextParameters['offset'] || 0;
    }

    setSearchParams(resetOffset: boolean = false) {
        if (resetOffset) {
            this.offset = 0;
        }
        this.appContext.componentState['search_view'] = {
            'type': this.filteredType || null,
            'search': this.searchTerm || null,
            'status': this.filteredStatus || null,
            'offset': this.offset || null,
        };

    }

    loadResults() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(
                this.limit,
                this.offset,
                this.searchTerm ? [this.searchTerm] : [],
                this.filteredType || '',
                this.filteredStatus || '',
                this.orderBy.length > 0 ? this.orderBy : ['-created_date']
            )
            .subscribe(
                res => {
                    this.searchResult = res;
                    this.containers = this.searchResult.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
            //console.log(res);
    }

    loadResultsLazy(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.loadResults();
        this.setSearchParams();
    }

    changedSearchTerm(event) {
        this.loadResults();
        this.setSearchParams(true);
    }

    findParentContainerId(container) {
        console.log('clicked on container', container);
        console.log('all containers', this.containers);

        var primaryContainer = container.data['primary_parent_container_id'];
        var parentContainer = this.containers.filter(t => t.guid === primaryContainer)[0];

        console.log('clicked on container parent', parentContainer);

        this.router.navigate(['/episodes', container.id, parentContainer.id]);
    }

    toBold(data) {
        return '<b>' + data + '</b>';
    }

    toEntityTypeLabel(data) {
        return '<div class="label label-default">'
            + (data || '')
            + '</div>';
    }

    toStatusLabel(status) {
        if (!status) {
            return '';
        }
        return '<div class="label ' +
               this.containerService.getStatusLabelClass(status) +
               '">' +
               this.toTitleCase(status) +
               '</div>';
    }

    toTitleCase(data) {
        let txt = data || '';
        if (!txt) {
            return txt;
        }
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    getFieldData(container, column) {
        let data = container;
        let fields = column.field.split('.');
        for (let field of fields) {
            data = data[field];
        }
        data = data || '';
        if (column.transform) {
            for (let i = 0; i < column.transform.length; i++) {
                data = column.transform[i](data);
            }
        }
        return data;
    }

    onChangedFilteredType(event) {
        this.filteredType = event.value ? event.value.name : '';
        this.loadResults();
        this.setSearchParams(true);
    }

    onChangedFilteredStatus(event) {
        this.filteredStatus = event.value ? event.value.value : '';
        this.loadResults();
        this.setSearchParams(true);
    }
}
