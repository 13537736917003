<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
                <p-header>
                    <span class="p-panel-title" style="font-size:16px;">Role Permissions</span>
                </p-header>
    
                <div style="padding:0.5em;overflow:hidden;">
                    <div class="ui-g-12">
                    <span *ngFor="let perm of viewPermissions.show" style="margin-right:10px"> 
                        <div class="label label-success"> {{perm}}</div>
                    </span>
                    <span *ngFor="let perm of objectPermissions.write" style="margin-right:10px">
                        <div class="label label-primary"> {{perm}}</div>
                    </span>
                    <span *ngFor="let perm of apiPermissions.crud" style="margin-right:10px">
                        <div class="label label-warning"> {{perm}}</div>
                    </span>
                    <span *ngFor="let perm of apiPermissions.read" style="margin-right:10px">
                        <div class="label label-default"> {{perm}}</div>
                    </span>
                    <span *ngFor="let perm of pipelinePermissions.type" style="margin-right:10px">
                        <div class="label label-info"> {{perm}}</div>
                    </span>
                    <span *ngFor="let perm of pipelinePermissions.action" style="margin-right:10px">
                        <div class="label label-info"> {{perm}}</div>
                    </span>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['name']" name="name" placeholder="Required" required>
                </div>
                <div class="ui-g-6">
                    <label for="origin">Mapping Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.mapping_id" name="bundle" placeholder="Required" required>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-6">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-4">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-8">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
            </div>
        </p-panel>

        <p-panel *ngIf="container.id > 0" class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>
            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">VMS View Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of viewPermDefs.show">
                    <p-checkbox name="viewPermissions" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="viewPermissions.show"></p-checkbox>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Object Write Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of objectPermDefs.write">
                    <p-checkbox name="objectPermissions" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="objectPermissions.write"></p-checkbox>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Pipeline Type Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of pipelinePermDefs.type">
                    <p-checkbox name="pipelinePermissionsType" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="pipelinePermissions.type"></p-checkbox>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Pipeline Action Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of pipelinePermDefs.action">
                    <p-checkbox name="pipelinePermissionsAction" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="pipelinePermissions.action"></p-checkbox>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">API Write Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of apiPermDefs.crud">
                    <p-checkbox name="apiPermissionsCrud" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="apiPermissions.crud"></p-checkbox>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">API Read Permissions</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2" *ngFor="let perm of apiPermDefs.read">
                    <p-checkbox name="apiPermissionsRead" [value]="perm.name" [label]="perm.label"
                        [inputId]="perm.name" [(ngModel)]="apiPermissions.read"></p-checkbox>
                </div>
            </div>
        </p-panel>
    </div>
</form>
