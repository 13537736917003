import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models';
import { AppContext } from '../../../app.context';
import { CountByTypeNamePipe } from '../container/countByTypeName.pipe';

@Component({
    selector: 'container',
    providers: [
        ContainerService,
    ],
    templateUrl: 'container.component.html',
    styleUrls: ['container.component.css']
})

export class ContainerComponent implements OnInit {
    @Input() container: Container;
    @Input() parentContainer: Container;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();

    public typeString: string;
    private tagTooltip: string;
    public showTagControls: boolean;
    public showTagDialog: boolean = false;
    
    private editRoute: Array<any>;

    constructor(
        private containerService: ContainerService,
        public appContext:AppContext,
    ) { }

    ngOnInit() {
        if (typeof this.container.type === 'string') {
            this.typeString = this.container.type;
        } else if (typeof this.container.type.name === 'string') {
            this.typeString = this.container.type.name;
        } else {
            this.typeString = '';
        }

        if (this.container.tags && this.container['relation_tags']) {
            this.showTagControls = true;
            this.tagTooltip = this.createTagString();
        } else {
            this.showTagControls = false;
        } 

        this.editRoute = this.getEditRoutes();

    }

    ngOnDestroy() {
        if (this.appContext.componentState['tags_dict'] || this.appContext.componentState['tags_types']){
            delete this.appContext.componentState['tags_dict'];
            delete this.appContext.componentState['tags_types'];
        }
    }

    // TODO: default thumbnail
    thumbnailUrl() {
        let url = '';
        if (this.container.resources) {
            let thumbnailResource = this.container.resources.find ? this.container.resources.find(r => r.type && r.type.name === 'thumbnail_small') : undefined;
            url = thumbnailResource ? thumbnailResource.uri : '';
            url = url && this.container.resources ? url : this.container.resources['thumbnail_small'];
        } else {
            url = this.container['thumbnail'];
        }
        url = url ? url : '';
        return url;
    }

    update(e) {
        this.onUpdate.emit({});
    }

    remove(e, container) {
        var parentTitle = this.parentContainer.data['title'] || this.parentContainer.data['name'];
        var thisTitle = this.container.data['title'] || this.container.data['name'] || ('this ' + this.typeString);
        if (confirm('Are you sure you want to remove ' + thisTitle + ' from ' + parentTitle + '?')) {
            console.log('remove', e, container);
            this.containerService
                .unrelate(this.parentContainer, container)
                .subscribe(
                    res => {
                        console.log(res);
                        this.onRemove.emit({
                            container: this.container
                        });
                    },
                    err => console.log(err)
                );
        }
    }

    statusLabelClass() {
        return this.containerService.getStatusLabelClass(this.container.status);
    }

    createTagString() {
        if (this.container.tags && this.container['relation_tags']) {
            let containerTags, relationTags;
            if (this.container.tags &&this.container.tags.length > 0) {
                containerTags = 'Container tags: ' + this.container.tags.map(t => t.value).join('\n');
            } else {
                containerTags = '';
            }
            if(this.container['relation_tags'] && this.container['relation_tags'].length > 0) {
                relationTags = 'Relationship tags: '  + this.container['relation_tags'].map(t => t.value).join('\n');
            } else {
                relationTags = ''
            }
            let separator = containerTags && relationTags ? '\n\n' : '';
            return containerTags + separator + relationTags;
        } else {
            return '';
        }

    }

    manageTags(e) {
        this.showTagDialog = true;
    }

    onCloseTagDialog() {
        this.showTagDialog = false;
        this.tagTooltip = this.createTagString();
    }

    getEditRoutes() {

        let editRoutes = {
            'application': ['/applications', this.container.id],
            'view': ['/view', this.container.id],
            'channel': ['/channels', this.container.id],
            'series': ['/series', this.container.id],
            'movie': ['/movie', this.container.id],
            'pipeline': ['/pipelines', this.container.id],
            'worker': ['/workers', this.container.id],
            'server': ['/server', this.container.id],
            'profile': ['/profiles', this.container.id],
            'role': ['/roles', this.container.id],
            'provider': ['/providers', this.container.id],
            'user_profile': ['/user/profiles', this.container.id]
        }

        if (this.parentContainer) {
            let editRoutesWithParents = {
                'configuration': ['/configurations', this.container.id, this.parentContainer.id],
                'distribution': ['/distributions', this.container.id, this.parentContainer.id],
                'version': ['/versions', this.container.id, this.parentContainer.id],
                'episode': ['/episodes', this.container.id, this.parentContainer.id],
                'season': ['/season', this.container.id, this.parentContainer.id],
                'media': ['/media', this.container.id, this.parentContainer.id],
                'collection': ['/collection', this.container.id, this.parentContainer.id]
            }

            editRoutes = Object.assign(editRoutes, editRoutesWithParents)
        }


        if (this.typeString in editRoutes) {
            return editRoutes[this.typeString];
        } else {
            return ['/containers', this.container.id];
        }
    }
}
