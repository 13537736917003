import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { CommerceService } from '../../services/commerce.service';
import { ContentGroupService} from '../../services/content-group.service';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { AppContext } from '../../../app.context';


@Component({
    selector: 'segment-media-table',
    providers: [
        ContainerService,
        EntityTypeService
    ],
    templateUrl: 'segment-media-table.component.html',
    styleUrls: ['segment-media-table.component.css']
})

export class CASegmentMediaTableComponent implements OnInit {
    @Input() segmentKeywords: Array<string>;
    @Input() segmentOrigins: Array<string>;
    @Input() title: string;
    @Input() rows: number;
    @Output() rowSelect: EventEmitter < any > = new EventEmitter();
    @ViewChild(Table) itemTable;
    private error: any;
    public container: Container;
    public isLoading = false;
    private cgState: any;
    private destroy$ = new Subject();
    public totalRecords: number;
    private parentEpisodeGUIDS = [];
    public episodeContainers = [];
    private limit: number;
    private offset: number = 0;
    private offsets = [];
    private lastSearch = null;
    private lastSearchTerms = [];
    private selectedDate:Date;
    public rangeDates: Date[];
    private transformedData = {};
    private objectLength;
    private possibleKeywords: number;

    constructor(
        public containerService: ContainerService,
        private commerceService: CommerceService,
        private cgService: ContentGroupService,
        private appContext: AppContext
        
    ) {}

    ngOnInit() {
        console.log('loading media table');
        this.possibleKeywords = this.segmentKeywords.length;
        // If content group consits of more than 5 keywords, put a cap at 5 keywords
        // for strengthValue calculation later
        if (this.segmentKeywords.length > 5) {
            this.possibleKeywords = 5;
        }
    }

    ngAfterViewInit(): void {
        this.destroy$.next(false);
        this.createSub();
    }

    ngOnDestroy() {
        // Unsubscribe from all Subscriptions
        this.destroy$.next(true);
        this.destroy$.complete();
      }

    private createSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
            });
    }

    onRowSelect() {
        this.rowSelect.emit({
            container: this.container
        });
    }

    unselectTableItems() {
        this.itemTable.selection = null;
    }
    // Two step search: 1.segments 2.episodes
    // A bit tricky pagination due to unknown totalRecords until the pagination is finished
    // Pagination is continued until the results are empty
    searchSegmentsAndEpisodes(terms){
        this.parentEpisodeGUIDS = [];
        this.episodeContainers  = [];
        this.transformedData = {};
        this.objectLength = 0;
        this.lastSearchTerms = terms;
        if (this.lastSearch){
            console.log(this.lastSearch);
            this.lastSearch.unsubscribe();
            this.lastSearch = null;
        }
        this.isLoading = true;
        // Search for segments with unique parent episode by using segmentKeywords
        this.commerceService
        .getUniqueByParentForKeywordValues(this.segmentKeywords, this.segmentOrigins, this.limit, this.offset, this.rangeDates)
        .subscribe(
            res => {
                let segmentsPayload = res;
                let segmentsContainers = segmentsPayload.results;
                // Pagination trick
                // Add to the totalRecords only if the selected page has not yet been visited
                if (!this.offsets.includes(this.offset)) {
                    // Initially, display two pages if the pagination is required.
                    if (this.offset ===0) {
                        this.totalRecords = 0;
                        this.totalRecords = segmentsPayload.results.length + 1;
                    }else {
                        // otherwise, keep paginating
                        // End pagination when the returned segmentsPayload result is less than set rows
                        this.totalRecords += segmentsPayload.results.length;
                        if (segmentsPayload.results.length <= this.limit) {
                            this.totalRecords = this.totalRecords;
                        }
                    }
                }
                for(let i = 0; i < segmentsContainers.length; i++) {
                    if (segmentsContainers[i].parent) {
                        this.parentEpisodeGUIDS.push(segmentsContainers[i].parent);
                    }
                }
                //Fetch episode containers by using episodeGUIDS
                this.lastSearch = this.commerceService
                    .getContainersByGuid(this.parentEpisodeGUIDS)
                    .subscribe(
                        res => {
                            let episodePayload  = res;
                            // Sort episodes by the segments (which is sorted by the 'created_date')
                            for(let i = 0; i < this.parentEpisodeGUIDS.length; i++) {
                                for (let j = 0; j < episodePayload.length; j++) {
                                    if (this.parentEpisodeGUIDS[i] === episodePayload[j].guid) {
                                        this.episodeContainers.push(episodePayload[j]);
                                    }
                                }
                            }
                            // Get nested aggregation
                            this.commerceService
                                .getFacetCountsForSegmentTermByParent(this.parentEpisodeGUIDS, this.segmentKeywords)
                                .subscribe(
                                    res => {
                                        let result = res;
                                        const data = result['facets']['_filter_parent_term_histogram']['parent_term_histogram']['buckets'];
                                        const guidMap = new Map(data.map(ep => [ep['key'], ep]));
                                        const episodes = this.parentEpisodeGUIDS.map(guid => guidMap.get(guid));
                                        this.transformedData = this.processEpisodes(episodes, this.episodeContainers);
                                        this.objectLength = Object.keys(this.transformedData).length;
                                    },
                                    err => {
                                        this.error = err;
                                        console.log('error', this.error);
                                    },
                                )
                            // Select the first episode in the media table when first loaded
                            if ( !this.container  && !(this.cgState && this.cgState['activeMoment']) )
                            {
                                this.container  = this.episodeContainers[0];
                                this.onRowSelect();
                            }
                        },
                        err => {
                            this.error = err;
                            console.log('error', this.error);
                            this.container  = null;
                            this.onRowSelect();
                        },
                        () =>{
                            this.isLoading = false;
                        }
                    )
            },
            err => {
                this.error = err;
                console.log('error', this.error);
            },
            () =>{
                if (!this.offsets.includes(this.offset)) {
                    this.offsets.push (this.offset);
                }
                this.isLoading = false;
            }
        );
    }

    loadDataOnScroll(event) {
        this.limit = event.rows;
        this.offset = event.first;
        this.searchSegmentsAndEpisodes(this.lastSearchTerms)
    }

    //statusLabelClass(status) {
    //    return this.containerService.getStatusLabelClass(status);
    //}

    handleDateSelect() {
        if (this.rangeDates.length === 2 && this.rangeDates[1] && this.rangeDates[0] !== this.rangeDates[1]) {
            this.rangeDates[0].setHours(0, 0, 0, 0);
            this.rangeDates[1].setHours(24, 0, -1, 0);
            this.offsets = [];
            this.offset = 0;
            this.totalRecords = 0;
            this.searchSegmentsAndEpisodes(this.lastSearchTerms);
        }
    }

    processEpisodes(episodes, episodeContainers) {
        let finalData = {};
        let episodeDurationMap = new Map(episodeContainers.map(ep => [ep.guid, parseInt(ep.data.duration || 0)]));
        // Scan through each episode,
        // find different keywords count and total number of keywords count in each existing time bucket
        episodes.forEach (episode => {
            let keywords = [];
            let maxTime = episodeDurationMap.get(episode['key']);
            episode['term_histogram']['buckets'].forEach(timeBucket => {
                // Fall back if episode is missing duration
                if (timeBucket['key'] > maxTime) {
                    maxTime = timeBucket['key']
                }
            })

            let differentKeywordsCountMap = new Map();
            let totalKeywordsCountMap = new Map();
            episode['term_histogram']['buckets'].forEach(timeBucket => {
                if (timeBucket['value']['buckets'] && timeBucket['value']['buckets'].length > 0) {
                    // Find number of different keywords count
                    differentKeywordsCountMap.set(timeBucket['key'], timeBucket['value']['buckets'].length);
                    // Find total number of keywords count
                    let totalDocCount = 0;
                    timeBucket['value']['buckets'].forEach(record => {
                        totalDocCount += record['doc_count']
                    })
                    totalKeywordsCountMap.set(timeBucket['key'], totalDocCount);
                }
            })
            // Use the episode duration (or from fall back option)
            // to fill in missing time intervals
            for (let time = 0; time <= maxTime; time += 120) {
                if (!(differentKeywordsCountMap.has(time))) {
                    differentKeywordsCountMap.set(time, 0)
                }
                if (!(totalKeywordsCountMap.has(time))) {
                    totalKeywordsCountMap.set(time, 0)
                }
            }
            // momentStrength array consists of a scale of strength which represents number of different keywords count (impacts graph color)
            // From 'very_strong' being the highest strength to 'very_weak' being the lowest strength
            // The total number of keywords count is assigned to each time interval for each strength (impacts graph height)
            // The toal number of keywords count is 0 at default
            let episodeData = [];
            let momentStrength = ['very_strong','strong', 'moderate', 'slightly_moderate', 'weak', 'very_weak'];
            momentStrength.forEach(strength => {
                let momentStrengthData = {}
                momentStrengthData['name'] = strength;
                momentStrengthData['series'] = [];
                for (let time = 0; time <= maxTime; time += 120) {
                    let seriesData = {};
                    let strengthValue = differentKeywordsCountMap.get(time) / this.possibleKeywords;
                    seriesData['name'] = time;
                    seriesData['value'] = 0;

                    if (strengthValue >= 1.0 && strength == 'very_strong') {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    if (strengthValue >= 0.8 && strengthValue < 1.0 && strength == 'strong') {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    if (strengthValue >= 0.6 && strengthValue < 0.8 && strength == 'moderate') {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    if (strengthValue >= 0.4 && strengthValue < 0.6 && strength == 'slightly_moderate') {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    if (strengthValue >= 0.2 && strengthValue < 0.4 && strength == 'weak') {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    if (strengthValue < 0.2 && strength == 'very_weak' ) {
                        seriesData['value'] = totalKeywordsCountMap.get(time);
                    }
                    momentStrengthData['series'].push(seriesData);
                }
                episodeData.push(momentStrengthData);
            })
            finalData[episode['key']] = episodeData;
        })
        return finalData
    }

}