import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { EntityType, Container } from '../../models';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'server-form',
    providers: [
        EntityTypeService
    ],
    templateUrl: 'server-form.component.html'
})
export class ServerFormComponent implements OnInit {
    @Input() container: Container;
    @Output() formSubmit: EventEmitter < any > = new EventEmitter();
    @Output() formFailed: EventEmitter < any > = new EventEmitter();
    @Output() formChangesScrapped: EventEmitter < any > = new EventEmitter();
    @Output() formCancelled: EventEmitter < any > = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];
    private entityType;
    private entityTypeId;
    public isLoading = false;

    private serverType = ['storage', 'application'];
    private protocolType = ['https', 'http', 's3', 'gs', 'sftp', 'ftp'];
    private roleTypes = ['source', 'destination', 'service', 'other'];
    private msgs: Message[] = [];

    constructor(
        private entityTypeService: EntityTypeService
    ) {}

    ngOnInit() {
        this.entityTypeId = this.container.type.id;

        if (!('locked' in this.container.data)) {
            this.container.data['locked'] = true;
        }

        this.isLoading = true;
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.entityType = (this.entityTypes.find(t => t.name === 'server'));
                    this.entityTypeId = this.entityType.id;
                    this.container.type = this.entityType;
                    console.log(res);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    onSubmit(e) {
        this.msgs = [];

        let valid = this.ngForm.form.status === 'VALID';

        if (!('name' in this.container.data) || (!this.container.data['name']) || !/\S/.test(this.container.data['name'])) {
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the server title.' });
            valid = false;
        }

        if (valid) {
            console.log('container submitted', this.container);
            this.formSubmit.emit({
                container: this.container
            });
        } else {
            this.formFailed.emit();
        }
    }

    onScrap(e) {
        this.formChangesScrapped.emit();
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
    }
}
