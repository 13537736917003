import { PermissionGroup, PermissionGroupDefinition } from './auth.types';
import { pipelineTypes as pipeTypes } from '../shared/enum';

export const apiPermGroup : PermissionGroupDefinition = {
    crud: {
        'container': 'Content Endpoint',
        'resource': 'Resource Endpoint',
        'segment': 'Segment Endpoint',
    },
    read: {
        'historicalcontainer': 'Content History',
        'historicalresource': 'Resource History',
    },
};

export const objectPermGroup : PermissionGroupDefinition = {
    write: {
        'all': 'All',
        'view': 'View',
        'rotator': 'Rotator',
        'collection': 'Collection',
        'series': 'Series',
        'season': 'Season',
        'episode': 'Episode',
        'media': 'Media',
        'movie': 'Movie',
        'campaign': 'Campaign',
        'content_group': 'Content Group',
        'moment': 'Moment',
        'document': 'Document',
    },
    read: {},
};

export const viewPermGroup : PermissionGroupDefinition = {
    show: {
        'content': 'Content',
        'curate' : 'Curate',
        'create': 'Create',
        'workflow': 'Workflow',
        'metrics': 'Metrics',
        'reports': 'Reports',
        'settings': 'Settings',
        'upload': 'Upload',
        'beta': 'Beta',
        'admin': 'Admin',
        'commerce': 'Commerce',
        'history': 'History',
        'revert': 'Revert',
    }
};

export const pipelinePermGroup : PermissionGroupDefinition = {
    type: pipeTypes,
    action: {
        'start': 'Restart/Start',
        'wait': 'Wait',
        'cancel': 'Cancel',
    },
};

export const allPermGroups = {
    api: apiPermGroup,
    view: viewPermGroup,
    object: objectPermGroup,
    pipeline: pipelinePermGroup,
};
