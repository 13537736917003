<page-header title="Edit Report{{!isLoading && container ? ': ' + container.data['label'] : ''}}" [showSpinner]="isLoading" [error]="error?.statusText"></page-header>
<div class="card card-w-title">
    <h1>Edit Report{{!isLoading && container ? ': ' + container.data['label'] : ''}}</h1>

    <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
    <p-messages [(value)]="requiredMsgs"></p-messages>
    <p-messages [(value)]="msgs"></p-messages>

    <div class="ui-g card-content" *ngIf="container && !isLoading">
        <report-form #form class="ui-g-12" [container]="container" (formSubmit)="onSubmit($event)" (formFailed)="onFailure($event)" (formChangesScrapped)="onScrap($event)" (formCancelled)="onBack($event)"></report-form>

        <hr>

            <div class="ui-g-12">
                <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
                    <p-header>
                        <span class="p-panel-title" style="font-size:16px;">Query Filters</span>
                    </p-header>
                    <json-editor #query_filters [options]="editorOptionsQueryFilters" [data]="queryfiltersObject"></json-editor>
                </p-panel>

                <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
                    <p-header>
                        <span class="p-panel-title" style="font-size:16px;">Filter Options</span>
                    </p-header>
                    <json-editor #filter_options [options]="editorOptionsFilterOptions" [data]="filteroptionsObject"></json-editor>
                </p-panel>
            </div>

            <div class="ui-g-12">
                <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
                    <p-header>
                        <span class="p-panel-title" style="font-size:16px;">Transform Options</span>
                    </p-header>
                    <json-editor #transform_options [options]="editorOptionsTransformOptions" [data]="transformOptionsObject"></json-editor>
                </p-panel>

                <p-panel class="ui-g-6 ui-md-6 ui-lg-6">
                    <p-header>
                        <span class="p-panel-title" style="font-size:16px;">Render Options</span>
                    </p-header>
                    <json-editor #render_options [options]="editorOptionsRenderOptions" [data]="renderOptionsObject"></json-editor>
                </p-panel>
            </div>

        <hr>

        <container-tags-panel [container]=container class="ui-g-12">
            </container-tags-panel>
        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
