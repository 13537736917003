import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EntityType, Container } from '../../models';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AppContext } from '../../../app.context';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class ContainerService {
    public static readonly statusList = [
        'new',
        'waiting',
        'processing',
        'error',
        'ready',
        'approved',
        'rejected',
        'scheduled',
        'published',
        'unpublished',
        'hidden',
    ];

    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;
    private baseSearchUrl = `${environment.PROXY_API_BASE_URL}/search/content`;
    private baseTemplateUrl = `${environment.PROXY_API_BASE_URL}/templates`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private messageService: MessageService,
        private appContext: AppContext,
    ) { }

    private constructOriginParameter(ignoreContext = false, overrideOrigin = '') {
        if (ignoreContext) {
            let originStr = ''
            for (let origin of overrideOrigin.split(',')) {
                originStr += `&origin=${origin}`
            }
            return originStr;
        }

        return this.appendOriginQueryParamFromContext(ignoreContext);

    }

    private appendOriginQueryParamFromContext(ignore = false) {
        if (!ignore) {
            return '&origin=' + this.appContext.activeOrigin
        }

        return ''

    }

    get(id, nest = 'full', depth = 1, filter = 'none'): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/${id}/?nest=${nest}&depth=${depth}&filter=${filter}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }

    getByGuid(guid, nest = 'full', depth = -1, searchResultsMode = false, all = false, ignoreOrigin = false): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let filter = all ? '&filter=none' : ''

        let originStr = ignoreOrigin
            ? this.constructOriginParameter(true, '*')
            : this.appendOriginQueryParamFromContext();

          if (searchResultsMode) {
            return this.http
              .get(
                  `${this.baseSearchUrl}/detailed/?guid=${guid}&limit=1&0ffset=0`,
                  { headers: headers }
              );
          }

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?guid=${guid}&nest=${nest}&depth=${depth}${filter}` + originStr,
                { headers: headers }
            ).pipe(
            map(
                (res: Array<any>) => {
                    if (!res && !res.length) {
                        throw new Error(`No container found with guid ${guid}`);
                    }
                    return res[0];
                }
            ));
    }

    getByName(name, nest = 'full', depth = 2): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        name = encodeURIComponent(name);

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?name=${name}&nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }

    getByTypeAndName(entityType: EntityType, name): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return Observable.create(new Container());
    }

    getRelatedByTypeAndGuidPaginated(typeName, guid, page, count, ordering = 'to_container__order,-created_date', origin='', status=''): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let url = `${this.baseTemplateUrl}/relatedcontainers/${guid}/?type=${typeName}&page=${page}&count=${count}&ordering=${ordering}`;

        if (origin.length > 0) {
          url += `&origin=${origin}`;
        }

        if (status.length > 0) {
          url += `&status=${status}`;
        }

        return this.http
            .get(
                url,
                { headers: headers }
            );
    }

    list(nest = 'full', depth = 1): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }

    getRelatedFromContainers(id, typeName, nest = 'full', depth = -1, overrideOrigin = true, includeAll = false): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let originStr = overrideOrigin
            ? this.constructOriginParameter(true, '*')
            : this.constructOriginParameter(false);

        let filterStr = includeAll ? '&filter=none' : '';

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?related_from=${id}&type=${typeName}&nest=${nest}&depth=${depth}${originStr}${filterStr}`,
                { headers: headers }
            );
    }

    filterByTypeName(typeName: string, nest = 'full', depth = 1, all = false, status: string | Array<string> = []): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let filter = all ? '&filter=none' : ''
        let query = `${this.baseUrl}/containers/search/?type=${typeName}&nest=${nest}&depth=${depth}${filter}` + this.appendOriginQueryParamFromContext();

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];

            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        return this.http
            .get(
                query,
                { headers: headers }
            );
    }

    filterByType(entityType: EntityType, nest = 'full', depth = 1): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?type=${entityType.name}&nest=${nest}&depth=${depth}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }

    save(container: Container): Observable<any> {
        console.log('save', container);
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());
        // headers.append('Accept',       'application/vnd.corus.api.core.services.proxy+json');

        let dateKeys = ['available_date', 'expiration_date', 'release_date', 'pub_date', 'published_date', 'reference_date', 'public_window_end_date', 'original_air_date', 'local_air_date', 'added_date'];

        for (let key of Object.keys(container.data)) {
          if (dateKeys.includes(key) && typeof(container.data[key])==='string' && container.data[key].length <= 19 && container.data[key].includes('T') && !container.data[key].endsWith('+00:00') && !container.data[key].endsWith('Z')) {
            container.data[key] += '+00:00';
          }
        }

        let resp;
        let data = JSON.stringify({
            data: container.data,
            type: container.type.id,
            guid: container.guid,
            tags: container.tags,
            origin: this.appContext.activeOrigin,
            status: container.status,
            published_date: container.published_date,
            is_enabled: container.is_enabled
        });

        // container doesn't exist yet
        if (container.id === -1) {
            console.log('post');
            console.log(data);
            resp = this.http
                .post(
                    `${this.baseUrl}/containers/`,
                    data, { headers: headers }
                ).pipe(
                tap(
                    res => {
                        container.id = res['id'];
                        container.guid = res['guid'];
                        container.origin = res['origin'];
                    }
                ));
        } else {
            console.log('patch');
            resp = this.http
                .patch(
                    `${this.baseUrl}/containers/${container.id}/`,
                    data, { headers: headers }
                );
        }

        return resp.pipe(tap(
            // At some point it might be worth moving all the "changes saved" messages
            // to here instead of per-form versions
            null,
            // Log error message from API
            err => this.showError(err),
        ));
    }

    delete(container: Container): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return Observable.create(true);
    }

    deleteById(id): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return Observable.create(true);
    }

    // TODO: search params
    // search(): Observable < any > {
    //     let headers = new HttpHeaders()
    //         .append('Accept', 'application/json')
    //         .append('Authorization', this.authService.getAuthHeader());

    //     return Observable.create(new Array < Container > ());
    // }

    search(limit: number, offset: number, terms: Array<string> = [], typeName = ''): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        var query = 'limit=' + limit + '&offset=' + offset;
        if (terms && terms.length > 0) {
            query += '&search=' + terms[0];
            for (var i = 1; i < terms.length; i++) {
                query += '|' + terms[i];
            }
        }
        if (typeName && typeName !== '') {
            query += '&type=' + typeName;
        }

        return this.http
            .get(
                // `${this.baseUrl}/search/?${query}`, {headers: headers}
                `${this.baseSearchUrl}/?${query}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }

    detailedSearch(limit: number, offset: number, terms: Array<string> = [],
        typeName = '', status: string | Array<string> = [], orderBy: Array<string> = [],
        origin: string = ''): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        var query = 'limit=' + limit + '&offset=' + offset;
        if (terms && terms.length > 0) {
            query += '&search=' + terms[0];
            for (var i = 1; i < terms.length; i++) {
                query += '|' + terms[i];
            }
        }
        if (typeName && typeName !== '') {
            query += `&type__in=${typeName}`;
        }

        var overrideOrigin = false

        if (origin && origin !== '') {
            overrideOrigin = true
        }

        // if (status) {
        //     query += `&status=${status}`;
        // }

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];

            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        if (orderBy && orderBy.length > 0) {
            for (let ord of orderBy) {
                query += `&ordering=${ord}`;
            }
        }

        return this.http
            .get(
                // `${this.baseUrl}/search/?${query}`, {headers: headers}
                `${this.baseSearchUrl}/detailed/?${query}` + this.constructOriginParameter(overrideOrigin, origin),
                { headers: headers }
            );
    }

    relate(fromContainer: Container, toContainer: Container, append = false): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let orderStr = append ? '?order=-1' : '';

        return this.http
            .post(
                `${this.baseUrl}/containers/${fromContainer.id}/relate/${toContainer.id}/${orderStr}`,
                JSON.stringify({}), { headers: headers }
            ).pipe(tap(
                null,
                err => {
                    if (err.status != '409'){
                        this.showError(err);
                    }
                    console.log('This is the error: ', err.status);

                }
            ));
    }

    relateById(fromContainerId: Number, toContainerId: Number, append = false): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let orderStr = append ? '?order=-1' : '';

        return this.http
            .post(
                `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/${orderStr}`,
                JSON.stringify({}), { headers: headers }
            ).pipe(tap(
                null,
                err => this.showError(err),
        ));
    }

    setRelationTagsById(fromContainerId: Number, toContainerId: Number, tags: Array<any> = []): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Content-Type', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let payload = {"tags": tags};

        return this.http
          .put(
              `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/`,
              JSON.stringify(payload), { headers: headers }
          ).pipe(tap(
              null,
              err => this.showError(err),
          ));
    }

    unrelate(fromContainer: Container, toContainer: Container): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/containers/${fromContainer.id}/relate/${toContainer.id}/`,
                { headers: headers }
            ).pipe(tap(
                null,
                err => this.showError(err),
        ));
    }

    unrelateById(fromContainerId: Number, toContainerId: Number): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .delete(
                `${this.baseUrl}/containers/${fromContainerId}/relate/${toContainerId}/`,
                { headers: headers }
            ).pipe(tap(
                null,
                err => this.showError(err),
        ));
    }

    reorder(parentContainer: Container, fromContainer: Container, toContainer: Container): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let toId = toContainer ? toContainer.id : 0;

        return this.http
            .put(
                `${this.baseUrl}/containers/${parentContainer.id}/reorder/${fromContainer.id}/${toId}/`,
                JSON.stringify({}), { headers: headers }
            ).pipe(tap(
                null,
                err => this.showError(err, 'Failed to reorder'),
        ));
    }

    getRandomId(): string {
        // No, not a UUID, just something to prefix things with
        // Should probably use the 'uuid' module at some point
        return [
            Math.random().toString(36).substring(2, 12),
            Math.random().toString(36).substring(2, 12),
        ].join('-');
    }

    getStatusLabelClass(status: string) {
        if (!status) {
            return '';
        }
        switch (status.toLowerCase()) {
            case 'waiting':
            case 'processing':
                return 'label-info';
            // case 'incomplete':
            case 'error':
            case 'rejected':
            case 'unpublished':
                return 'label-danger';
            // case 'warning':
            case 'ready':
            case 'approved':
                return 'label-primary';
            case 'scheduled':
                return 'label-warning';
            // case 'complete':
            case 'published':
                return 'label-success';
            default:
                return 'label-default';
        }
    }

    private showError(err, summary = 'Error') {
        const detail = (err.error && err.error.detail) || err.statusText;
        this.messageService.add({ key: 'api', severity: 'error', summary, detail });
    }

    private handleError(err) {
        console.error(err);
        return Promise.reject(err.message || err);
    }

    filterByTypeNameAndStatus(typeName: string, depth = -1, all = false, status: string | Array<string> = []): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        let filter = all ? '&filter=none' : ''

        var query = '';

        if (status && status.length > 0) {
            status = Array.isArray(status) ? status : [status];
            for (let st of status) {
                query += `&status=${st}`;
            }
        }

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?type=${typeName}&depth=${depth}${filter}${query}` + this.appendOriginQueryParamFromContext(),
                { headers: headers }
            );
    }
}
