import { Component, OnInit } from '@angular/core';
import { ContainerService } from '../../services/container/container.service';
//import { Container, EntityType } from '../../models';
//import { ContainerListComponent } from '../container-list/container-list.component';
//import { SpinnerComponent } from '../../../spinner/spinner.component';
//import { PageHeaderComponent } from '../../../ui/page-header.component';

@Component({
    selector: 'channels',
    providers: [
        ContainerService
    ],
    templateUrl: 'channels.component.html',
    styleUrls: ['channels.component.css']
})
export class ChannelsComponent implements OnInit {
    public containers = [];
    public isLoading: boolean = false;
    private error: string;
    //private sortOptions: Array<any>;
    public sortField: string;
    public sortOrder: number;
    private status: string = 'published';

    constructor(
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        // this.sortOptions = [
        //     {label: 'Title A-to-Z', value: 'data.title'},
        //     {label: 'Title Z-to-A', value: '!data.title'},
        //     {label: 'Newest to Oldest', value: '!created_date'},
        //     {label: 'Oldest to Newest', value: 'created_date'},
        // ];

        this.sortField = 'title';
        this.sortOrder = 1;
        this.loadPage(); 
    }

    loadPage() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'channel', this.status, [this.sortField])
            .subscribe(
                res => this.containers = res.results,
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onStatusSelectionChange($event) {
        this.status = $event.status;
        this.loadPage();
    }
}
