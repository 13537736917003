<spinner *ngIf="isLoading"></spinner>
<form *ngIf="container && !isLoading" #ngForm="ngForm" (submit)="onSubmit($event)">
    <div class="ui-g">
        <p-messages styleClass="w-100" [(value)]="msgs"></p-messages>

        <p-toolbar class="ui-g-12">
            <div class="ui-g-2">
                <button pButton type="button" label="{{container.id > 0 ? 'Back' : 'Cancel'}}" (click)="onCancel($event)" class="p-button-danger" icon="pi pi-arrow-circle-left" style="margin-right:4px;"></button>
                <button pButton type="button" label="Scrap Changes" (click)="onScrap($event)" class="p-button-danger" icon="pi pi-trash" style="margin-right:4px;"></button>
            </div>
            <div class="ui-g-10">
                <button pButton *ngIf="container.data.metadata_ingest_workflow_status == 'Warning'" type="button" (click)="onStatusComplete()" label="Mark QC Complete" class="p-button-success" icon="fa fa-thumbs-up" style="float:right;"></button>
                <button pButton type="submit" label="Save" icon="pi pi-check" style="float:right;"></button>
                <container-status-edit [container]="container" (onStatusChange)="onStatusChange($event)" style="float:right;margin-right:4px;"></container-status-edit>
            </div>
        </p-toolbar>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Basic Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="title">Title</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data['title']" name="title" placeholder="Required" required>
                </div>
                <div class="ui-g-6">
                    <label for="guid">GUID</label>
                    <input type="text" class="form-control" readonly [(ngModel)]="container.guid" name="guid">
                </div>
                <div class="ui-g-12">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.description" name="description" placeholder="Required" required>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Availability</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="publishDate" style="display:block;">Publish Date</label>
                    <p-calendar [(ngModel)]="publishDate" [showTime]="true" [inline]="false" [showIcon]="true" [disabled]="true" appendTo="body" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
                <div class="ui-g-6">
                    <label for="expirationDate">Expiration Date</label>
                    <input type="text" class="form-control p-widget" [(ngModel)]="container.data.expiration_date" name="expirationDate" disabled>
                </div>
            </div>
        </p-panel>
        <container-tags-panel [container]=container class="ui-g-12"></container-tags-panel>
        <p-panel class="ui-g-12 ui-md-12 ui-lg-8">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Additional Information</span>
            </p-header>

            
            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-8">
                    <label for="keywords">Keywords</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.keywords" name="keywords">
                </div>

                <div class="ui-g-4">
                    <label for="rating">Country of Origin</label>
                    <select [(ngModel)]="container.data.country_of_origin" name="country_of_origin" class="form-control">
                        <option *ngFor="let ccountry of country_of_origin" [value]="ccountry">{{ccountry}}</option>
                    </select>
                </div>
                <div *ngIf="container.id > 0" class="ui-g-12">
                    <history [inputObject]="container" inputType='container'></history>
                </div>
            </div>
        </p-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-4">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Details</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-6">
                    <label for="genre">Genre</label>
                    <select [(ngModel)]="container.data.genre" name="genre" class="form-control">
                        <option *ngFor="let ctype of genre" [value]="ctype.value">{{ctype.label}}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <container-subgenre [container]="container"></container-subgenre>
                </div>
                <div class="ui-g-6">
                    <label for="rating">Rating</label>
                    <select [(ngModel)]="container.data.tv_rating" name="rating" class="form-control">
                        <option *ngFor="let ctype of ratings" [value]="ctype">{{ctype}}</option>
                    </select>
                </div>
                <div class="ui-g-6">
                    <label for="airingnetwork">Aired on Network</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.airing_network" name="airingnetwork">
                </div>
                <div class="ui-g-6">
                    <label for="seriesType">Series Type</label>
                    <select [(ngModel)]="container.data.series_type" name="seriesType" class="form-control">
                        <option *ngFor="let ctype of seriesType" [value]="ctype">{{ctype}}</option>
                    </select>
                </div>
            </div>
        </p-panel>

        <view-metadata-form-panel [container]=container class="ui-g-12"></view-metadata-form-panel>

        <p-panel class="ui-g-12 ui-md-12 ui-lg-12">
            <p-header>
                <span class="p-panel-title" style="font-size:16px;">Identity Information</span>
            </p-header>

            <div style="padding:0.5em;overflow:hidden;">
                <div class="ui-g-2">
                    <label for="type">Type</label>
                    <select [(ngModel)]="entityTypeId" disabled name="type" class="form-control">
                        <option *ngFor="let entityType of entityTypes" [value]="entityType.id">{{ entityType.name }}</option>
                    </select>
                </div>
                <div class="ui-g-5">
                    <label for="houseId">House Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.houseId" name="houseId">
                </div>
                <div class="ui-g-5">
                    <label for="externalVendorId">External Vendor Id</label>
                    <input type="text" class="form-control" [(ngModel)]="container.data.externalVendorId" name="externalVendorId">
                </div>
            </div>
        </p-panel>
    </div>
</form>
