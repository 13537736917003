<div class="card card-w-title top-level-card">
    <h1>Edit Movie{{!isLoading && container ? ': ' + container.data['title'] : ''}}</h1>

    <page-header title="Edit Movie{{!isLoading && container ? ': ' + container.data['title'] : ''}}" 
                [showSpinner]="isLoading" 
                [error]="error?.statusText"></page-header>
    <pipeline-status *ngIf="container && !isLoading && isProcessing" 
                    [container]="container" 
                    [pipelineId]="pipelineId" 
                    (onStatusUpdate)="onStatusUpdate($event)"></pipeline-status>
    
    <div *ngIf="container && !isLoading && !isProcessing">
        <!--<p-growl [(value)]="requiredMsgs"></p-growl> -->
        <p-messages [(value)]="requiredMsgs"></p-messages>
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="ui-g card-content" *ngIf="container && !isLoading && !isProcessing">
        <movie-form #form
                    [container]="container"
                    (formSubmit)="onSubmit($event)"
                    (formFailed)="onFailure($event)"
                    (formChangesScrapped)="onScrap($event)"
                    (formCancelled)="onBack($event)"
                    (pipelineTriggered)="onPipelineTriggered($event)">
        </movie-form>

        <hr>

        <div class="ui-g-12">
            <resource-datarow  [resources]="container.resources" 
                               [parentContainer]="container" 
                               title="Resources" 
                               rows="10" 
                               filterBy="type.name" 
                               [sortOptions]="resourceSortOptions">
                <button pButton 
                        type="button" 
                        label="Upload" 
                        [routerLink]="['/containers', container?.id, 'resources', 'upload']" 
                        icon="pi pi-upload" 
                        *ngxPermissionsOnly="['view.show.upload']">                             
                </button>
                <trigger-pipeline-modal-form [container]=container 
                                            [pipelineTypes]="['resource', 'upload']" 
                                            *ngxPermissionsOnly="['object.write.all', 'object.write.movie']" 
                                            (pipelineTriggered)="onPipelineTriggered($event)">
                </trigger-pipeline-modal-form>
                <button pButton
                        type="button" 
                        label="Create" 
                        [routerLink]="['/containers', container?.id, 'resources', 'create']" 
                        icon="pi pi-plus">
                </button>
            </resource-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="media" itemsPerPage = 20 [parentContainer]="container" title="Media" filterBy="data.title, data.keywords" [sortOptions]="mediaSortOptions" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [disabled]="isJobInProgress()" [routerLink]="['/containers', container?.id, 'relate', 'media']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-paginated-datarow type="collection" itemsPerPage = 20 [parentContainer]="container" title="Collections" filterBy="data.title, data.keywords" [sortOptions]="collectionSortOptions" (reorder)="onReorder($event)">
                <button pButton type="button" label="Add" [routerLink]="['/containers', container?.id, 'relate', 'collection']" icon="pi pi-plus" style="float:right;margin-left:4px;height:100%;"></button>
            </container-paginated-datarow>
        </div>

        <div class="ui-g-12">
            <container-membership-row [container] = 'container' containerTypeName ="collection">
            </container-membership-row>
        </div>

        <div class="ui-g-12">
            <policy-table [containers]="policies" [parentContainer]="container" [modifyPermissions]="['view.show.admin']">
                <trigger-pipeline-modal-form [container]=container 
                                            [pipelineTypes]="['policy']" 
                                            *ngxPermissionsOnly="['object.write.all', 'object.write.movie']" 
                                            (pipelineTriggered)="onPipelineTriggered($event)">
                </trigger-pipeline-modal-form>
                <button pButton 
                        *ngxPermissionsOnly="['view.show.admin']"
                        type="button" 
                        label="Add" 
                        [routerLink]="['/containers', container?.id, 'relate', 'policy']" 
                        icon="pi pi-plus" 
                        style="float:right;height:100%;">
                </button>
            </policy-table>
        </div>

        <div class="ui-g-12">
            <platform-table [containers]="platforms" [parentContainer]="container">
                <button pButton 
                        type="button" 
                        label="Add" 
                        [routerLink]="['/containers', container?.id, 'relate', 'platform']" 
                        icon="pi pi-plus" 
                        style="float:right;height:100%;">
                </button>
            </platform-table>
        </div>

        <hr>

        <div class="ui-g-12">
            <object-advancedview [object]="container.data" title="Advanced Editing"></object-advancedview>
        </div>
    </div>
</div>
