import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Container } from '../../../content/models/container';
import { ContainerService } from '../../../content/services/container/container.service';
import { EntityTypeService } from '../../../content/services/entitytype/entitytype.service';
import { CommerceService } from '../../services/commerce.service';
import { ContentGroupService } from '../../services/content-group.service';
import { CASegmentMediaTableComponent } from '../segment-media-table/segment-media-table.component';
import { CAMomentTableComponent } from '../moment-table/moment-table.component';
import { takeUntil, switchMap, map } from 'rxjs/operators';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'content-group-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'content-group-edit.component.html',
    styleUrls: ['content-group-edit.component.css']
})
export class CAContentGroupEditComponent implements OnInit, OnDestroy {
    private initSubscr: any;
    public container: Container;
    private selectedMediacontainer: Container;
    private mediaContainerGUIDS = [];
    private moments;
    private momentEntityType;
    public error: HttpResponse<any>;
    public isLoading = false;
    private isReLoading = false;
    private isMomentLoading = false;
    private totalMoments = 0;
    private activeTimecode = 0;
    private activeChapter: Array<number>;
    private activeMoment: any;
    private allMomentsWithChapters: Array<string> = [];
    private allMediaWithMoments = {};
    private rawContainer: Container;
    private getRawContSubscr: Subscription;
    private entityTypeSubscr: Subscription;
    private getRelatedMoments$: Subscription;
    private getParentsForKwdsSubscr: Subscription;
    private saveMomentSubscr: Subscription;
    private removeMomentSubscr: Subscription;
    private saveContSubscr: Subscription
    private relateContainers$: Subscription;
    private unRelateContainers$: Subscription;
    private cgState: any;
    private destroy$ = new Subject();
    private segmentKeywords = [];
    private segmentOrigins = [];
    private guid;
    @ViewChild(CASegmentMediaTableComponent) mediaTableComponent;
    @ViewChild(CAMomentTableComponent) momentTableComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private commerceService: CommerceService,
        private cgService: ContentGroupService,
        private entityTypeService: EntityTypeService,
        private msgSrv: MessageService,
    ) {}

    ngOnInit() {
        console.log('group edit initialized');

        this.initSubscr = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);

            this.isLoading = true;
            this.containerService
                .get(id, 'full', 0, 'none')
                .subscribe(
                    res => {
                        this.container = res;
                        this.cgService.updateCgState({
                            'contentGroup': this.container,
                            'source': 'content-group-edit-2'
                        });
                        this.totalMoments = this.container.containers.length;
                    },
                    err => this.error = err,
                    () => {
                        this.isLoading = false;
                        this.getSegmentKeywords();
                        this.getSegmentOrigins();
                        this.getTypes();
                        this.initSubscr.unsubscribe();
                    }
                );
        });
    }

    ngAfterViewInit(): void {
        this.destroy$.next(false);
        this.createCgSub();
    }

    ngOnDestroy() {
        this.cgService.updateCgState({
            'autoplay': null,
            'activeMediaContainer': null,
            'source': 'cg-destroy'
        });
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private createCgSub(): void {
        this.cgService.getCgState().pipe(takeUntil(this.destroy$))
            .subscribe((cgState) => {
                this.cgState = cgState;
                console.log(this.cgState);
            });
    }

    getSegmentKeywords() {
        if ('segment_keywords' in this.container.data && this.container.data['segment_keywords']) {
            this.segmentKeywords = this.container.data['segment_keywords'];
            this.segmentKeywords = this.segmentKeywords.map(k => k.value);
        } else {
            this.segmentKeywords = [];
        }
    }

    getSegmentOrigins() {
        if ('origins' in this.container.data) {
            this.segmentOrigins = this.container.data['origins']
        } else {
            this.segmentOrigins = [];
        }
    }

    getTypes() {
        this.entityTypeSubscr = this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    let entityTypes = res;
                    this.momentEntityType = (entityTypes.find(t => t.name === 'moment'));
                    this.entityTypeSubscr.unsubscribe();
                },
                err => console.log(err),
                () => {}
            );

    }

    getRelatedMoments(mediaSource) {
        // When mediaSource = true,
        // Get all the moments of selected episode when the episode is selected
        // Also Update moments(moments table) when the new moment is created or existing moment is deleted
        if (mediaSource) {
            this.isMomentLoading = true;
            if (this.container && this.selectedMediacontainer) {
                this.getRelatedMoments$ = this.commerceService
                    .filterByPrimaryAndSecondaryParent(this.container.guid, this.selectedMediacontainer.guid, 'published')
                    .subscribe(
                        res => {
                            res.forEach((item) => {
                                if (item.data && item.data['secondary_parent_container_id']) {
                                    let parent_id = item.data['secondary_parent_container_id'];
                                    if (item.data.active_chapter) {
                                        if (!this.allMomentsWithChapters.includes(parent_id+"_"+item.data['active_chapter_number'])) {
                                            this.allMomentsWithChapters.push(parent_id+"_"+item.data['active_chapter_number']);
                                        }
                                    }
                                    this.addMomentToDictionary(parent_id, item.data['start_time_code']);
                                }
                            });
                        },
                        err => {
                            this.error = err;
                        },
                        () => {
                            this.getRawContainer(this.selectedMediacontainer.guid, 'handleMediaRowSelect');
                            this.isMomentLoading = false;
                            if (this.getRelatedMoments$) {
                                this.getRelatedMoments$.unsubscribe();
                            }
                            if (this.relateContainers$) {
                                this.relateContainers$.unsubscribe();
                            }
                            if (this.unRelateContainers$) {
                                this.unRelateContainers$.unsubscribe();
                            }

                        }
                    );
            } else {
                this.isMomentLoading = false;
            }
        }
        // When mediaSource = false,
        // Get all other moments of parent episode when the active moment is selected
        else {
            if (this.container && this.activeMoment) {
                this.getRelatedMoments$ = this.commerceService
                    .filterByPrimaryAndSecondaryParent(this.container.guid, this.guid, 'published')
                    .subscribe(
                        res => {
                            this.moments = res;
                            res.forEach((item) => {
                                if (item.data && item.data['secondary_parent_container_id']) {
                                    let parent_id = item.data['secondary_parent_container_id'];
                                    if (item.data.active_chapter) {
                                        if (!this.allMomentsWithChapters.includes(parent_id+"_"+item.data['active_chapter_number'])) {
                                            this.allMomentsWithChapters.push(parent_id+"_"+item.data['active_chapter_number']);
                                        }
                                    }
                                    this.addMomentToDictionary(parent_id, item.data['start_time_code']);
                                }
                            });
                        },
                        err => {
                            this.error = err;
                        },
                        () => {
                            this.getRawContainer(this.guid, 'handleMomentRowSelect');
                            if (this.getRelatedMoments$) {
                                this.getRelatedMoments$.unsubscribe();
                            }
                        }
                    );
            }
        }
    }

    createMoment() {
        let momentKey = this.selectedMediacontainer.guid + "_" + this.cgState['activeChapterNumber'];
        //if (this.cgState['userTimecode'] === -1 && this.cgState['playerTimecode'] > 0) {
        if (this.cgState['userTimecode'] === -1) {
            this.activeTimecode = this.cgState['playerTimecode'];
        } else {
            this.activeTimecode = this.cgState['userTimecode'];
        }

        if (this.allMomentsWithChapters.includes(momentKey)) {
            if (!confirm('A moment for this chapter has already been saved. Are you sure you want to save another?')) {
                return;
            }
        }

        let momentContainer = new Container();
        momentContainer.status = 'published';
        momentContainer.type = this.momentEntityType;
        momentContainer.data['label'] = "Moment at "+this.activeTimecode +" sec - "+ this.selectedMediacontainer.data['title'] +" - " + this.selectedMediacontainer.data['show_title'];
        momentContainer.data['start_time_code'] = this.activeTimecode;
        momentContainer.data['active_chapter'] = this.cgState['activeChapter'];
        momentContainer.data['active_chapter_number'] = this.cgState['activeChapterNumber'];
        momentContainer.data['primary_parent_container_id'] = this.container.guid;
        momentContainer.data['secondary_parent_container_id'] = this.selectedMediacontainer.guid;

        this.saveMomentSubscr = this.containerService
            .save(momentContainer)
            .subscribe(
                res => {
                    console.log('Container created response:', res);
                    console.log('Container created:', momentContainer);
                    this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Moment Saved', detail: ''});
                    let momentId = res['id'];
                    this.allMomentsWithChapters.push(momentKey);
                    this.addMomentToDictionary(momentContainer.data['secondary_parent_container_id'], momentContainer.data['start_time_code']);
                    this.cgService.updateCgState({
                        //'activeMoment': res,
                        'activeMoment': null,
                        'source': 'content-group-edit-3',
                        'userTimecode': this.activeTimecode,
                        'playerTimecode': 0,
                        'activeMediaMoments': this.allMediaWithMoments[momentContainer.data['secondary_parent_container_id']]
                    });
                    const relateContainers = combineLatest(
                        this.containerService.relateById(this.container.id, momentId),
                        this.containerService.relateById(this.selectedMediacontainer.id, momentId)
                    );
                    this.relateContainers$ = relateContainers.subscribe(([res1, res2]) => {
                        this.getRelatedMoments(true);
                    });
                },
                err => {
                    console.error(err);
                },
                () => {
                    this.isLoading = false;
                    this.saveMomentSubscr.unsubscribe();
                }
            );

    }

    handleMediaRowSelect(e) {
        console.log("media Row Selected", e);
        this.selectedMediacontainer = e['container'];
        this.activeTimecode = 0;
        const currentCgState = this.cgService.getCurrentCgState();
        if (currentCgState['skipPlay']) {
            this.activeTimecode = currentCgState['graphTimecode'];
        }
        this.activeMoment = null;
        this.getRelatedMoments(true);
        this.momentTableComponent.unselectTableItems();
    }

    handleMomentRowSelect(e) {   
        console.log("moment Row Selected", e);
        if (this.mediaTableComponent) {
            this.mediaTableComponent.unselectTableItems();
        }
        this.activeMoment = e['container']
        this.activeTimecode = this.activeMoment['data']['start_time_code'];
        this.guid = this.activeMoment['data']['secondary_parent_container_id'];
        this.getRelatedMoments(false);
    }

    handleMomentRemove(e) {
        let moment = e['container'];
        let parentContainer = this.container;
        let index = this.allMomentsWithChapters.indexOf(moment.data['secondary_parent_container_id']+"_"+moment.data['active_chapter_number']);
        if (index > -1) {
            this.allMomentsWithChapters.splice(index, 1);
            this.removeMomentFromDictionary(moment.data['secondary_parent_container_id'], moment.data['start_time_code'])
            if (this.selectedMediacontainer && moment.data['secondary_parent_container_id'] == this.selectedMediacontainer.guid) {
                this.cgService.updateCgState({
                    'activeMediaMoments': this.allMediaWithMoments[moment.data['secondary_parent_container_id']]
                });
            }
        }
        if (confirm('Are you sure you want to remove this moment?')) {
            console.log('remove', e, moment, parentContainer);
            moment.status = 'unpublished';
            this.removeMomentSubscr = this.containerService
                .save(moment)
                .subscribe(
                    res => {
                        const unRelateContainers = combineLatest(
                            this.containerService.getByGuid(moment.data['secondary_parent_container_id'], 'full', -1, false, true, true),
                            this.containerService.unrelate(parentContainer, moment)).pipe(switchMap(([res1, res2]) => {
                                return this.containerService
                                        .unrelate(res1, moment)
                                        .pipe(map((res3) => [res1, res2, res3]));
                        }));
                        this.unRelateContainers$ = unRelateContainers.subscribe(([res1, res2, res3]) => {
                            this.getRelatedMoments(true);
                        });
                    },
                    err => {
                        console.log(err);
                    },
                    () => {
                        this.removeMomentSubscr.unsubscribe();
                        this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Moment Removed', detail: '' });
                    }
                );
        }
    }

    getRawContainer(guid, source) {
        console.log('getRawContainer guid', guid);
        console.log('getRawContainer source', source);
        this.getRawContSubscr = this.containerService
            .getByGuid(guid, 'full', -1, false, true, true)
            .subscribe(
                res => {
                    this.rawContainer = res;
                    this.selectedMediacontainer = this.rawContainer;
                    let activeMediaMoments;
                    if (this.allMediaWithMoments[guid]) {
                        activeMediaMoments = this.allMediaWithMoments[guid];
                    }
                    this.cgService.updateCgState({
                        'activeMediaContainer': this.rawContainer,
                        'activeMoment': this.activeMoment,
                        'source': 'content-group-edit-1',
                        'userTimecode': this.activeTimecode,
                        'playerTimecode': 0,
                        'activeMediaMoments': activeMediaMoments,
                        'skipPlay': false
                    });
                },
                err => this.error = err,
                () => {
                    this.getRawContSubscr.unsubscribe();
                }
            );
    }

    onSubmit(e) {
        console.log('submit', e.model);
        this.isReLoading = true;
        this.saveContSubscr = this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.msgSrv.add({ key: 'ceMsg', severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                    this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'Error', detail: err.statusText });
                },
                () => {
                    this.isLoading = false;
                    this.saveContSubscr.unsubscribe();
                    this.getSegmentKeywords();
                    this.getSegmentOrigins();
                    this.isReLoading = false;
                }
            );
    }

    addMomentToDictionary(container_id, timecode) {
        if (this.allMediaWithMoments[container_id] && !this.allMediaWithMoments[container_id].includes(timecode)) {
            this.allMediaWithMoments[container_id].push(timecode);
        }
        if (!this.allMediaWithMoments[container_id]) {
            this.allMediaWithMoments[container_id] = [timecode];
        }
    }

    removeMomentFromDictionary(container_id, timecode) {
        if (this.allMediaWithMoments[container_id]) {
            let index = this.allMediaWithMoments[container_id].indexOf(timecode);
            if (index > -1) {
                this.allMediaWithMoments[container_id].splice(index, 1);
            }
        }
    }

    onCancel(e) {
        this.location.back();
    }

    onFailure(e) {
        this.msgSrv.add({ key: 'ceMsg', severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }
}
