<div class="card card-w-title top-level-card">
    <h1>Pipelines</h1>

    <p-table #dt [value]="pipelineContainers" 
            [rows]="20" 
            [paginator]="true" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <button pButton type="button" label="Create" [routerLink]="['/pipelines', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            <spinner *ngIf="isLoading"></spinner>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th>Description</th>
                <th>Bundle Id</th>
                <th style="width:8%">Type</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pipeline>
            <tr>
                <td>{{pipeline.data.name}}</td>
                <td>{{pipeline.data.description}}</td>
                <td>{{pipeline.data.bundle_id}}</td>
                <td>{{pipeline.data.pipeline_type}}</td>
                <td>
                    <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/pipelines', pipeline.id]"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="card card-w-title top-level-card">
    <h1>Workers</h1>

    <p-table #dt [value]="workerContainers" 
            [rows]="20" 
            [paginator]="true" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <button pButton type="button" label="Create" [routerLink]="['/workers', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            <spinner *ngIf="isLoading"></spinner>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th>Module Id</th>
                <th>Worker Id</th>
                <th style="width:8%">Execution</th>
                <th style="width:8%">Type</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-worker>
            <tr>
                <td>{{worker.data.name}}</td>
                <td>{{worker.data.bundle_id}}</td>
                <td>{{worker.guid}}</td>
                <td>{{worker.data.execution_type}}</td>
                <td>{{worker.data.worker_type}}</td>
                <td>
                    <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/workers', worker.id]"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="card card-w-title top-level-card">
    <h1>Servers</h1>

    <p-table #dt [value]="serverContainers" 
            [rows]="20" 
            [paginator]="true" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines" 
            [responsive]="true">
        <ng-template pTemplate="caption">
            <button pButton type="button" label="Create" [routerLink]="['/server', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            <spinner *ngIf="isLoading"></spinner>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th style="width:6%">Protocol</th>
                <th>Location</th>
                <th>Path</th>
                <th style="width:8%">Role</th>
                <th style="width:8%">Type</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-server>
            <tr>
                <td>{{server.data.name}}</td>
                <td>{{server.data.protocol_type}}</td>
                <td>{{server.data.location}}</td>
                <td>{{server.data.path}}</td>
                <td>{{server.data.role}}</td>
                <td>{{server.data.server_type}}</td>
                <td>
                    <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/server', server.id]"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="card card-w-title top-level-card">
    <h1>Profiles</h1>

    <p-table #dt [value]="profileContainers" 
            [rows]="20" [paginator]="true" 
            paginatorPosition="bottom" 
            selectionMode="single" 
            (onRowSelect)="onRowSelect($event)" 
            [(selection)]="container"
            styleClass="p-datatable-gridlines"
            [responsive]="true">
        <ng-template pTemplate="caption">
            <button pButton type="button" label="Create" [routerLink]="['/profiles', 'create']" icon="pi pi-plus" class="p-button-success" style="height:100%;float:right;"></button>
            <spinner *ngIf="isLoading"></spinner>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Name</th>
                <th>Description</th>
                <th>Bundle Id</th>
                <th style="width:8%">Type</th>
                <th style="width:5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-profile>
            <tr>
                <td>{{profile.data.name}}</td>
                <td>{{profile.data.description}}</td>
                <td>{{profile.data.bundle_id}}</td>
                <td>{{profile.data.profile_type}}</td>
                <td>
                    <button pButton type="button" class="p-button-primary" label="Edit" [routerLink]="['/profiles', profile.id]"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>