import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Container, Tag } from '../../models';
import { MenuItem } from 'primeng/api';
import { ContainerService } from '../../services/container/container.service';
import { TagService } from '../../services/tag/tag.service';
import { Message } from 'primeng/api';

@Component({
    selector: 'container-tags-panel',
    providers: [
        ContainerService,
        TagService
    ],
    templateUrl: 'container-tags-panel.component.html',
    styleUrls: ['container-tags-panel.component.css']
})

export class ContainerTagsPanelComponent implements OnInit {
    @Input() container: Container;
    @Output() onTagsChange: EventEmitter < any > = new EventEmitter();

    displayDialog: boolean = false;
    public isLoadingTags = false;
    public selectedTags: Tag[];
    private tags: Tag[];
    private tagsDict = {};
    public selectedTagsDict = {};
    private tagsTypes: any;

    constructor
        (
        private containerService: ContainerService,
        private tagService: TagService
        ) { }

    ngOnInit() {
        this.selectedTags = this.container.tags;
        this.fetchTags();
    }

    capitalize(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
    }

    showDialog() {
        this.displayDialog = true;
    }

    onChangeTags(){
        this.container.tags = this.selectedTags;
    }

    fetchTags() {
        this.isLoadingTags = true;
        this.tagService
        .list()
        .subscribe(
            res => {
                this.tags = res;
                this.tagsTypes = this.getTagTypes(this.tags);
                this.selectedTagsDict = this.splitTagsByType(this.selectedTags);
                this.tagsDict = this.splitTagsByType(this.tags);
            },
            err => console.log(err),
            () => this.isLoadingTags = false
        );

    }

    getTagTypes(tagArray) {
        let tagsTypes = [];
        tagArray.forEach(tag => {
            if (!(tag.type in tagsTypes)) {
                tagsTypes.push(tag.type);
            }

        });
        let tagSet = new Set(tagsTypes);
        return tagSet;
    }

    splitTagsByType(tagsArray) {
        let tagsDict = {};
        tagsArray.forEach(tag => {
            if (!(tag.type in tagsDict)) {
                tagsDict[tag.type] = [];
            }
            tagsDict[tag.type].push(tag);
        });
        return tagsDict;
    }

}
