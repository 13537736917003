<spinner *ngIf="isLoading"></spinner>
<div *ngIf="!isLoading" style="height:4px;"></div>
<p-toolbar>
    <div class="ui-g">
        <div class="ui-g-4">
            <p-dropdown [options]="entityTypes" (onChange)="onChangedFilteredType($event)" placeholder="No Type Filter" optionLabel="name" [showClear]="true" [ngModel]="selectedType" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
        </div>
        <div class="ui-g-4">
            <input style="text-align:center;height:100%;width:100%;" type="text" [(ngModel)]="searchTerm" (ngModelChange)="changedSearchTerm($event)" pInputText placeholder="Search">
        </div>
        <div class="ui-g-4">
            <p-dropdown [options]="statusList" (onChange)="onChangedFilteredStatus($event)" placeholder="No Status Filter" optionLabel="value" [showClear]="true" [ngModel]="selectedStatus" [style]="{'height':'100%','width':'100%'}"></p-dropdown>
        </div>
    </div>
</p-toolbar>
<p-table [columns]="columns" 
        [value]="containers" 
        [rows]="20" 
        [paginator]="true" 
        [first]="offset" 
        paginatorPosition="both" 
        dataKey="guid" 
        [lazy]="true" 
        (onLazyLoad)="loadResultsLazy($event)"
        styleClass="p-datatable-gridlines"
        [totalRecords]="searchResult.count">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 2.25em"></th>
            <th *ngFor="let col of columns" [ngStyle]="col.style">
                {{col.header}}
            </th>
            <th style="width: 13em">Published</th>
            <th style="width: 6em"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-container let-expanded="expanded" let-columns="columns">
        <tr *ngIf="!isLoading">
            <td>
                <a [pRowToggler]="container">
                    <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                </a>
            </td>
            <td *ngFor="let col of columns">
                <div style="word-wrap:break-word;" [innerHTML]="getFieldData(container, col)"></div>
            </td>
            <td>
                <div>{{container.published_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}</div>
            </td>
            <td>

                <button *ngIf="container.type == 'media'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/media', container.id,'']" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'season'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/seasons', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'episode'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/episodes', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'series'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/series', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'collection'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/collections', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'rotator'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'channel'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/channels', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'view'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/view', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'movie'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/movie', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                <button *ngIf="container.type == 'document'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/document', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>

                <div *ngxPermissionsOnly="['view.show.commerce']">
                  <button *ngIf="container.type == 'campaign'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/campaign', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'content_group'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/commerce/contentgroup', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                </div>

                <div *ngxPermissionsOnly="['view.show.settings']">
                  <button *ngIf="container.type == 'pipeline'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/pipelines', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'worker'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/workers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'server'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/server', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/profiles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'application'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/applications', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'catalog'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/catalog', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'configuration'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/configurations', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'distribution'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/distributions', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'entitlement'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'platform'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/platform', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'policy'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/policy', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'query'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'role'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/roles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'version'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/versions', container.id, '']" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'featured'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'integration'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'metric'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'origin'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/origins', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'theme'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'moment'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'provider'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/providers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'user_profile'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/user/profiles', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'dynamic'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                  <button *ngIf="container.type == 'report'" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/reports', container.id]" icon="pi pi-pencil" style="width:100%;font-size:13px;"></button>
                </div>

                <!-- <button *ngIf="(container.type != 'media') && (container.type != 'season') && (container.type != 'episode') && (container.type != 'series') && (container.type != 'collection') && (container.type != 'rotator') && (container.type != 'view')" pButton type="button" label="Edit" class="p-button-success" [routerLink]="['/containers', container.id]" icon="fa fa-wrench" style="width:100%;font-size:13px;"></button>
                -->
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-container let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 3">
                <div class="ui-g p-fluid" style="font-size:14px;padding:0.2em">
                    <div class="ui-g-2" style="text-align:center">
                        <img style="max-height:8em;width:auto;max-width:100%" src="{{container.thumbnail}}">
                    </div>
                    <div class="ui-g-4">
                        <div>Description</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container.description}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-4">
                        <div>Keywords</div>
                        <p-scrollPanel [style]="{width: '100%', height: '6em'}">
                            {{container.keywords}}
                        </p-scrollPanel>
                    </div>
                    <div class="ui-g-2">
                        <div>
                            <strong>Created:</strong><br>
                            {{container.created_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                        <br>
                        <!-- <hr/> -->
                        <div>
                            <strong>Updated:</strong><br>
                            {{container.updated_date | date:'yyyy-MMM-dd HH:mm:ss Z'}}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
