import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { UploadComponent } from '../../../upload/upload.component';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { NgForm } from '@angular/forms';
import { Message } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { Resource } from '../../models';
import { Container } from '../../models/container';
import { genre as genreList } from '../../../shared/enum';

import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'create-media-form',
    providers: [
        // ContainerService,
        // ResourceService,
        // EntityTypeService
    ],
    templateUrl: 'create-media-form.component.html',
    styles: [`
        .ui-steps .ui-steps-item {
            width: 100%;
            `]
})
export class CreateMediaFormComponent implements OnInit {
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    @Output() formFailed: EventEmitter<any> = new EventEmitter();
    @Output() formCancelled: EventEmitter<any> = new EventEmitter();
    @ViewChild('ngForm') ngForm: NgForm;
    private entityTypes = [];

    container: Container = new Container();

    private entityTypeId;
    public isLoading = false;
    private error: string;
    private selectedSeasonId;
    private selectedEntityType;

    private containerTypeName = 'episode';

    private seriesContainerId;
    private createdContainerId;
    private createdContainerGuid;

    items: MenuItem[];
    activeIndex: number = 0;

    seasons: any[] = [];
    series: any[] = [];

    private selectedSeries;
    selectedProfile: string;
    selectedTemplate: string;

    private templateProfiles = [];

    private contentTemplates = [];

    private ratings = ['TV-Y', 'TV-Y7', 'TV-G', 'TV-PG', 'TV-14', 'TV-MA'];
    private genre = genreList;

    public msgs: Message[] = [];

    constructor(
        private containerService: ContainerService,
        private resourceService: ResourceService,
        private entityTypeService: EntityTypeService
    ) { }


    resetUploadData()
    {
        this.container = new Container();
        this.setDefaultContainerObjectData(this.container);

        this.createdContainerId = null;
        this.createdContainerGuid = null;

        this.selectedSeasonId = "";
        this.selectedEntityType = "";

        this.selectedSeries  = "";
        this.selectedProfile = "";
        this.selectedTemplate  = "";

        this.activeIndex = 0;
    }

    ngOnInit() {
        this.items = [{
            label: 'Basic Information',
            command: (event: any) => {
                this.activeIndex = 0;
            }
        },
        {
            label: 'Availability & Metadata',
            command: (event: any) => {
                this.activeIndex = 1;
            }
        }
        ];

        this.getEntityData();
        this.getSeriesData();
        this.getTemplateProfiles();
    }

    setDefaultContainerObjectData(container)
    {
        container.data =
        {
            title: '',
            houseId: '',
            description: '',
            genre: '',
            tv_rating: '',
            episode_number: '',
            available_date: null,
            expiration_date: null,
            public_window_end_date: null,
            original_air_date: null,
            local_air_date: null,
            release_date: null,
            keywords: '',
            locked: false,
            exclude_platforms: '',
            primary_parent_container_id: '',
            secondary_parent_container_id: '',
            externalVendorId: ''
        };
    }

    getEntityData() {
        this.entityTypeService
            .list()
            .subscribe(
                (res: any) => {
                    this.entityTypes = res;
                    this.onTypeChange(this.containerTypeName);
                },
                err => console.log(err),
                () => this.isLoading = false
            );
    }

    getSeriesData() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'series', ['published','unpublished','scheduled'], ['title'])
            .subscribe(
                res => {
                    this.series = res.results;
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    getSeasonData(guid) {
        this.isLoading = true;
        this.containerService
            .getRelatedByTypeAndGuidPaginated('season', guid, 1, 100)
            .subscribe(
                res => {
                    this.seasons = [];
                    res.forEach(element => {
                        this.seasons.push(element);
                        //element['show'] = true;
                    });

                },
                err => this.error = err,
                () => this.isLoading = false
            );
    }

    getTemplateProfiles() {
        this.isLoading = true;
        this.containerService
            .detailedSearch(1000, 0, [], 'profile', 'published', ['title'])
            .subscribe(
                res => {
                    this.templateProfiles = (res.results.filter(t => t.data.profile_type === 'template'));
                    for (let template of this.templateProfiles) {
                        var templateValue = { label: template['data']['name'], value: template['data']['bundle_id'] };
                        this.contentTemplates.push(templateValue);
                    }
                },
                err => {
                    console.log(err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onSeasonChange() {
        if (this.selectedSeasonId) {
            var seasonContainer = this.seasons.find(t => t.id === parseInt(this.selectedSeasonId, 10));
            this.container.data['secondary_parent_container_id'] = seasonContainer.guid;
        }
    }

    onTemplateChange() {
        this.container = new Container();
        this.setDefaultContainerObjectData(this.container);
        var selectedTemplateContainer = this.templateProfiles.find(t => t.id === parseInt(this.selectedTemplate, 10));
        // Handle legacy stringified JSON payloads
        var jsonPayload;
        if (typeof selectedTemplateContainer.data['payload'] == 'string') {
            jsonPayload = JSON.parse(selectedTemplateContainer.data['payload']);
        } else {
            jsonPayload = selectedTemplateContainer.data['payload']
        }
        this.container = Object.assign(this.container, jsonPayload.container);

        if (!('published_date' in jsonPayload.container)) {
            this.container.published_date = null;
        }
        if ('type' in jsonPayload.container) {
            this.onTypeChange(jsonPayload.container.type);
        }
        this.selectedSeries = "";
        this.selectedSeasonId = "";

        // Handle placeholder available date
        if (this.container.availableDate === null) {
            this.container.availableDate = new Date();
        }
        // TODO: allow ISO interval/duration format and calc expiration date
    }

    onSeriesChange() {
        var seriesContainer = this.series.find(t => t.id === parseInt(this.selectedSeries, 10));

        this.seriesContainerId = seriesContainer.id
        this.container.data['title'] = seriesContainer.data.title;
        this.container.data['show_title'] = seriesContainer.data.title;
        this.container.data['tv_rating'] = seriesContainer.data.tv_rating;
        this.container.data['genre'] = seriesContainer.data.genre;
        this.container.data['keywords'] = seriesContainer.data.keywords;
        this.container.data['primary_parent_container_id'] = seriesContainer.guid;

        this.getSeasonData(this.container.data['primary_parent_container_id']);
        this.onSeasonChange();
    }

    onTypeChange(containerTypeName) {
        this.selectedEntityType = (this.entityTypes.find(t => t.name === containerTypeName));
        this.entityTypeId = this.selectedEntityType.id;
        this.container.type = this.selectedEntityType;
    }

    onSubmit(e) {
        var season = this.seasons.find(t => t.guid === this.selectedSeasonId);
        if ((season) && ('season_number' in season.data)) {
            this.container.data['season_number'] = season.data['season_number'];
        }

        this.msgs = [];
        /* 
        if (!('title' in this.container.data) || (!this.container.data['title']) || !/\S/.test(this.container.data['title'])) {
            //this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the episode title.' });
        }
        if (!('episode_number' in this.container.data) || (!this.container.data['episode_number']) || !/\S/.test(this.container.data['episode_number'])) {
            // this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the episode number.' });
        }
        if (!('season_number' in this.container.data) || (!this.container.data['season_number']) || !/\S/.test(this.container.data['season_number'])) {
            // this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Please enter the season number.' });
        } */

        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    console.log('Container created:', this.container);
                    this.msgs = [];
                    this.msgs.push({ severity: 'success', summary: 'Media Object Created', detail: '' });
                    this.createdContainerId = res['id'];
                    this.createdContainerGuid = res['guid'];
                    /*
                    this.containerService
                    .relate(this.seriesContainerId, res)
                    .subscribe(
                        res => {
                            console.log(res);
                            this.msgs.push({ severity: 'success', summary: 'Media Object Added to series', detail: '' });

                        },
                        err => console.log(err)
                    );
                    */
                    this.formSubmit.emit({
                        container: this.container
                    });
                },
                err => {
                    console.log(err);
                    // this.error = err.statusText;
                },
                () => this.isLoading = false
            );

        var valid = this.ngForm.form.status === 'VALID';
        valid = true;
        if (valid) {
            console.log('container submitted', this.container);
        } else {
            this.formFailed.emit();
        }
    }

    onStatusComplete() {
        // this.container.data['metadata_ingest_workflow_status'] = 'Complete';
        this.resetUploadData();
        this.formSubmit.emit({
            container: this.container
        });
    }

    nextStep() {
        if (this.activeIndex < 4) {
            this.activeIndex = this.activeIndex + 1;
        }
    }

    prevStep() {
        if (this.activeIndex > 0) {
            this.activeIndex = this.activeIndex - 1;
        }
    }

    isPristine() {
        return this.ngForm.form.pristine;
    }

    onCancel(e) {
        this.formCancelled.emit();
        this.resetUploadData();
    }
}


