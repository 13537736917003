import { environment } from '../environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { ContainerService } from './content/services/container/container.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AppContext {
    public activeOrigin: string = '';
    //TODO: Should load/save component states between sessions on local store
    public componentState = {}
    public activeOriginObject = {}
    public originContainers = [];
    private isLoadingOrigin = false;
    //TODO: Make struc of model to store metadata logo, etc..
    public authorizedOrigins: any[] = [];

    public originObjects = {};

    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private router: Router) {
        this.activeOrigin = this.getActiveOrigin();
        this.activeOriginObject = this.getActiveOriginObject();

        this.componentState['search_view'] = {};
        this.componentState['policy_view'] = {};
        this.componentState['provider_view'] = {};
        this.componentState['sort_order'] = {};
        this.componentState['pipeline_list'] = {};
        this.componentState['report_view'] = {};
        this.componentState['history_view'] = {};

        this.authService.userState.subscribe(
            res => {
                if (res.id) {
                    this.isLoadingOrigin = true;
                    this.parseAuthorizedOrigins(res);
                    this.filterByTypeName()
                        .subscribe(
                            res => {
                                this.originContainers = res;
                                console.log(res);
                                // Reset this.originObjects?
                                this.originContainers.forEach((element, index) => {
                                    this.originObjects[element.data['origin_id']] = element.data;
                                })
                                console.log("origins loaded");
                                console.log(this.originObjects);

                                let oldOrigin = this.activeOrigin;
                                this.setActiveOrigin(this.activeOrigin);
                                if (oldOrigin != this.activeOrigin) {
                                    this.reloadOnOriginChange();
                                }
                            },
                            err => {
                                console.log(err);
                            },
                            () => this.isLoadingOrigin = false
                        );
                }
            },
            err => { console.log(err) },
            () => { }
        )
    }

    private emptyOrigin(): Object {
        return {
            'origin_id': '',
            'logo_url': '',
        }
    }

    private parseAuthorizedOrigins(user: Object) {
        let permissions = user['permissions'] || {};
        this.authorizedOrigins = Object.keys(permissions).sort();
        console.log(this.authorizedOrigins);
    }

    filterByTypeName(): Observable<any> {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/containers/search/?type=origin&nest=full&depth=-1&origin=system`,
                { headers: headers }
            );
    }

    prepareViewDataOnOriginSwitch()
    {
        //console.log("I am here.....");
        if (this.componentState['search_view']) {
            this.componentState['search_view']['offset'] = 0;
        }

    }

    reloadOnOriginChange() {
        this.prepareViewDataOnOriginSwitch();

        let urlComponents = this.router.url.split('/');

        if (urlComponents.length <= 2) {
            this.router.navigate([this.router.url]);
        }
        else {
            this.router.navigate([ '/' + urlComponents[1]]).then(data => {
                console.log('Route exists, redirection is done');
              })
              .catch(e => {
                this.router.navigate(['/']);
              });;
        }
    }

    onSelectNewOriginClick(event: Event, origin: string) {
        if (this.activeOrigin === origin) return;
        event.preventDefault();
        this.setActiveOrigin(origin);
        this.reloadOnOriginChange();
    }

    getActiveOrigin() {
        const activeOrigin = localStorage.getItem('active_origin');
        return activeOrigin;
    }

    getActiveOriginObject() {
        let activeOriginString = localStorage.getItem('active_origin_object')
        let activeOriginObject = this.emptyOrigin();
        if (activeOriginString) {
            activeOriginObject = JSON.parse(activeOriginString);
        }
        return activeOriginObject;
    }

    setActiveOrigin(activeOrigin) {
        if (!activeOrigin || !this.authorizedOrigins.includes(activeOrigin)) {
            activeOrigin = this.authorizedOrigins[0] || '';
        }
        this.activeOrigin = activeOrigin;
        localStorage.setItem('active_origin', activeOrigin);
        this.activeOriginObject = this.originObjects[activeOrigin];
        if (this.activeOriginObject) {
            localStorage.setItem('active_origin_object', JSON.stringify(this.activeOriginObject));
        }
        else {
            this.activeOriginObject = this.emptyOrigin();
            localStorage.setItem('active_origin_object', '');
        }
        console.log('origin:', this.activeOrigin);
        this.authService.setPermissionsOrigin(this.activeOrigin);
    }

    getOriginLabel(origin) {
        let originObj = this.originObjects[origin];
        if (originObj && originObj['label']) {
            return originObj['label'];
        }
        return origin;
    }

}
