import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ResourceService } from '../../services/resource/resource.service';
import { ResourceFormComponent } from '../../forms/resource-form/resource-form.component';
import { Resource } from '../../models';
import { Message } from 'primeng/api';

@Component({
    selector: 'resource-create',
    providers: [
        ResourceService
    ],
    templateUrl: 'resource-create.component.html',
    styleUrls: ['resource-create.component.css']
})
export class ResourceCreateComponent implements OnInit {
    @ViewChild('form') form: ResourceFormComponent;
    private sub: any;
    public resource: Resource = new Resource();
    private containerId;
    private error: string;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public resourceService: ResourceService
    ) {}

    ngOnInit() {
        console.log('resource create init');

        this.sub = this.route.params.subscribe(
            params => {
                let id = parseInt(params['containerId'], 10);
                console.log('id', id);
                this.containerId = id;
            },
            err => this.error = err
        );
    }

    onSubmit(e) {
        console.log('resource create submit', e);

        this.resourceService
            .save(this.resource, this.containerId)
            .subscribe(
                res => {
                    console.log(res);
                    this.location.back();
                },
                err => this.error = err
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this new collection?')) {
                this.form.resetToDefaultState();
            }
        }
    }
}
