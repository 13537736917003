
import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { ContainerService } from '../../services/container/container.service';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Container } from '../../models/container'
import { AppContext } from '../../../app.context';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'catalog-modal-form',
    providers: [
        ContainerService,
        AuthService,
        DatePipe
    ],
    templateUrl: 'catalog-modal-form.component.html'
})

export class CatalogModalFormComponent implements OnInit {
    public static readonly catalogOrigins = [
        "system",
    ];

    @Input() container: Container;
    @Input() catalogTypes: Array<string>;
    @ViewChild('ngForm') ngForm: NgForm;

    public displayDialog: boolean = false;
    public isLoading = false;
    public catalogs: any[] = [];
    private catalogContainer;
    public selectedCatalogId;
    public isDisabled = true;
    public url;

    constructor(
        private containerService: ContainerService,
        private authService: AuthService,
        private appContext: AppContext,
        private datepipe: DatePipe
     ) {}

    ngOnInit() {
        this.getCatalogData(this.catalogTypes);
    }

    resetFormData() {
        this.selectedCatalogId = '';
    }

    checkExport() {
        if (this.catalogs.length && this.container && this.container.id > 0) {
            this.isDisabled = false;
        }
    }

    showDialog() {
        this.resetFormData();
        this.displayDialog = true;
    }

    closeDialog() {
        this.displayDialog = false;
    }

    getCatalogData(catalogTypes) {
        this.isLoading = true;
        this.catalogSearch(catalogTypes)
           .subscribe(
               res => {
                   this.catalogs = res;
                   this.checkExport();
               },
               err => {
                   console.log('error', err);
               },
               () => this.isLoading = false
           );
    }

    catalogSearch(catalogTypes: Array<string> = []) {
        function isCatalogValid(c) {
            return c.data && c.data.template;
        }

        function compareCatalogTypes(c, catalogTypes) {
            if (catalogTypes && catalogTypes.length) {
                return catalogTypes.includes(c.data.catalog_type);
            } else {
                return true;
            }
        }

        let origins = CatalogModalFormComponent.catalogOrigins.slice();
        if (!origins.includes(this.appContext.activeOrigin)) {
            origins.push(this.appContext.activeOrigin);
        }

        return this.containerService
            .detailedSearch(1000, 0, [], 'catalog', 'published', ['title'], origins.join(',')).pipe(
            map(
                res => {
                    return res.results.filter(
                        c => (
                            isCatalogValid(c)
                            && compareCatalogTypes(c, catalogTypes)
                        )
                    );
                }
            ));
    }

    onCatalogChange() {
        this.catalogContainer = this.catalogs.find(t => t.id === parseInt(this.selectedCatalogId, 10));
        this.constructCatalogUrl();
    }

    constructCatalogUrl() {
        let baseUrl = environment.PROXY_API_BASE_URL;
        let query = 'guid=' + this.container.guid

        if (this.catalogContainer.data['catalog_type'] == 'export_campaign') {
            let date = this.datepipe.transform(new Date(), 'MM_dd_yyyy');
            let filename = this.container.data['label'] + '_' + date + '.csv';
            query += '&filename=' + filename
        }

        this.url = `${baseUrl}/templates/catalog/${this.catalogContainer.guid}/?${query}`
    }

    exportCatalog() {
        this.closeDialog();
    }

}
