import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { Container } from '../../../content/models/container';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { MapToIterablePipe } from '../../../content/components/container-create/maptoiterable.pipe';
import { VersionFormComponent } from '../../forms/version-form/version-form.component';
import { Message } from 'primeng/api';

@Component({
    selector: 'version-create',
    providers: [
        ContainerService
    ],
    templateUrl: 'version-create.component.html',
    styleUrls: ['version-create.component.css']
})
export class VersionCreateComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: VersionFormComponent;
    private sub: any;
    public container: Container = new Container();
    private parentContainer: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    private requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('version create initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['distId']; // + converts string to number
            console.log('distId', id);
            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        this.parentContainer = res;
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
    }

    ngOnDestroy() {

    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        let newContId;
        this.containerService
            .save(this.container)
            .subscribe(
                function (res) {
                    console.log("Created: ", res);
                    newContId = res.id;
                    this.containerService
                        .relate(this.parentContainer, res)
                        .subscribe(
                            res => {
                                console.log("Related: ", res);
                                this.router.navigate(['/versions', newContId,  this.parentContainer.id], { replaceUrl: true });
                            },
                            function (err) {
                                // TODO: Fix this fallback deletion, it doesn't delete the container
                                console.log("Failed to relate: ", err);
                                this.error = err.statusText;

                                this.containerService
                                    .delete(res);
                                this.location.back();
                            }.bind(this)
                        );
                }.bind(this),
                err => {
                    console.log("Failed to create: ", err);
                    this.error = err.statusText;
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this new collection?')) {
                this.form.resetToDefaultState();
            }
        }
    }
}
