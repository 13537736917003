import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse as Response } from '@angular/common/http';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ContainerService } from '../../../content/services/container/container.service';
import { JobsService } from '../../../content/services/jobs/jobs.service';
import { Container } from '../../../content/models/container';
import { Tag } from '../../../content/models/tag';
import { Status } from '../../../content/models/status';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message, MessageService } from 'primeng/api';
import { CACampaignFormComponent } from '../../forms/campaign-form/campaign-form.component';


@Component({
    selector: 'campaign-edit',
    providers: [
        ContainerService,
        JobsService
    ],
    templateUrl: 'campaign-edit.component.html',
    styleUrls: ['campaign-edit.component.css']
})
export class CACampaignEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: CACampaignFormComponent;
    private sub: any;
    public container: Container;
    public error: Response<any>;
    public isLoading = false;
    public isLoadingEntityTypes = false;
    public msgs: Message[] = [];
    private jobInProgress: boolean = false;
    private jobStatus: Status;
    private job_status_msgs: Message[] = [];
    private job_status_sub: any;
    private job_prev_status = 'default';
    private contentgroupSortOptions: Array<any>;
    public isProcessing = false;
    private pipelineId = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService,
        private jobsService: JobsService,
        public requiredMsgs: MessageService
    ) {}

    ngOnInit() {
        console.log('container edit initialized');

        this.contentgroupSortOptions = [
            {label: 'Stored Order', value: ''},
            {label: 'Creation Order', value: '!created_date'},
            {label: 'Reverse Creation Order', value: 'created_date'},
        ];

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);
            this.isLoading = true;
            this.containerService
                .get(id, 'full', -1, 'none')
                .subscribe(
                    res => {
                        this.container = res;
                        console.log("container_edit",res);
                        this.validateFormData();
                        this.checkProcessingStatus();
                        this.containerService
                        .getRelatedByTypeAndGuidPaginated('content_group', this.container.guid, 1, 200)
                        .subscribe(
                            res => {
                                this.isLoading = false
                                this.container.containers = res;
                                console.log("content_group container", this.container.containers);
                            },
                            err => this.error = err,
                            () => this.isLoading = false

                        )
                    },
                    err => this.error = err,
                    () => this.isLoading = false
                );
        });
        console.log(this.sub);
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    onPipelineTriggered(event) {
        console.log(event);
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id, 'full', -1, 'none')
            .subscribe(
                res => {
                    this.container = res;
                    this.validateFormData();
                    this.checkProcessingStatus();
                    this.containerService
                    .getRelatedByTypeAndGuidPaginated('content_group', this.container.guid, 1, 200)
                    .subscribe(
                        res => {
                            this.isLoading = false
                            this.container.containers = res;
                            console.log("content_group container", this.container.containers);
                        },
                        err => this.error = err,
                        () => this.isLoading = false

                    )
                },
                err => this.error = err,
                () => this.isLoading = false
            );
        
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    
    isJobInProgress() {
        return this.jobInProgress;
    }

    onEditComplete(e) {
        this.container.data[e.data.key] = e.data.val;
    }
  
    validateFormData() {
        this.msgs = [];

        if (!('label' in this.container.data)) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'No label. Please enter the label.' });
        }
        if (!('order_id' in this.container.data)) {
            this.msgs.push({ severity: 'warn', summary: 'Warning', detail: 'No order_id. Please enter the order_id.' });
        }
    }

    onSubmit(e) {
        console.log('submit', e.model);
        // this.container.data = e.model;
        this.requiredMsgs.clear();
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    console.log(res);
                    this.requiredMsgs.add({ key: 'cpMsg', severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false
            );
        this.validateFormData();
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs.clear();
        this.requiredMsgs.add({ key: 'cpMsg', severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this series?')) {
                this.refetchContainer();
            }
        }
    }
}