
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import { ResourceFormComponent } from '../../forms/resource-form/resource-form.component';
import { ResourceService } from '../../services/resource/resource.service';
import { EntityTypeService } from '../../services/entitytype/entitytype.service';
import { Resource, EntityType } from '../../models';
import { Message } from 'primeng/api';


@Component({
    selector: 'resource-edit',
    providers: [
        ResourceService,
        EntityTypeService,
    ],
    templateUrl: 'resource-edit.component.html',
    styleUrls: ['resource-edit.component.css']
})
export class ResourceEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: ResourceFormComponent;
    private sub: any;
    public resource: any;
    public error: HttpResponse<any>;
    public isLoading = false;
    private containerId;
    public resourceId;
    private entityType : EntityType;
    private entityTypes = [];
    public msgs: Message[] = [];
    public requiredMsgs: Message[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public resourceService: ResourceService,
        private entityTypeService: EntityTypeService,
    ) {}

    get displayPreview() {
        if (this.isLoading || !this.resource) return false;
        return this.resourceService.displayPreview(this.resource.type.name);
    }

    ngOnInit() {
        console.log('container edit initialized');

        this.sub = this.route.params.subscribe(params => {
            this.containerId = +params['containerId'];
            this.resourceId = +params['resourceId']; // + converts string to number
            console.log('id', this.resourceId);

            this.isLoading = true;
            this.entityTypeService
                .list()
                .subscribe(
                    (res: any) => {
                        this.entityTypes = res;
                        this.refetchResource();
                    },
                    err => console.log(err)
                );
        });

    }

    refetchResource() {
        this.isLoading = true;
        this.resourceService
            .get(this.resourceId).pipe(
            tap((res) => {
          return res;
        }))
            // mock context data end
            .subscribe(
                res => {
//                    console.log('%%% rez', res);
                    this.resource = res;
                    if (typeof this.resource.type == 'number') {
                        this.resource.type = this.entityTypes.find(t => t.id === this.resource.type);
                    }
                    this.isLoading = false;
                },
                err => this.error = err
            );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        console.log('resource edit heard submit', e);
        this.resource.uri = e.resource.uri;
        this.resource.type = e.resource.type;
        console.log('resource to be saved', this.resource);
        this.resourceService.save(this.resource, this.containerId)
            .subscribe(
                res => {
                    console.log(res);
                    this.location.back();
                },
                err => console.log(err),
                () => console.log('done')
            );
    }

    onBack(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this resource?')) {
                this.refetchResource();
            }
        }
    }
}
