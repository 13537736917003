import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Container, Resource, History, HistoryDiff, ContainerHistory } from '../../models';
import { HistoryService } from '../../services/history/history.service';
import { ContainerService } from '../../services/container/container.service';
import { ResourceService } from '../../services/resource/resource.service';
import { SpinnerComponent } from '../../../spinner/spinner.component';
import { PageHeaderComponent } from '../../../ui/page-header.component';
import * as JSONStableStringify from 'json-stable-stringify';

@Component({
    selector: 'history-revert',
    providers: [
        HistoryService
    ],

    templateUrl: 'history-revert.component.html',
    styleUrls: ['history-revert.component.css']
})

export class HistoryRevertComponent implements OnInit {

    //@Input() objectType;
    //@Input() objectId;
    //@Input() historyId;

    public displayDialog: boolean = false;
    private isLoading = false;
    private isLoadingFetchObject = false;
    private sub: any;
    private objectId;
    public objectType;
    public historyId;
    private currentHistoryId;
    private prevHistoryId;
    public targetHistoryDate;
    private currentObject: ContainerHistory = new ContainerHistory();
    private oldObject: ContainerHistory = new ContainerHistory();
    private diffChanges: any;
    public currentObjectString: string = 'examplejson';
    public oldObjectString: string = 'examplejson';
    private diffField: any;
    private oldValue: any;
    private currentHistoryUser: any;
    private currentHistoryDate: string = '';

    constructor(
        private historyService: HistoryService,
        private containerService: ContainerService,
        private resourceService: ResourceService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() { 

        this.sub = this.route.params.subscribe(params => {
            this.objectId = +params['objectId'];
            this.objectType = params['type'];
            this.historyId = +params['historyId'];
            this.currentHistoryId = +params['latestHistoryId'];
            });
        if (this.currentHistoryId != this.historyId) {
            this.getDiff();    
        }
        else {
            this.fetchObject();
        }
         

    }

    getDiff(){

        this.isLoading = true;

        this.historyService
            .getHistoryDiff(this.objectId, this.objectType, this.currentHistoryId, this.historyId)
            .subscribe(
                res => {
                    this.prevHistoryId = res.old_history_id;
                    this.targetHistoryDate = new Date(res.old_history_date).toLocaleString();
                    this.diffChanges = res.changes;
                    this.fetchObject();
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false

            );

    }

    fetchObject(){
        this.isLoadingFetchObject = true;

        this.historyService
            .retrieveHistory(this.objectId, this.objectType, this.currentHistoryId)
            .subscribe(
                res => {
                    this.currentObject = res;
                    //this.currentHistoryUser = this.currentObject['history_user'];
                    //this.currentHistoryDate = new Date(this.currentObject['history_date']).toLocaleString();
                    if (this.currentHistoryId != this.historyId) {
                        this.removeHistoryFields();
                    }
                },
                err => {
                    console.log(err);
                },
                () => this.isLoadingFetchObject = false
            );
    }

    removeHistoryFields() {

        ['history_id','history_date', 'history_type', 'history_user', 'history_user_id', 'history_change_reason'].forEach(element => delete this.currentObject[element]);

        this.createOldContainerState();


    }

    createOldContainerState() {

        this.currentObjectString = JSONStableStringify(this.currentObject, { space: 2});
        this.currentObject = JSON.parse(this.currentObjectString);
        this.oldObject = JSON.parse(this.currentObjectString);

        for (let change of this.diffChanges) {
            let diffField = change['field'];
            let oldValue = change['old'];
            
            if (this.currentObject.hasOwnProperty(diffField)) {
                this.oldObject[diffField] = oldValue;
            }

        }
        this.oldObjectString = JSONStableStringify(this.oldObject, { space: 2 });
    }

    showDialog() {
        this.displayDialog = true;
    }

    revertObject() {

        this.isLoading = true;

        this.historyService
            .revertHistory(this.objectId, this.objectType, this.historyId)
            .subscribe(
                res => {
                    this.router.navigate(['/history', this.objectType, this.objectId]);
                },
                err => {
                    console.log(err);
                },
                () => this.isLoading = false

            );

    }
}
