<p-dialog header="Tags" 
        [(visible)]="displayDialog"
        [dismissableMask]="true"
        [closeOnEscape]="true"
        [responsive]="true"
        [closable]="true" 
        [minY]="70"
        modal="true"
        [style]="{'min-width': '400px','min-height': '300px'}"
        [baseZIndex]="10000">

    <div *ngIf="!isLoadingTags" class="ui-g-12 ui-md-12 ui-lg-12" style="min-height:250px;padding-bottom:200px;">

        <div *ngFor="let tagType of tagsTypes" style="width:auto;display:block;margin-bottom:1em;">
            <label for="tagType">{{tagType | labelToDisplay}}</label>
            <span class="p-float-label">
                <p-multiSelect [options]="tagsDict[tagType]" 
                                [(ngModel)]="selectedTags" 
                                name="tagType" 
                                optionLabel="label" 
                                maxSelectedLabels=100 
                                [style]="{'min-width':'20em', 'min-height':'27px'}"
                                [defaultLabel]="'Choose Tag(s)'"
                                (onChange)="onChangeTags()">
                    <ng-template let-tag pTemplate="item">
                        <div style="font-size:14px;float:right;margin-top:4px">{{tag.label}}</div>
                    </ng-template>
                </p-multiSelect>
            </span>
        </div>
    </div>
    <p-progressSpinner *ngIf="isLoadingTags == true" [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</p-dialog>

<p-panel class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
        <p-header>
            <span class="p-panel-title" style="font-size:16px;">Tags</span>
            <button pButton type="button" label="Edit" icon="pi pi-arrow-right" style="float:right;"(click)="showDialog()"></button>

        </p-header>
        <div style="padding:0.5em;overflow:hidden;">
        <span *ngFor="let tag of selectedTags" style="margin-right:10px">
                <div class="label label-warning"> {{tag.label}}</div>
        </span> 
    </div>

        
</p-panel>
