<p-panel>
    <p-header>
        <div class="ui-g">
            <span class="p-panel-title ui-g-12 ui-md-12 ui-lg-4 view-title" style="padding:0;">Content Groups</span>
            <div class="ui-g-12 ui-lg-8" style="padding:0;">
                <ng-content></ng-content>
            </div>
            <spinner *ngIf="isLoading"></spinner>
        </div>
    </p-header>

    <p-table #dt [value]="containers" 
                [globalFilterFields]="['data.label']" 
                [rows]="10" 
                [paginator]="true" 
                paginatorPosition="bottom" 
                selectionMode="single" 
                (onRowSelect)="onRowSelect($event)" 
                [(selection)]="container"
                styleClass="p-datatable-gridlines p-datatable-nested"
                [responsive]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:40%">Label</th>
                <th style="width:23%">Origin</th>
                <th style="width:23%">Status</th>
                <th style="width:6em">
                <th style="width:3em">

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contentGroup>
            <tr [pSelectableRow]="contentGroup">
                <td style="word-break: break-all;">{{contentGroup.data?.label}}</td>
                <td style="word-break: break-all;">{{contentGroup.origin}}</td>
                <td style="word-break: break-all;">{{contentGroup.status}}</td>
                <td>
                    <button pButton type="button" label="Edit" [routerLink]="['/commerce/contentgroup', contentGroup?.id]" icon="pi pi-pencil" [disabled]="parentContainer.origin != contentGroup.origin"></button>
                </td>
                <td>
                    <button pButton type="button" class="p-button-danger" (click)="onRemove($event, contentGroup)" icon="pi pi-times" style="margin:auto;display:block;font-size:13px"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
   
</p-panel>