import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntityType } from '../../models';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class EntityTypeService {
    private baseUrl = `${environment.PROXY_API_BASE_URL}/content`;

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    get(id) {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/entitytypes/${id}/`, { headers: headers }
            );
    }

    // getByName(name) {
    // }

    list() {
        let headers = new HttpHeaders()
            .append('Accept', 'application/json')
            .append('Authorization', this.authService.getAuthHeader());

        return this.http
            .get(
                `${this.baseUrl}/entitytypes/`, { headers: headers }
            );
    }

    // save() {
    // }
}
