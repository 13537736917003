import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
//import { PageHeaderComponent } from '../../../ui/page-header.component';
import { PlatformFormComponent } from '../../forms/platform-form/platform-form.component';
import { ContainerService } from '../../services/container/container.service';
import { Container } from '../../models/container';
//import { SpinnerComponent } from '../../../spinner/spinner.component';
import { Message } from 'primeng/api';

@Component({
    selector: 'platform-edit',
    providers: [
        ContainerService
    ],
    templateUrl: 'platform-edit.component.html',
    styleUrls: ['platform-edit.component.css']
})
export class PlatformEditComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: PlatformFormComponent;
    private sub: any;
    public container: Container;
    public error: HttpResponse<any>;
    public isLoading = false;
    private msgs: Message[] = [];
    public requiredMsgs: Message[] = [];
    private policies: Array < any > = [];
    public isProcessing = false;
    private pipelineId = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public containerService: ContainerService
    ) {}

    ngOnInit() {
        console.log('platform edit initialized');

        this.sub = this.route.params.subscribe(params => {
            let id = +params['id']; // + converts string to number
            console.log('id', id);

            this.isLoading = true;
            this.containerService
                .get(id)
                .subscribe(
                    res => {
                        console.log('res', res);
                        this.container = res;
                        this.mapContainersByEntityType();
                    },
                    err => {
                        this.error = err;
                        if (err.status == 404 ){
                            this.isProcessing = false;
                            this.requiredMsgs = [];
                            this.requiredMsgs.push({ severity: 'error', summary: err.statusText, detail: 'Platform id='+this.container.id+' does not exist.' });
                            this.container = null;
                            this.isLoading = false;
                        }
                    },
                    () => this.isLoading = false
                );
        });

        console.log('cres', this.container);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onSubmit(e) {
        this.requiredMsgs = [];
        this.containerService
            .save(this.container)
            .subscribe(
                res => {
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'success', summary: 'Changes Saved', detail: '' });
                    this.container = Object.assign(new Container(), this.container);
                },
                err => {
                    this.error = err.statusText;
                    this.requiredMsgs = [];
                    this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
                },
                () => this.isLoading = false
            );
    }

    onBack(e) {
        this.location.back();
    }

    onCancel(e) {
        if (!this.form || this.form.isPristine() || confirm('You may have unsaved changes that will not be saved if you leave this page. Are you sure you want to leave this page?')) {
            this.location.back();
        }
    }

    onFailure(e) {
        this.requiredMsgs = [];
        this.requiredMsgs.push({ severity: 'error', summary: 'Changes Were Not Saved', detail: 'There are errors in the form, please review and fix errors before saving.' });
    }

    onScrap(e) {
        if (!this.form.isPristine()) {
            if (confirm('Are you sure you want to scrap all unsaved changes to this platform?')) {
                this.refetchContainer();
            }
        }
    }

    onPipelineTriggered(event) {
        console.log(event);
        if (event && event['pipeline_id']) {
            this.isProcessing = true;
            // Set explicit pipeline id to track
            this.pipelineId = event['pipeline_id'];
        }
    }

    onStatusUpdate(event) {
        if (('previous_status' in event) && (event['status'] !== event['previous_status'])) {
            this.refetchContainer();
        } else if (('status' in event) && (event['status'] === 'none')) {
            this.isProcessing = false;
        }
    }

    checkProcessingStatus() {
        if (this.container) {
            this.isProcessing = (this.container.status == 'waiting' || this.container.status == 'processing');
        } else {
            this.isProcessing = false;
        }
        if (this.pipelineId && !this.isProcessing) {
            this.pipelineId = '';
        }
    }

    mapContainersByEntityType() {
        this.policies = this.container.containers.filter(t => t.type.name === 'policy');
    }

    refetchContainer() {
        this.isLoading = true;
        this.containerService
            .get(this.container.id)
            .subscribe(
                res => {
                    this.container = res instanceof Container ? res : Container.fromJson(res);
                    this.checkProcessingStatus();
                    this.mapContainersByEntityType();
                },
                err => {
                    this.error = err;
                    if (err.status == 404 ) {
                        this.isProcessing = false;
                        this.requiredMsgs = [];
                        this.requiredMsgs.push({ severity: 'error', summary: err.statusText, detail: 'Platform id='+this.container.id+' does not exist.' });
                        this.container = null;
                        this.isLoading = false;
                    }
                },
                () => this.isLoading = false
            );
    }
}
