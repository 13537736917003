import { Component, OnInit,  Input, Output, EventEmitter, OnDestroy, ViewChild, SimpleChanges, ElementRef } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MetricsReportsListComponent } from './../metrics-reports-list/metrics-reports-list.component';
import { interval as observableInterval, fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Container } from '../../../content/models';
import { Router, ActivatedRoute } from '@angular/router';
import { MetricsService } from '../../services/metrics.service';

@Component({
    selector: 'metrics-report-panel',
    templateUrl: './metrics-report-panel.component.html',
    styleUrls: ['metrics-report-panel.component.css'],
    providers: [
        MetricsService
    ]
})
export class MetricsReportPanelComponent implements OnInit,  OnDestroy {
 // @ViewChild(MetricsReportsListComponent) worklistTable:  MetricsReportsListComponent;

    @Input() selectedContainer: Container;
    @Input() showReportFilters: Boolean = true;
    @Input() showReportDetails: Boolean = true;
    @Input() allowCustomHeight: Boolean = true;
    @Output() onReportDataLoaded: EventEmitter<any> = new EventEmitter();

    @ViewChild('chartContainer') elementContainer: ElementRef;
    //@ViewChild('reportWrapper') reportWrapper : ElementRef;

    public reportData: any;
    private requiredMsgs = [];
    private error: any;
    public isLoading = false;
    public viewSize: any;

    public autoRefreshEnabled = false;
    public autoRefreshSliderActive = false;

    private paramArray = []

    private sub: any;
    private refreshObservableSubscription: Subscription;
    private resizeObservableSubscription: Subscription;
    private refreshInterval: Number;
    //private throttledReset = null;
    private initialHeight; 
    private viewHeight = 300;

    private chartResize$: Observable <any>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private metricsService: MetricsService
    ) { }

    ngOnInit() {
        this.chartResize$ = observableFromEvent(window, 'resize').pipe(debounceTime(200));
        this.resizeObservableSubscription = this.chartResize$.subscribe(event => {
            this.viewSize = this.getSize();
        })
    }

    setAutoRefresh() {
        if (this.selectedContainer && 'refresh_interval_seconds' in this.selectedContainer['data'] && this.selectedContainer['data']['refresh_interval_seconds'] > 0) {
            this.refreshInterval = this.selectedContainer['data']['refresh_interval_seconds'] * 1000
            this.setReportRefreshInterval(this.refreshInterval)
            this.autoRefreshSliderActive = true;
            this.autoRefreshEnabled = true;
        } else {
            this.autoRefreshSliderActive = false;
            this.autoRefreshEnabled = false; 

            if (this.refreshObservableSubscription) {
                this.refreshObservableSubscription.unsubscribe();
            }
        }
    }

    ngAfterViewInit() {
        //fix for ngx-charts bug that makes charts grow in height when data is updated.
        //if (this.elementContainer) {
        //    this.viewSize = [this.elementContainer.nativeElement.offsetWidth, this.elementContainer.nativeElement.offsetHeight];
        //}
        // this.viewSize = this.getSize();
        //this.initialHeight = this.reportWrapper.nativeElement.clientHeight;
        this.initialHeight = this.elementContainer.nativeElement.offsetHeight;
        this.viewHeight = this.initialHeight;
        //console.log('this.viewHeight', this.viewHeight);
    }

    // onResize() {
    //     if (this.throttledReset) {
    //         window.clearTimeout(this.throttledReset);
    //         this.throttledReset = null;
    //     }
    //     this.throttledReset = window.setTimeout( () => {
    //         this.viewSize = this.getSize();
    //     }, 500);
    // }


    private getSize() {
        let size, width;
        if (this.allowCustomHeight && this.selectedContainer && 'render_options' in this.selectedContainer['data'] && 'height' in this.selectedContainer['data']['render_options'])  {
            this.viewHeight = parseInt(this.selectedContainer['data']['render_options']['height']);
        }
        if (this.elementContainer && this.elementContainer.nativeElement.offsetWidth) {
            width = this.elementContainer.nativeElement.offsetWidth > 330 ? this.elementContainer.nativeElement.offsetWidth : 330;
            size = [width-80, this.viewHeight];
        } else {
            size = [700, 300];
        }
        console.log('size', size);
        return size;
    }

    setReportRefreshInterval(refreshInterval) {
        this.refreshObservableSubscription = observableInterval(refreshInterval)
            .subscribe(() => {
                if (this.autoRefreshEnabled) {
                  console.log("view Ref",this.elementContainer,this.viewSize)
                  this.fetchReport();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('changes', changes)
        if (changes['selectedContainer'].previousValue != changes['selectedContainer'].currentValue) {
            this.paramArray = [];
            this.reportData = null;
            //this.viewHeight = 300;
            this.viewHeight = this.initialHeight;
            this.viewSize = this.getSize();
            this.fetchReport();
            if (this.refreshObservableSubscription) {
                this.refreshObservableSubscription.unsubscribe();
            }
            this.setAutoRefresh();     
        }
    }

    onFilterParamChange(event) {
        console.log('onFilterParamChange',event)
        this.paramArray = event['params'];
        this.fetchReport();
    }

    fetchReport() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        // if (hide) {
        //     this.isLoading = true;
        // }
        this.sub = this.metricsService
            .getReportByGUIDWithParameters(this.selectedContainer.guid, this.paramArray)
            .subscribe(
                res => {
                    this.reportData = res;
                    //this.checkFilters();
                    this.viewSize = this.getSize();
                    console.log('Report Success', this.viewHeight);
                },
                err => {
                    
                    this.error = err.statusText;
                },
                () => {
                    console.log('fetching report', event);
                    // if (this.isLoading) {
                    //     this.isLoading = false;
                    // }
                    this.onReportDataLoaded.emit({
                        report_data: this.reportData
                    });
                }
            );
    }

    // checkFilters() {
    //     if ( this.selectedContainer && (! ('filter_options' in this.selectedContainer['data']) ) || this.selectedContainer['data']['filter_options'].length === 0 ) {
    //         console.log(1)
    //         this.showReportFilters = false;
    //     } else {
    //         this.showReportFilters = true;
    //     } 
    // }


    ngOnDestroy() {
        if (this.refreshObservableSubscription) {
            this.refreshObservableSubscription.unsubscribe();
        }
        if (this.resizeObservableSubscription) {
            this.resizeObservableSubscription.unsubscribe();
        }
    }

}
