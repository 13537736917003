<p-panel [style]="{'textAlign':'center'}">
    <p-header>
        <span class="p-panel-title">{{resource.type.name}}</span>
    </p-header>
    
    <img *ngIf="displayPreview" class="ui-g-12 thumbnail" [src]="resource.uri">
    
    <div class="ui-g-12" *ngIf="resource.type.name == 'video'" style="padding:0;">
        <div *ngIf="resource.data" class="ui-g-6">
            <div class="label label-primary ui-g-12">{{ resource.data.delivery_protocol}}</div>
        </div>
        <div *ngIf="resource.data && resource.data.protection_scheme" class="ui-g-6" style="padding-left:0;">
            <div *ngIf="resource.data.protection_scheme != 'none'" class="label label-success ui-g-12">DRM</div>
            <div *ngIf="resource.data.protection_scheme == 'none'" class="label label-success ui-g-12">No DRM</div>
        </div>
        
    </div>
    
    <div class="ui-g-12" *ngIf="resource.type.name == 'file'" style="padding:0;">
        <div *ngIf="resource.data && resource.data.source_uri" class="ui-g-6">
            <div class="label label-primary ui-g-12">
                {{ resource.data.source_uri | fileExt | lowercase }}
            </div>
        </div>
        <div *ngIf="resource.data && resource.data.source_uri" class="ui-g-6" style="padding-left:0;">
            <div class="label label-success ui-g-12">
                {{ resource.data.source_uri | uriScheme | uppercase }}
            </div>
        </div>
    </div>
    
    <div class="ui-g-12" *ngIf="resource.type.name == 'captions'" style="padding:0;">
        <div *ngIf="resource.uri" class="ui-g-6">
            <div class="label label-primary ui-g-12">
                {{ resource.uri | fileExt | uppercase }}
            </div>
        </div>
        <div *ngIf="resource.data && resource.data.source_uri" class="ui-g-6" style="padding-left:0;">
            <div class="label label-success ui-g-12">
                {{ resource.data.source_uri | uriScheme | uppercase }}
            </div>
        </div>
    </div>

    <div class="ui-g-12" style="padding:0;">
        <div *ngIf="['resource.status']" class="ui-g-10">
            <div class="label label-default ui-g-12">
                {{ resource.status | titlecase }}
            </div>
        </div>
     
        <div *ngIf="resource.tags.length === 0" class="ui-g-2" pTooltip="No tags">
                <div class="ui-g-12 label label-default  fa fa-fw fa-tag" style="display: inline-block;"> </div>
        </div>
        <div *ngIf="resource.tags.length > 0" class="ui-g-2" pTooltip="{{tagTooltip}}">
                <div class="ui-g-12 label label-success  fa fa-fw fa-tag" style="display: inline-block;"> </div>
        </div>        
    </div>
</p-panel>
<p-toolbar>
    <div class="ui-g">
        <div class="ui-g-6">
            <button label="Edit" class="p-button-primary panel-button" pButton type="button" [routerLink]="['/containers', parentContainer.id, 'resources', resource.id]" icon="pi pi-pencil"></button>
        </div>
        <div class="ui-g-6" style="padding-left:0;">
            <button label="Delete" class="p-button-danger panel-button" pButton type="button" (click)="remove()" icon="pi pi-trash"></button>
        </div>
    </div>
</p-toolbar>